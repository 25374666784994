/**
 * Google Tag Managerにpreviewタグを送信します
 */
export const GtmPageview = (w: Window & { dataLayer?: any[] }, pageTitle: string) => {
  // GTMにページビューを送信
  w.dataLayer = w.dataLayer || [];
  w.dataLayer.push({
    event: 'pageview',
    page: {
      title: pageTitle,
      url: w.location.pathname
    }
  });
};
