import { Fetcher, HTTPError } from '@this/src/util';
import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import AddIcon from '@material-ui/icons/Add';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import type { RuleCabinetJson } from '@this/domain/expenses/rule_cabinet';
import { RuleCabinet } from '@this/domain/expenses/rule_cabinet';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  OrganizationBiztraTh,
  OrganizationBiztraTable,
  OrganizationBiztraTd,
  OrganizationBiztraTdWithPointer
} from '@this/components/expenses/organization/organization.style';
import MainTitle from '@this/shared/organization/main_title/main_title';

import { ContentBody } from '../organization';
import { Wrapper, Header } from '../../report_items/search_form';
import { ActionWrapper, CancelButton } from '../../approvals/reject';
import { ConfirmTxt, ConfirmBody } from '../../approvals/zengin_download';
import { TheAddButton } from '../departments';
import { EditButton, TheDeleteButton, ModalDeleteButton } from '../expenses_account_types';

interface RuleCabinetsResponse {
  rule_cabinets: RuleCabinetJson[];
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  ruleCabinets: RuleCabinet[];
  error?: string;
  loading: boolean;
  deletingRuleCabinet: RuleCabinet | null;
  selectedRowIds: string[];
}

const basePath = '/biztra/organization/rule_cabinets/';

class RuleCabinets extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ruleCabinets: [],
      loading: true,
      deletingRuleCabinet: null,
      selectedRowIds: []
    };
  }

  componentDidMount() {
    this.fetchRuleCabinets();
    mixpanel.track('[Biztra][view] /organization/rule_cabinets');
  }

  onDragStart = (start: any) => {
    const id = start.draggableId;
    const selected = this.state.selectedRowIds.find(selectedId => selectedId === id);

    // If dragging an item that is not selected, unselect all items
    if (!selected) {
      this.unselectAll();
    }
  };

  onDragEnd = (result: any) => {
    const { destination, source } = result;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const ruleCabinets = Object.assign([], this.state.ruleCabinets);
    if (this.state.ruleCabinets) {
      const quote = this.state.ruleCabinets[source.index];
      ruleCabinets.splice(source.index, 1);
      ruleCabinets.splice(destination.index, 0, quote);
    }
    this.setState({
      ruleCabinets
    });
  };

  unselectAll = () => {
    this.setState({
      selectedRowIds: []
    });
  };

  private async fetchRuleCabinets() {
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<RuleCabinetsResponse>('/biztra/organization/rule_cabinets.json');
      const ruleCabinets = response.rule_cabinets.map(account => new RuleCabinet(account));
      this.setState({
        ruleCabinets,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  handleDeleteClick(ruleCabinet: RuleCabinet) {
    this.setState({ deletingRuleCabinet: ruleCabinet });
  }

  handleDeleteAlertClose() {
    this.setState({ deletingRuleCabinet: null });
  }

  handleDeleteConfirm() {
    Fetcher.delete(`${basePath}${this.state.deletingRuleCabinet!.id}`, {}).then(
      _result => {
        const ruleCabinets = this.state.ruleCabinets.filter(
          ruleCabinet => ruleCabinet.id !== this.state.deletingRuleCabinet!.id
        );
        this.setState({
          ruleCabinets,
          deletingRuleCabinet: null
        });
        this.fetchRuleCabinets();
      },
      error => {
        this.setState({
          error:
            error instanceof HTTPError && error.response?.status === 400
              ? error.response.data.errors
              : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  private ruleCabinets(ruleCabinets: RuleCabinet[]) {
    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <OrganizationBiztraTable showBorder>
          <thead>
            <tr>
              <OrganizationBiztraTh width="18%">記入日</OrganizationBiztraTh>
              <OrganizationBiztraTh>タイトル</OrganizationBiztraTh>
              <OrganizationBiztraTh width="12%">編集</OrganizationBiztraTh>
              <OrganizationBiztraTh width="12%">削除</OrganizationBiztraTh>
            </tr>
          </thead>
          <Droppable droppableId="tableBody">
            {(provided: any, _snapshot: any) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {ruleCabinets.map((ruleCabinet, index) => this.ruleCabinetList(ruleCabinet, index))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </OrganizationBiztraTable>
      </DragDropContext>
    );
  }

  handleClickDetail(ruleCabinet: RuleCabinet) {
    this.props.history.push(`${basePath}${ruleCabinet.id}`);
  }

  handleClickEdit(ruleCabinet: RuleCabinet) {
    this.props.history.push(`${basePath}${ruleCabinet.id}/edit`);
  }

  handleAddClick = () => {
    this.props.history.push(`${basePath}new`);
  };

  private ruleCabinetList(ruleCabinet: RuleCabinet, index: number) {
    return (
      <Draggable draggableId={String(ruleCabinet.id)} index={index} key={ruleCabinet.id}>
        {(provided: any, snapshot: any) => (
          <Tr
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={ruleCabinet.id}
            ref={provided.innerRef}
            style={this.getTrStyle(snapshot.isDragging, provided.draggableProps.style)}
            data-e2e-id={ruleCabinet.id}
          >
            <OrganizationBiztraTd className="e2e-updated-at">{ruleCabinet.updatedAt}</OrganizationBiztraTd>
            <OrganizationBiztraTdWithPointer
              className="e2e-title"
              onClick={() => this.handleClickDetail(ruleCabinet)}
            >
              {ruleCabinet.title}
            </OrganizationBiztraTdWithPointer>
            <OrganizationBiztraTd>
              <EditButton onClick={() => this.handleClickEdit(ruleCabinet)}>編集</EditButton>
            </OrganizationBiztraTd>
            <OrganizationBiztraTd>
              <TheDeleteButton onClick={() => this.handleDeleteClick(ruleCabinet)}>削除</TheDeleteButton>
            </OrganizationBiztraTd>
          </Tr>
        )}
      </Draggable>
    );
  }

  private deleteConfirm(deletingRuleCabinet: RuleCabinet) {
    return (
      <Wrapper className="e2e-confirm-delete">
        <Header>{`${deletingRuleCabinet.title}を削除してよろしいですか？`}</Header>
        <ConfirmBody>
          <ConfirmTxt style={{ textAlign: 'center' }}>※この操作は取り消せません</ConfirmTxt>
        </ConfirmBody>
        <ActionWrapper>
          <CancelButton onClick={() => this.handleDeleteAlertClose()}>キャンセル</CancelButton>
          <ModalDeleteButton onClick={() => this.handleDeleteConfirm()}>削除</ModalDeleteButton>
        </ActionWrapper>
      </Wrapper>
    );
  }

  getTrStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging && '#fff9ed',
    ...draggableStyle
  });

  render() {
    const { ruleCabinets, loading, error, deletingRuleCabinet } = this.state;
    return (
      <>
        <MainTitle
          value="ルール保管庫"
          buttons={
            <TheAddButton onClick={() => this.handleAddClick()}>
              <AddIcon fontSize="small" style={{ marginRight: 8, verticalAlign: 'text-top' }} />
              ルールを追加
            </TheAddButton>
          }
        />
        <ContentBody>
          {loading ? <SimpleLoading /> : error ? <p>{error}</p> : this.ruleCabinets(ruleCabinets)}
        </ContentBody>
        {deletingRuleCabinet && (
          <Modal
            open
            onClose={() => this.handleDeleteAlertClose()}
            PaperProps={{ style: { background: 'white' } }}
          >
            {this.deleteConfirm(deletingRuleCabinet)}
          </Modal>
        )}
      </>
    );
  }
}

const Tr = styled.tr`
  &:hover {
    background: #fff9ed;
  }
`;

export default observer(RuleCabinets);
