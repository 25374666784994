/* eslint-disable max-lines */
import _ from 'lodash';
import type { IObservableArray } from 'mobx';
import { observable, computed, action } from 'mobx';

import type { ArrangementRequestLimitTypeJson } from '@this/domain/organization/arrangement_request_limit_type';
import ArrangementRequestLimitType from '@this/domain/organization/arrangement_request_limit_type';
import User from '../user/user';
import type UserJson from '../user/user_json';
import type { ShinkansenLimitTypeJson } from './shinkansen_limit_type';
import ShinkansenLimitType from './shinkansen_limit_type';
import type { DomesticAirLimitTypeJson } from './domestic_air_limit_type';
import DomesticAirLimitType from './domestic_air_limit_type';
import type { RentalCarLimitTypeJson } from './rental_car_limit_type';
import RentalCarLimitType from './rental_car_limit_type';
import type { PackageLimitTypeJson } from './package_limit_type';
import PackageLimitType from './package_limit_type';
import type { HotelLimitTypeJson } from './hotel_limit_type';
import HotelLimitType from './hotel_limit_type';
import MarginType from './margin_type2';
import type { BulkTicketArgs } from '../bulk_ticket2';
import BulkTicket from '../bulk_ticket2';
import type { FreeeCompanyArgs } from '../freee_company2';
import FreeeCompany from '../freee_company2';
import type { OrganizationBillReceiverArgs } from './organization_bill_receiver2';
import OrganizationBillReceiver from './organization_bill_receiver2';
import type { ArrangementRequestSettingJson } from './arrangement_request_setting2';
import ArrangementRequestSetting from './arrangement_request_setting2';
import type { InfomartApiTokenArgs } from './infomart_api_token';
import InfomartApiToken from './infomart_api_token';
import type { InvoiceSettingArgs } from './invoice_setting';
import InvoiceSetting from './invoice_setting';
import type {
  OrganizationSystemFeeSettingJson,
  OrganizationSystemFeeSettingPaymentMethodType
} from './organization_system_fee_setting';
import { OrganizationSystemFeeSetting } from './organization_system_fee_setting';
import type { AutoRemoveFreeePostDeals, SettingArgs } from '../setting';
import Setting from '../setting';
import type { GradeArgs } from '../grade';
import OptionPlanPrice from '../plan/option_plan_price';
import type { OptionPlanPriceArgs } from '../plan/option_plan_price';
import type { SavingAccountArgs } from '../saving_accounts/saving_account';
import SavingAccount from '../saving_accounts/saving_account';

export type PaymentMethodOptions = 'invoice' | 'card' | 'selectable';
export type PaymentMethodType = 'billing' | 'card';
export type PaymentGatewayType = 'gmo' | 'sony';
export type ReserveRequestMailNotificationType = 'togglable' | 'selectable_user';
export type TransitSearchApi = 'legacy' | 'ekispert';
export type TransportElementReservationApi = 'smart_ticket' | 'ticket_opr';
export type FobiddenRepository = {
  category: string;
  repository: string;
};
export type ForbiddenRequestForm = {
  category: string;
};
export type FareOption = {
  optionType: 'corporate';
  qualifier: string;
  identity: string;
};
export type FareOptionArgs = {
  option_type: 'corporate';
  qualifier: string;
  identity: string;
};
export type workflowStyle = 'department' | 'trip';
export type TripReportWorkflowStyle = 'department' | 'project' | 'trip_report';
type DefaultShinkansenAddressType = 'select' | 'text';
type freeePartnerSetting = 'partner' | 'user_name';
type InvoiceSplitType = 'none' | 'charging_department' | 'project' | 'invoice_split_target';

interface revenueReferenceDateTypesInterface {
  [key: string]: string;
}

export type OrganizationArgs = Partial<{
  domain: string;
  members: UserJson[];
  margin_types: MarginType[];
  bulk_tickets: BulkTicketArgs[];
  selected_freee_company_id: number;
  freee_companies: FreeeCompanyArgs[];
  freee_partner_id: number;
  freee_monthly_price_section_id: number;
  freee_project_destination: string;
  is_create_freee_tag: boolean;
  freee_department: string;
  sso_entity_id: string;
  sso_url: string;
  sso_logout_url: string;
  sso_idp_cert: string;
  amadeus_company_name: string;
  person_in_charge_name: string;
  person_in_charge_email: string;
  billing_email: string;
  bank_account_info: string;
  agency_bank_account_info: string;
  member_count: number;
  admin_count: number;
  enable_count: number;
  disable_count: number;
  invite_count: number;
  uninvite_count: number;
  latest_plan_name: string;
  organization_bill_receivers: OrganizationBillReceiverArgs[];
  plan_id: number;
  salesforce_id: string;
  arrangement_request_setting: ArrangementRequestSettingJson;
  infomart_api_token: InfomartApiTokenArgs;
  immigration_card_enabled: boolean;
  visa_application_enabled: boolean;
  salesforce_import_id: string;
  enterprise_identification_number: string;
  show_ex: boolean;
  use_exic_expence: boolean;
  use_non_order_item: boolean;
  request_form_approval_required: boolean;
  invoice_day: number;
  setting: SettingArgs;
  admins: {
    name: string;
    email: string;
  }[];
  invoice_setting: InvoiceSettingArgs;
  grades: GradeArgs[];
  forbidden_repositories: FobiddenRepository[];
  forbidden_request_forms: ForbiddenRequestForm[];
  fare_options: FareOptionArgs[];
  segment1: string;
  segment2: string;
  company_size: string;
  exic_basic_member_no: string;
  expenses_multistage_approval: boolean;
  workflow_style: workflowStyle;
  trip_report_workflow_style: TripReportWorkflowStyle;
  is_own_company: boolean;
  use_qr: boolean;
  use_chatbot: boolean;
  use_slack_app: boolean;
  user_dashboard_label1: string;
  user_dashboard_label2: string;
  user_dashboard_label3: string;
  system_fee_setting: OrganizationSystemFeeSettingJson;
  freee_partner_setting: freeePartnerSetting;
  invoice_split_type: InvoiceSplitType;
  current_option_plan_prices: OptionPlanPriceArgs[];
  saving_account_id: number;
  allow_overdraft: boolean;
  use_exic_reference_number: boolean;
  saving_account: SavingAccountArgs;
  infomart_private_cust_cd: string;
  infomart_transfer_code: string;
  api_authenticate_key: string;
}> & {
  id: number;
  service_id: number;
  name: string;
  url: string;
  tel: string;
  address: string;
  sso_enabled: boolean;
  orientation: 'trip' | 'expenses';
  rakuten_inner_fee_rate: number;
  exic_agency_fee_rate: number;
  enabled_customize_address: boolean;
  use_bulk_ticket: boolean;
  use_kyuusyuu_ticket: boolean;
  include_tax: boolean;
  hotel_sort_type: boolean;
  enable_auto_reserve_rakuten_hotel: boolean;
  show_fee: boolean;
  shinkansen_limit_type: ShinkansenLimitTypeJson;
  domestic_air_limit_type: DomesticAirLimitTypeJson;
  rental_car_limit_type: RentalCarLimitTypeJson;
  package_limit_type: PackageLimitTypeJson;
  hotel_limit_type: HotelLimitTypeJson;
  arrangement_request_limit_type: ArrangementRequestLimitTypeJson;
  revenue_reference_date_type: 'confirmed_at' | 'end_date';
  payment_method_type: PaymentMethodOptions;
  payment_gateway_type: PaymentGatewayType;
  reserve_request_mail_notification_type: ReserveRequestMailNotificationType;
  freee_workflow: boolean;
  freee_workflow_approval_flow_route_id_domestic: number | null;
  freee_workflow_form_id_domestic: number | null;
  freee_workflow_approval_flow_route_id_foreign: number | null;
  freee_workflow_form_id_foreign: number | null;
  default_shinkansen_address_type: DefaultShinkansenAddressType;
  transit_search_api: TransitSearchApi;
  enable_rentalcar_form: boolean;
  day_off_operation: boolean;
  enable_auto_stock_check: boolean;
  enable_batch_approval: boolean;
  use_smart_search: boolean;
  use_chatbot: boolean;
  use_slack_app: boolean;
  transport_element_reservation_api: TransportElementReservationApi;
  system_fee_setting: OrganizationSystemFeeSettingJson;
  enable_just_before_reserve_expedia_hotel: boolean;
  enable_multiple_hotel_plans: boolean;
  freee_partner_setting: freeePartnerSetting;
  show_exic_seisan_trips: boolean;
  allow_apply_past_date: boolean;
  enable_ticket_opr_auto_ticketing: boolean;
  infomart_private_cust_cd: string;
  infomart_transfer_code: string;
  api_authenticate_key: string;
};

export default class Organization {
  @observable
  domain: string | undefined;

  @observable
  freeeCompanies: IObservableArray<FreeeCompany>;

  @observable
  bulkTickets: IObservableArray<BulkTicket>;

  @observable
  enterpriseIdentifactionNumber: string | undefined;

  @observable
  id: number;

  @observable
  serviceId: number;

  @observable
  name: string;

  @observable
  url: string;

  @observable
  planId: number | undefined;

  @observable
  amadeusCompanyName: string | undefined;

  @observable
  address: string | undefined;

  @observable
  tel: string | undefined;

  @observable
  personInChargeName: string | undefined;

  @observable
  personInChargeEmail: string | undefined;

  @observable
  billingEmail: string | undefined;

  @observable
  bankAccountInfo: string | undefined;

  @observable
  agencyBankAccountInfo: string | undefined;

  @observable
  memberCount: number | undefined;

  @observable
  adminCount: number | undefined;

  @observable
  enableCount: number | undefined;

  @observable
  disableCount: number | undefined;

  @observable
  inviteCount: number | undefined;

  @observable
  uninviteCount: number | undefined;

  @observable
  latestPlanName: string | undefined;

  @observable
  showFee: boolean | undefined;

  @observable
  includeTax: boolean | undefined;

  @observable
  hotelSortType: boolean | undefined;

  @observable
  enableAutoReserveRakutenHotel: boolean | undefined;

  @observable
  useBulkTicket: boolean | undefined;

  @observable
  useKyuusyuuTicket: boolean | undefined;

  @observable
  shinkansenLimitType: ShinkansenLimitType;

  @observable
  domesticAirLimitType: DomesticAirLimitType;

  @observable
  rentalCarLimitType: RentalCarLimitType;

  @observable
  packageLimitType: PackageLimitType;

  @observable
  hotelLimitType: HotelLimitType;

  @observable
  arrangementRequestLimitType: ArrangementRequestLimitType;

  @observable
  showEx: boolean | undefined;

  @observable
  enabledCustomizeAddress: boolean | undefined;

  @observable
  useExicExpence: boolean | undefined;

  @observable
  useNonOrderItem: boolean | undefined;

  @observable
  requestFormApprovalRequired: boolean | undefined;

  @observable
  domesticHotelMarginType: MarginType;

  @observable
  domesticPackageMarginType: MarginType;

  @observable
  foreignMarginType: MarginType;

  @observable
  foreignHotelMarginType: MarginType;

  @observable
  domesticRentalCarMarginType: MarginType;

  @observable
  domesticFlightMarginType: MarginType;

  @observable
  domesticShinkansenMarginType: MarginType;

  @observable
  rakutenInnerFeeRate: number | undefined;

  @observable
  exicAgencyFeeRate: number | undefined;

  @observable
  revenueReferenceDateType!: keyof typeof Organization.revenueReferenceDateTypes;

  @observable
  selectedFreeeCompanyId: number | undefined;

  @observable
  freeePartnerId: number | undefined;

  @observable
  freeeMonthlyPriceSectionId: number | undefined;

  @observable
  freeeProjectDestination: string | undefined;

  @observable
  isCreateFreeeTag: boolean | undefined;

  @observable
  freeeDepartment: string | undefined;

  @observable
  organizationBillReceivers: IObservableArray<OrganizationBillReceiver>;

  @observable
  arrangementRequestSetting: ArrangementRequestSetting;

  @observable
  infomartApiToken: InfomartApiToken;

  @observable
  immigrationCardEnabled: boolean;

  @observable
  visaApplicationEnabled: boolean;

  @observable
  salesforceId: string | undefined;

  @observable
  salesforceImportId: string | undefined;

  @observable
  ssoEnabled: boolean | undefined;

  @observable
  ssoEntityId: string | undefined;

  @observable
  ssoUrl: string | undefined;

  @observable
  ssoLogoutUrl: string | undefined;

  @observable
  ssoIdpCert: string | null | undefined;

  @observable
  margin_types: IObservableArray<MarginType>;

  @observable
  members: IObservableArray<User>;

  @observable
  orientation: 'trip' | 'expenses';

  @observable
  paymentMethodType: PaymentMethodOptions;

  @observable
  paymentGatewayType: PaymentGatewayType | undefined;

  @observable
  invoiceDay: number | undefined | null;

  @observable
  invoiceSetting?: InvoiceSetting;

  @observable
  grades: GradeArgs[] | undefined;

  @observable
  fobiddenRepositories: FobiddenRepository[] = [];

  @observable
  forbiddenRequestForms: ForbiddenRequestForm[] = [];

  @observable
  fareOptions: FareOption[] = [];

  @observable
  reserveRequestMailNotificationType: ReserveRequestMailNotificationType;

  @observable
  freeeWorkflow: boolean | undefined;

  @observable
  freeeWorkflowApprovalFlowRouteIdDomestic: number | null;

  @observable
  freeeWorkflowFormIdDomestic: number | null;

  @observable
  freeeWorkflowApprovalFlowRouteIdForeign: number | null;

  @observable
  freeeWorkflowFormIdForeign: number | null;

  @observable
  transitSearchApi: TransitSearchApi;

  @observable
  transportElementReservationApi: TransportElementReservationApi;

  @observable
  defaultShinkansenAddressType: DefaultShinkansenAddressType;

  @observable
  segment1: string | undefined;

  @observable
  segment2: string | undefined;

  @observable
  companySize: string | undefined;

  @observable
  setting?: Setting;

  @observable
  exicBasicMemberNo: string | undefined;

  @observable
  workflowStyle: workflowStyle | undefined;

  @observable
  tripReportWorkflowStyle: TripReportWorkflowStyle | undefined;

  @observable
  isOwnCompany: boolean | undefined;

  @observable
  useQr: boolean;

  @observable
  enableRentalcarForm: boolean;

  @observable
  dayOffOperation: boolean;

  @observable
  enableAutoStockCheck: boolean;

  @observable
  enableBatchApproval: boolean;

  @observable
  useSmartSearch: boolean;

  @observable
  useChatbot: boolean;

  @observable
  useSlackApp: boolean;

  @observable
  userDashboardLabel1: string | undefined;

  @observable
  userDashboardLabel2: string | undefined;

  @observable
  userDashboardLabel3: string | undefined;

  @observable
  systemFeeSetting: OrganizationSystemFeeSetting | undefined;

  @observable
  creditCardPaymentSettingAvailable: boolean | undefined;

  @observable
  enableJustBeforeReserveExpediaHotel: boolean;

  @observable
  enableMultipleHotelPlans: boolean;

  @observable
  freeePartnerSetting: freeePartnerSetting;

  @observable
  invoiceSplitType: InvoiceSplitType | undefined;

  @observable
  currentOptionPlanPrices?: OptionPlanPrice[];

  @observable
  showExicSeisanTrips: boolean;

  @observable
  allowApplyPastDate: boolean;

  @observable
  enableTicketOprAutoTicketing: boolean;

  @observable
  savingAccountId: number | undefined;

  @observable
  savingAccount?: SavingAccount;

  @observable
  allowOverdraft: boolean;

  @observable
  useExicReferenceNumber: boolean;

  @observable
  infomartPrivateCustCd: string | undefined;

  @observable
  infomartTransferCode: string | undefined;

  @observable
  apiAuthenticateKey: string;

  constructor(args: OrganizationArgs = {} as any) {
    ({
      id: this.id,
      service_id: this.serviceId,
      name: this.name,
      domain: this.domain,
      url: this.url,
      tel: this.tel,
      address: this.address,
      orientation: this.orientation,
      plan_id: this.planId,
      selected_freee_company_id: this.selectedFreeeCompanyId,
      freee_partner_id: this.freeePartnerId,
      freee_monthly_price_section_id: this.freeeMonthlyPriceSectionId,
      freee_project_destination: this.freeeProjectDestination,
      is_create_freee_tag: this.isCreateFreeeTag,
      freee_department: this.freeeDepartment,
      sso_enabled: this.ssoEnabled,
      sso_entity_id: this.ssoEntityId,
      sso_url: this.ssoUrl,
      sso_logout_url: this.ssoLogoutUrl,
      sso_idp_cert: this.ssoIdpCert,
      // for /admin/organizations
      amadeus_company_name: this.amadeusCompanyName,
      person_in_charge_name: this.personInChargeName,
      person_in_charge_email: this.personInChargeEmail,
      billing_email: this.billingEmail,
      bank_account_info: this.bankAccountInfo,
      agency_bank_account_info: this.agencyBankAccountInfo,
      member_count: this.memberCount,
      admin_count: this.adminCount,
      enable_count: this.enableCount,
      disable_count: this.disableCount,
      invite_count: this.inviteCount,
      uninvite_count: this.uninviteCount,
      latest_plan_name: this.latestPlanName,
      show_fee: this.showFee,
      include_tax: this.includeTax,
      hotel_sort_type: this.hotelSortType,
      enable_auto_reserve_rakuten_hotel: this.enableAutoReserveRakutenHotel,
      use_bulk_ticket: this.useBulkTicket,
      use_kyuusyuu_ticket: this.useKyuusyuuTicket,
      enabled_customize_address: this.enabledCustomizeAddress,
      show_ex: this.showEx,
      use_exic_expence: this.useExicExpence,
      use_non_order_item: this.useNonOrderItem,
      request_form_approval_required: this.requestFormApprovalRequired,
      salesforce_id: this.salesforceId,
      salesforce_import_id: this.salesforceImportId,
      enterprise_identification_number: this.enterpriseIdentifactionNumber,
      rakuten_inner_fee_rate: this.rakutenInnerFeeRate,
      exic_agency_fee_rate: this.exicAgencyFeeRate,
      revenue_reference_date_type: this.revenueReferenceDateType,
      payment_method_type: this.paymentMethodType,
      payment_gateway_type: this.paymentGatewayType,
      invoice_day: this.invoiceDay,
      grades: this.grades,
      freee_workflow: this.freeeWorkflow,
      freee_workflow_approval_flow_route_id_domestic: this.freeeWorkflowApprovalFlowRouteIdDomestic,
      freee_workflow_form_id_domestic: this.freeeWorkflowFormIdDomestic,
      freee_workflow_approval_flow_route_id_foreign: this.freeeWorkflowApprovalFlowRouteIdForeign,
      freee_workflow_form_id_foreign: this.freeeWorkflowFormIdForeign,
      segment1: this.segment1,
      segment2: this.segment2,
      company_size: this.companySize,
      transit_search_api: this.transitSearchApi,
      transport_element_reservation_api: this.transportElementReservationApi,
      exic_basic_member_no: this.exicBasicMemberNo,
      workflow_style: this.workflowStyle,
      trip_report_workflow_style: this.tripReportWorkflowStyle,
      is_own_company: this.isOwnCompany,
      enable_rentalcar_form: this.enableRentalcarForm,
      day_off_operation: this.dayOffOperation,
      enable_auto_stock_check: this.enableAutoStockCheck,
      enable_batch_approval: this.enableBatchApproval,
      use_smart_search: this.useSmartSearch,
      use_chatbot: this.useChatbot,
      use_slack_app: this.useSlackApp,
      user_dashboard_label1: this.userDashboardLabel1,
      user_dashboard_label2: this.userDashboardLabel2,
      user_dashboard_label3: this.userDashboardLabel3,
      enable_just_before_reserve_expedia_hotel: this.enableJustBeforeReserveExpediaHotel,
      enable_multiple_hotel_plans: this.enableMultipleHotelPlans,
      freee_partner_setting: this.freeePartnerSetting,
      invoice_split_type: this.invoiceSplitType,
      saving_account_id: this.savingAccountId,
      infomart_private_cust_cd: this.infomartPrivateCustCd,
      infomart_transfer_code: this.infomartTransferCode,
      api_authenticate_key: this.apiAuthenticateKey
    } = args);
    this.members = observable(_.map(args.members || [], m => new User(m)));
    const marginTypes = _.map(args.margin_types || [], m => new MarginType(m));
    this.domesticHotelMarginType =
      _.find(marginTypes, m => m.category === 'domestic_hotel') ||
      new MarginType({ category: 'domestic_hotel', base_amount: 0 });
    this.domesticPackageMarginType =
      _.find(marginTypes, m => m.category === 'domestic_package') ||
      new MarginType({ category: 'domestic_package', base_amount: 0 });
    this.foreignMarginType =
      _.find(marginTypes, m => m.isForeign) ||
      new MarginType({ category: 'foreign', base_type: 'fixed', base_amount: 2200 });
    this.foreignHotelMarginType =
      _.find(marginTypes, m => m.category === 'foreign_hotel') ||
      new MarginType({ category: 'foreign_hotel', base_amount: 0 });
    this.domesticRentalCarMarginType =
      _.find(marginTypes, m => m.category === 'domestic_rental_car') ||
      new MarginType({ category: 'domestic_rental_car', base_amount: 0 });
    this.domesticFlightMarginType =
      _.find(marginTypes, m => m.category === 'domestic_flight') ||
      new MarginType({ category: 'domestic_flight', base_type: 'percentage', base_amount: 5 });
    this.domesticShinkansenMarginType =
      _.find(marginTypes, m => m.category === 'domestic_shinkansen') ||
      new MarginType({ category: 'domestic_shinkansen', base_type: 'percentage', base_amount: 5 });
    this.margin_types = observable([
      this.domesticFlightMarginType,
      this.domesticShinkansenMarginType,
      this.domesticHotelMarginType,
      this.domesticPackageMarginType,
      this.foreignMarginType,
      this.foreignHotelMarginType,
      this.domesticRentalCarMarginType
    ]);
    this.bulkTickets = observable(_.map(args.bulk_tickets || [], t => new BulkTicket(t)));
    this.freeeCompanies = observable(
      args.freee_companies ? args.freee_companies.map(raw => new FreeeCompany(raw)) : []
    );
    this.organizationBillReceivers = observable(
      args.organization_bill_receivers
        ? _.map(args.organization_bill_receivers, r => new OrganizationBillReceiver(r))
        : []
    );
    this.arrangementRequestSetting = new ArrangementRequestSetting(
      args.arrangement_request_setting || ({} as any)
    );
    this.infomartApiToken = new InfomartApiToken(args.infomart_api_token || ({} as InfomartApiTokenArgs));
    this.immigrationCardEnabled = args.immigration_card_enabled || false;
    this.visaApplicationEnabled = args.visa_application_enabled || false;
    this.shinkansenLimitType = new ShinkansenLimitType(args.shinkansen_limit_type || {});
    this.domesticAirLimitType = new DomesticAirLimitType(
      args.domestic_air_limit_type || this.domesticAirLimitTypeForService()
    );
    this.rentalCarLimitType = new RentalCarLimitType(args.rental_car_limit_type || {});
    this.packageLimitType = new PackageLimitType(args.package_limit_type || this.packageLimitTypeForService());
    this.hotelLimitType = args.hotel_limit_type
      ? new HotelLimitType(args.hotel_limit_type)
      : HotelLimitType.newForService(this.serviceId);
    this.arrangementRequestLimitType = new ArrangementRequestLimitType(args.arrangement_request_limit_type || {});
    if (args.invoice_setting) {
      this.invoiceSetting = new InvoiceSetting(args.invoice_setting);
    }
    if (args.forbidden_repositories) {
      this.fobiddenRepositories = args.forbidden_repositories;
    }
    if (args.forbidden_request_forms) {
      this.forbiddenRequestForms = args.forbidden_request_forms;
    }
    if (args.fare_options) {
      this.fareOptions = args.fare_options.map(fareOption => ({
        optionType: fareOption.option_type,
        qualifier: fareOption.qualifier,
        identity: fareOption.identity
      }));
    }
    this.reserveRequestMailNotificationType = args.reserve_request_mail_notification_type;
    this.freeeWorkflow = args.freee_workflow || false;
    this.defaultShinkansenAddressType = args.default_shinkansen_address_type;
    if (args.setting) {
      this.setting = new Setting(args.setting);
    }
    this.transitSearchApi = args.transit_search_api;
    this.transportElementReservationApi = args.transport_element_reservation_api;
    this.exicBasicMemberNo = args.exic_basic_member_no;
    this.isOwnCompany = args.is_own_company || false;
    this.useQr = args.use_qr || false;
    this.useSmartSearch = args.use_smart_search || false;
    this.useChatbot = args.use_chatbot || false;
    this.useSlackApp = args.use_slack_app || false;
    this.systemFeeSetting = new OrganizationSystemFeeSetting(args.system_fee_setting || ({} as any));
    this.creditCardPaymentSettingAvailable =
      this.systemFeeSetting.paymentMethodType === 'credit_card' ||
      ((args.payment_method_type === 'card' || args.payment_method_type === 'selectable') &&
        args.payment_gateway_type === 'sony') ||
      false;
    if (args.current_option_plan_prices) {
      this.currentOptionPlanPrices = args.current_option_plan_prices.map(price => new OptionPlanPrice(price));
    }
    this.showExicSeisanTrips = args.show_exic_seisan_trips || false;
    this.allowApplyPastDate = args.allow_apply_past_date || false;
    this.enableTicketOprAutoTicketing = args.enable_ticket_opr_auto_ticketing || false;
    this.savingAccountId = args.saving_account_id;
    this.savingAccount = args.saving_account ? new SavingAccount(args.saving_account) : undefined;
    this.allowOverdraft = args.allow_overdraft || false;
    this.useExicReferenceNumber = args.use_exic_reference_number || false;
    this.infomartPrivateCustCd = args.infomart_private_cust_cd || '';
    this.infomartTransferCode = args.infomart_transfer_code || '';
    this.apiAuthenticateKey = args.api_authenticate_key || '';
  }

  static revenueReferenceDateTypes: revenueReferenceDateTypesInterface = {
    confirmed_at: '予約確定日',
    end_date: '出張完了日'
  };

  static orientations = {
    trip: '出張予約',
    expenses: '経費精算'
  };

  domesticAirLimitTypeForService() {
    if (!utils.isMynavi(this.serviceId)) return {};
    return { business_days: 1, hour: 12 };
  }

  packageLimitTypeForService() {
    if (!utils.isMynavi(this.serviceId)) return {};
    return { business_days: 7, hour: 0 };
  }

  @computed
  get revenueReferenceDateTypeText() {
    return Organization.revenueReferenceDateTypes[this.revenueReferenceDateType];
  }

  @computed
  get orientationText() {
    return Organization.orientations[this.orientation];
  }

  @computed
  get ssoIdpCertSet() {
    return !_.isEmpty(this.ssoIdpCert);
  }

  @action
  toggleShowFee() {
    this.showFee = !this.showFee;
  }

  @action
  toggleIncludeTax() {
    this.includeTax = !this.includeTax;
  }

  @action
  toggleHotelSortType() {
    this.hotelSortType = !this.hotelSortType;
  }

  @action
  toggleEnableAutoReserveRakutenHotel() {
    this.enableAutoReserveRakutenHotel = !this.enableAutoReserveRakutenHotel;
  }

  @action
  toggleUseBulkTicket() {
    this.useBulkTicket = !this.useBulkTicket;
  }

  @action
  toggleUseKyuusyuuTicket() {
    this.useKyuusyuuTicket = !this.useKyuusyuuTicket;
  }

  @action
  toggleEnabledCustomizeAddress() {
    this.enabledCustomizeAddress = !this.enabledCustomizeAddress;
  }

  @action
  toggleShowEx() {
    this.showEx = !this.showEx;
  }

  @action
  toggleUseExicExpence() {
    this.useExicExpence = !this.useExicExpence;
  }

  @action
  toggleUseNonOrderItem() {
    this.useNonOrderItem = !this.useNonOrderItem;
  }

  @action
  toggleRequestFormApprovalRequired() {
    this.requestFormApprovalRequired = !this.requestFormApprovalRequired;
  }

  @action
  toggleImmigrationCardEnabled() {
    this.immigrationCardEnabled = !this.immigrationCardEnabled;
  }

  @action
  toggleVisaApplicationEnabled() {
    this.visaApplicationEnabled = !this.visaApplicationEnabled;
  }

  @action
  toggleEnableRentalcarForm() {
    this.enableRentalcarForm = !this.enableRentalcarForm;
  }

  @action
  toggleDayOffOperation() {
    this.dayOffOperation = !this.dayOffOperation;
  }

  @action
  toggleEnableAutoStockCheck() {
    this.enableAutoStockCheck = !this.enableAutoStockCheck;
  }

  @action
  toggleEnableBatchApproval() {
    this.enableBatchApproval = !this.enableBatchApproval;
  }

  @action
  toggleUseSmartSearch() {
    this.useSmartSearch = !this.useSmartSearch;
  }

  @action
  toggleShowExicSeisanTrips() {
    this.showExicSeisanTrips = !this.showExicSeisanTrips;
  }

  @action
  toggleAllowApplyPastDate() {
    this.allowApplyPastDate = !this.allowApplyPastDate;
  }

  @action
  toggleEnableTicketOprAutoTicketing() {
    this.enableTicketOprAutoTicketing = !this.enableTicketOprAutoTicketing;
  }

  @action
  toggleAllowOverdraft() {
    this.allowOverdraft = !this.allowOverdraft;
  }

  @action
  toggleUseExicReferenceNumber() {
    this.useExicReferenceNumber = !this.useExicReferenceNumber;
  }

  @action
  addBillReceiver() {
    const receiver = new OrganizationBillReceiver({ organization_id: this.id });
    this.organizationBillReceivers.push(receiver);
  }

  @action
  removeBillReceiverAtIndex(index: number) {
    this.organizationBillReceivers.splice(index, 1);
  }

  @action
  toggleSsoEnabled() {
    this.ssoEnabled = !this.ssoEnabled;
  }

  @action
  toggleIsCreateFreeeTag() {
    this.isCreateFreeeTag = !this.isCreateFreeeTag;
  }

  @action
  setSsoIdpCertFile(file: File) {
    const reader = new FileReader();
    reader.onload = e => {
      this.ssoIdpCert = (e.target as FileReader).result as string;
    };
    reader.readAsText(file);
  }

  @action
  setSystemFeeSettingPaymentMethodType(type: OrganizationSystemFeeSettingPaymentMethodType) {
    this.systemFeeSetting!.paymentMethodType = type;
  }

  @action
  setPaymentMethodType(type: PaymentMethodOptions) {
    this.paymentMethodType = type;
  }

  @action
  setPaymentGatewayType(type: PaymentGatewayType) {
    this.paymentGatewayType = type;
  }

  @action
  setReserveRequestMailNotificationType(type: ReserveRequestMailNotificationType) {
    this.reserveRequestMailNotificationType = type;
  }

  @action
  toggleFreeeWorkflow() {
    this.freeeWorkflow = !this.freeeWorkflow;
  }

  @action
  setTransitSearchApi(api: TransitSearchApi) {
    this.transitSearchApi = api;
  }

  @action
  setTransportElementReservationApi(api: TransportElementReservationApi) {
    this.transportElementReservationApi = api;
  }

  @action
  setWorkflowStyle(style: workflowStyle) {
    this.workflowStyle = style;
  }

  @action
  setTripReportWorkflowStyle(style: TripReportWorkflowStyle) {
    this.tripReportWorkflowStyle = style;
  }

  @action
  toggleIsOwnCompany() {
    this.isOwnCompany = !this.isOwnCompany;
  }

  @action
  toggleUseChatbot() {
    this.useChatbot = !this.useChatbot;
  }

  @action
  toggleUseSlackApp() {
    this.useSlackApp = !this.useSlackApp;
  }

  @action
  toggleEnableJustBeforeReserveExpediaHotel() {
    this.enableJustBeforeReserveExpediaHotel = !this.enableJustBeforeReserveExpediaHotel;
  }

  @action
  toggleEnableMultipleHotelPlans() {
    this.enableMultipleHotelPlans = !this.enableMultipleHotelPlans;
  }

  @action
  setAutoRemoveFreeePostDeals(value: AutoRemoveFreeePostDeals) {
    this.setting!.setAutoRemoveFreeePostDeals(value);
  }

  @action
  setFreeePartnerSetting(setting: freeePartnerSetting) {
    this.freeePartnerSetting = setting;
  }

  @action
  setInvoiceSplitType(type: InvoiceSplitType) {
    this.invoiceSplitType = type;
  }

  hasOptionPlan(optionPlanName: string) {
    return !!this.currentOptionPlanPrices?.some(price => price.optionPlan.name === optionPlanName);
  }

  submitParams() {
    // TODO: ソニーペイメントをprodで有効化するときにenv切り分けを削除
    const env =
      location.host.includes('localhost') || location.host.includes('ngrok.io')
        ? 'development'
        : location.host.includes('stg')
        ? 'staging'
        : 'production';

    if (env === 'production' || !utils.isAiTravel(this.serviceId)) {
      this.paymentGatewayType = 'gmo';
    }

    return {
      name: this.name,
      domain: this.domain,
      url: this.url,
      amadeus_company_name: this.amadeusCompanyName,
      address: this.address,
      tel: this.tel,
      person_in_charge_name: this.personInChargeName,
      person_in_charge_email: this.personInChargeEmail,
      billing_email: this.billingEmail,
      bank_account_info: this.bankAccountInfo,
      agency_bank_account_info: this.agencyBankAccountInfo,
      show_fee: this.showFee ? 1 : 0,
      include_tax: this.includeTax ? 1 : 0,
      hotel_sort_type: this.hotelSortType ? 1 : 0,
      enable_auto_reserve_rakuten_hotel: this.enableAutoReserveRakutenHotel ? 1 : 0,
      use_bulk_ticket: this.useBulkTicket ? 1 : 0,
      use_kyuusyuu_ticket: this.useKyuusyuuTicket ? 1 : 0,
      shinkansen_limit_type_attributes: this.shinkansenLimitType.submitParams(),
      domestic_air_limit_type_attributes: this.domesticAirLimitType.submitParams(),
      margin_types_attributes: this.margin_types.map(mt => mt.submitParams()),
      rental_car_limit_type_attributes: this.rentalCarLimitType.submitParams(),
      package_limit_type_attributes: this.packageLimitType.submitParams(),
      hotel_limit_type_attributes: this.hotelLimitType.submitParams(),
      arrangement_request_limit_type_attributes: this.arrangementRequestLimitType.submitParams(),
      enabled_customize_address: this.enabledCustomizeAddress ? 1 : 0,
      show_ex: this.showEx ? 1 : 0,
      use_exic_expence: this.useExicExpence ? 1 : 0,
      use_non_order_item: this.useNonOrderItem ? 1 : 0,
      request_form_approval_required: this.requestFormApprovalRequired ? 1 : 0,
      plan_id: this.planId,
      rakuten_inner_fee_rate: this.rakutenInnerFeeRate || 0,
      exic_agency_fee_rate: this.exicAgencyFeeRate || 0,
      revenue_reference_date_type: this.revenueReferenceDateType,
      orientation: this.orientation,
      organization_bill_receivers_attributes: _.map(this.organizationBillReceivers, r => r.submitParams()),
      arrangement_request_setting_attributes: this.arrangementRequestSetting
        ? this.arrangementRequestSetting.submitParams()
        : undefined,
      immigration_card_enabled: this.immigrationCardEnabled,
      visa_application_enabled: this.visaApplicationEnabled,
      salesforce_id: !_.isNil(this.salesforceId) ? this.salesforceId : undefined,
      salesforce_import_id: !_.isNil(this.salesforceImportId) ? this.salesforceImportId : undefined,
      enterprise_identification_number: !_.isNil(this.enterpriseIdentifactionNumber)
        ? this.enterpriseIdentifactionNumber
        : undefined,
      setting_attributes: this.setting?.organizationSubmitParams(),
      system_fee_setting_attributes: this.systemFeeSetting?.submitParams(),
      payment_method_type: this.paymentMethodType,
      payment_gateway_type:
        this.paymentMethodType === 'card' || this.paymentMethodType === 'selectable'
          ? this.paymentGatewayType
          : null,
      invoice_day: this.invoiceDay,
      invoice_setting_attributes: this.invoiceSetting ? this.invoiceSetting.submitParams() : undefined,
      forbidden_repositories_attributes: this.fobiddenRepositories.map(repo => repo), // mobxのobservableの影響でmapで配列に変換する必要がある
      forbidden_request_forms_attributes: this.forbiddenRequestForms.map(frf => frf),
      fare_options_attributes: this.fareOptions.map(fareOption => ({
        option_type: fareOption.optionType,
        qualifier: fareOption.qualifier,
        identity: fareOption.identity
      })),
      reserve_request_mail_notification_type: this.reserveRequestMailNotificationType,
      freee_workflow: this.freeeWorkflow,
      transit_search_api: this.transitSearchApi,
      transport_element_reservation_api: this.transportElementReservationApi,
      default_shinkansen_address_type: this.defaultShinkansenAddressType,
      segment1: this.segment1,
      segment2: this.segment2,
      company_size: this.companySize,
      exic_basic_member_no: this.exicBasicMemberNo,
      workflow_style: this.workflowStyle,
      trip_report_workflow_style: this.tripReportWorkflowStyle,
      is_own_company: this.isOwnCompany,
      use_qr: this.useQr,
      enable_rentalcar_form: this.enableRentalcarForm ? 1 : 0,
      day_off_operation: this.dayOffOperation,
      enable_auto_stock_check: this.enableAutoStockCheck,
      enable_batch_approval: this.enableBatchApproval,
      use_smart_search: this.useSmartSearch,
      use_chatbot: this.useChatbot,
      use_slack_app: this.useSlackApp,
      user_dashboard_label1: this.userDashboardLabel1,
      user_dashboard_label2: this.userDashboardLabel2,
      user_dashboard_label3: this.userDashboardLabel3,
      enable_just_before_reserve_expedia_hotel: this.enableJustBeforeReserveExpediaHotel,
      enable_multiple_hotel_plans: this.enableMultipleHotelPlans,
      freee_partner_setting: this.freeePartnerSetting,
      invoice_split_type: this.invoiceSplitType,
      show_exic_seisan_trips: this.showExicSeisanTrips,
      allow_apply_past_date: this.allowApplyPastDate,
      enable_ticket_opr_auto_ticketing: this.enableTicketOprAutoTicketing,
      saving_account_id: this.savingAccountId || '',
      allow_overdraft: this.allowOverdraft,
      use_exic_reference_number: this.useExicReferenceNumber,
      infomart_private_cust_cd: this.infomartPrivateCustCd || '',
      infomart_transfer_code: this.infomartTransferCode || '',
      api_authenticate_key: this.apiAuthenticateKey || ''
    };
  }
}
