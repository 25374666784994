OrganizationAddress = require('./organization_address').default

class Department
  constructor: (args = {}, members) ->
    _.assign @, args
    @approveStages =
      (@approval_authorities || []).reduce (approveStages, authority) ->
        workflowType = if authority.workflow then authority.workflow.workflow_type else 'default'
        stage = authority.approve_stage
        id = authority.approver_id

        if approveStages[workflowType][stage]? && approveStages[workflowType][stage][0] != ''
          approveStages[workflowType][stage].push id
        else
          approveStages[workflowType][stage] = [id]
        approveStages
      ,{ default: { 1: [''] }, overseas: { 1: [''] } }
    @members = members
    @organizationAddress = new OrganizationAddress @organization_address
    @postalCode = @organizationAddress.postalCode || ''
    @address = @organizationAddress.location || ''
    @addressee = @organizationAddress.addressee || ''
    @expensesApprovalAuthorities = @expenses_approval_authorities || []
    @monthly_budget = args.monthly_budget || ''
    @workflowStyle = args.workflow_style || null
    @invoiceSplitTargetId =
      args.invoice_split_targets && args.invoice_split_targets[0]? && args.invoice_split_targets[0].id || null

  approver: ->
    _.find(@members, (a) => a.id == @approverId)

  approverName: ->
    dig(@approver(), 'name')

  membersNotApprover: (stage) ->
    _.filter @members, (m) =>
      !_.includes @approveStages[stage], m.id

  membersNotAdmin: ->
    _.filter @members, (m) -> !m.isOrganizationAdmin

  approversFromIds: (ids) ->
    approvers = _.map ids, (id) => _.find(@members, (m) -> m.id == id)
    _.compact approvers

  approverNames: (ids) ->
    _.map @approversFromIds(ids), (a) -> a.name

  allApproverNames: ->
    _.map @approvers, (a) -> a.name

  expensesApprovalAuthoritiesToShow: ->
    _.filter @expensesApprovalAuthorities, (auth) -> !auth['_destroy']

  expensesApprovers: ->
    approvers = _.map @expensesApprovalAuthoritiesToShow(), (auth) =>
      _.find(@members, (m) -> m.id == parseInt(auth.expenses_approver_id))
    _.compact approvers

  expensesNonApprovers: ->
    _.filter @members, (m) => !_.includes @expensesApprovers(), m

  addExpensesApprovalAuthority: ->
    members = @membersNotAdmin()
    @expensesApprovalAuthorities.push({ expenses_approver_id: _.first(members).id })
    app.render()

  removeExpensesApprovalAuthority: (authority) ->
    authority['_destroy'] = '1'
    app.render()

  setExpensesApprover: (authority, approver_id) ->
    authority.expenses_approver_id = approver_id
    app.render()

  setCode: (value) ->
    @code = value
    app.render()

  setName: (value) ->
    @name = value
    app.render()

  setApproverId: (value) ->
    @approverId = if value == '' then null else parseInt(value)
    app.render()

  setApproverIds: (ids, s, workflowType) ->
    @approveStages[workflowType][s] = if ids then ids else []
    app.render()

  setApproveStages: (approveStages) ->
    @approveStages = if approveStages then approveStages else { default: { 1: [''] }, overseas: { 1: ['' ] } }
    app.render()

  setApproveStageParam: (multipleApproveStages) ->
    _.mapValues multipleApproveStages, (approveStages) ->
      _.mapValues approveStages, (as) ->
        _.filter as, (a) ->
          a != ""

  setPostalCode: (value) ->
    @postalCode = value
    app.render()

  setAddress: (value) ->
    @address = value
    app.render()

  setAddressee: (value) ->
    @addressee = value
    app.render()

  setMonthlyBudget: (value) ->
    @monthly_budget = value
    app.render()

  setFreeeSection: (value) ->
    @freee_section_id = value?.id || null
    @freee_section_name = value?.name || null
    app.render()

  setDisabled: (value) ->
    @disabled = value
    app.render()

  setWorkflowStyle: (value) ->
    @workflowStyle = value || null
    app.render()

  setInvoiceSplitTargetId: (value) ->
    @invoiceSplitTargetId = value || null
    app.render()

  updateParams: ->
    code: @code
    name: @name
    disabled: @disabled
    monthly_budget: @monthly_budget
    approve_stages: @setApproveStageParam @approveStages
    organization_address_attributes:
      postal_code: @postalCode
      location: @address
      addressee: @addressee
    expenses_approval_authorities_attributes: @expensesApprovalAuthorities
    workflow_style: @workflowStyle
    invoice_split_target_id: @invoiceSplitTargetId
    freee_section_id: @freee_section_id
    freee_section_name: @freee_section_name

  noAddress: ->
    _.isEmpty @address

  description: ->
    if @noAddress()
      @name + ':住所が登録されていません'
    else
      @name + ':' + @postalCode + ' ' + @address + ' ' + @addressee

  descriptionWithCode: ->
    @code + ':' + @name

module.exports = Department
