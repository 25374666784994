import type { TripDuplicationInterface } from '@this/src/components/reserve_trip/reserve_confirm/reserve_confirm';
import { Fetcher } from '@this/src/util';

interface Props {
  sendLog?: boolean;
  searchQueryId?: number;
  tripId?: number;
  path: string;
  tripDuplications: TripDuplicationInterface[];
  onCompleted?: () => void;
  onError?: (error: string) => void;
}

interface CompletedResponse {
  success: boolean;
}

interface ErrorResponse {
  errors?: string[];
}

const tripDuplicateLogAPI = ({
  sendLog = false,
  searchQueryId,
  tripId,
  path,
  tripDuplications,
  onCompleted,
  onError
}: Props) => {
  if (!sendLog) {
    return;
  }

  const params = {
    search_query_id: searchQueryId,
    trip_id: tripId,
    display_path: path,
    trip_duplications: tripDuplications
  };

  Fetcher.post<CompletedResponse>('/trip_duplicate_logs', params)
    .then(() => onCompleted?.())
    .catch((error: ErrorResponse) => onError?.(error.errors?.join('\n') || '予期せぬエラーが発生しました'));
};

export type TripDuplicateLogAPIProps = Partial<Props>;

export default tripDuplicateLogAPI;
