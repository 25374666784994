import React from 'react';
import _ from 'lodash';
import type { ImageFile } from 'react-dropzone';

import type Prefecture from '@this/domain/prefecture';
import type TripRuleError from '@this/domain/trip/trip_rule_error';
import type UserJson from '@this/domain/user/user_json';
import type { SearchType } from '@this/src/domain/search_query';
import type TripRuleDistanceTimeInfo from '@this/domain/trip/trip_rule_distance_time_info';
import { trackClick } from '@this/src/util';
import type moment from '../../../../../lib/moment';

import InputCustomerInformationTemplate from './input_customer_information.template';

import type { FilterableSelectorOption } from '../../../../shared/filterable_selector/filterable_selector';
import type ReserveInfo from '../../../../../domain/reserve_info';
import type { OrderItemMappingArgs, OrderItemMappingMode } from '../../../../../domain/order_item_mapping';
import type OrderItemMapping from '../../../../../domain/order_item_mapping';
import type ReservingTrip from '../../../../../domain/trip/reserving_trip';
import Traveler from '../../../../../domain/traveler/traveler';
import type User from '../../../../../domain//user/user';
import Department from '../../../../../domain/department/department';
import type OrganizationBase from '../../../../../domain/organization_base/organization_base';
import { isWithinSixMonth, isOverDate } from '../../../../shared/checker/date_checker';
import OrderItemMappingList from '../../../../../domain/order_item_mapping_list';
import type ItemList from '../../../../../domain/approve_item/item_list';
import type { TripDuplicationInterface } from '../../reserve_confirm';

export type Props = {
  // shinkansenAddress;
  reserveInfo: ReserveInfo;
  applicant: Traveler;
  selectPageUrl: string | undefined;
  onSuccess: () => void;

  isCustomerInfoRequired: () => boolean;
  reservingTrip: ReservingTrip;
  // bedTypeId, どこから？
  organizationBases: OrganizationBase[] | undefined;
  departments: Department[] | undefined;
  useBulkTicket: boolean | undefined;
  useKyuusyuuTicket: boolean | undefined;
  showEx: boolean | undefined;
  serviceId: number;
  handleBackToSelectClick: () => void;
  projectShareAvailability: boolean;
  prefecture: Prefecture[];
  isOnTripsConfirm: boolean;
  tripRuleErrors: TripRuleError[];
  tripDuplications: TripDuplicationInterface[];
  tripDistanceAndTimeInfo: TripRuleDistanceTimeInfo[];
  queries: { search_type: SearchType };
  fromTripForm: boolean;
  handleSaveDraft?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isSimpleRequest?: boolean;
  errorsInputCustomer?: { [key: string]: string | undefined };
  paymentLoading: boolean;
};

export type State = {
  // shinkansenAddress;
  validationErrors: { [key: string]: string | undefined };
  isRentalCar: boolean;
  showDepartmentDetail: boolean;
  departmentDetailMode: OrderItemMappingMode;
  showProjectDetail: boolean;
  projectDetailMode: OrderItemMappingMode;
  showExpensesAccountTypeDetail: boolean;
  expensesAccountTypeDetailMode: OrderItemMappingMode;
  isSubmitting: boolean;
  exicPasswordTypes: string[];
  showAlert: boolean;
};

interface Stage {
  stage: number;
  approvers: (User | null)[];
}

export function validationErrorsInputCustomer(reserveInfo: any, serviceId: number, isSimpleRequest: boolean) {
  const validationErrors = reserveInfo.validationErrors(serviceId, isSimpleRequest);
  if (_.isEmpty(validationErrors)) {
    return { isValid: true, errors: {} };
  }
  return { isValid: false, errors: validationErrors };
}

class InputCustomerInformation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const departmentMappingMode = props.reserveInfo.orderItemMappingsDepartmentPart.mappingMode();
    const showDepartmentDetail = props.reserveInfo.orderItemMappingsDepartmentPart.isForDetail();

    const projectMappingMode = props.reserveInfo.orderItemMappingsProjectPart.mappingMode();
    const showProjectDetail = props.reserveInfo.orderItemMappingsProjectPart.isForDetail();

    const expensesMappingMode = props.reserveInfo.orderItemMappingsExpensesPart.mappingMode();
    const showExpensesAccountTypeDetail = props.reserveInfo.orderItemMappingsExpensesPart.isForDetail();
    this.state = {
      // shinkansenAddress: this.props.shinkansenAddress,
      validationErrors: {},
      isRentalCar: false,
      isSubmitting: false,
      showDepartmentDetail,
      departmentDetailMode: showDepartmentDetail ? departmentMappingMode : 'item',
      showProjectDetail,
      projectDetailMode: showProjectDetail ? projectMappingMode : 'item',
      showExpensesAccountTypeDetail,
      expensesAccountTypeDetailMode: showExpensesAccountTypeDetail ? expensesMappingMode : 'item',
      exicPasswordTypes: props.reserveInfo.travelers.list.map(_l => 'password'),
      showAlert: false
    };

    this.fetchFreeeAuthUri();
  }

  // getDefaultProps() {
  //   return {onSuccess() {}};
  // },

  handleTravelerTypeChange = (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'self') {
      const user = new Traveler(this.props.reserveInfo.user);
      this.props.reserveInfo.travelers.setTravelerAtIndex(i, user);
      this.props.reserveInfo.setTravelerAtIndex(i, user);
    } else if (e.target.value === 'member') {
      this.props.reserveInfo.travelers.setTravelerTypeAtIndex(i, e.target.value);
    } else if (e.target.value === 'companion') {
      this.props.reserveInfo.travelers.setTravelerTypeAtIndex(i, e.target.value);
      this.props.reserveInfo.resetTravelerAtIndex(i);
    }
    if (i === 0) {
      this.props.reserveInfo.setRCFirstNameKana(this.props.reserveInfo.travelers.list[i].firstNameKana, i);
      this.props.reserveInfo.setRCLastNameKana(this.props.reserveInfo.travelers.list[i].lastNameKana, i);
    }
  };

  handleTravelerSelect = (i: number, traveler: Traveler) => {
    this.props.reserveInfo.setTravelerAtIndex(i, traveler);
    if (i === 0) {
      this.props.reserveInfo.setRCFirstNameKana(traveler.firstNameKana, i);
      this.props.reserveInfo.setRCLastNameKana(traveler.lastNameKana, i);
    }
  };

  handleTravelerInfoChange =
    (i: number, method: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const traveler = this.props.reserveInfo.travelers.list[i];
      (traveler as any)[method](e.target.value);
      if (i === 0) {
        if (method === 'setFirstNameKana') {
          this.props.reserveInfo.setRCFirstNameKana(traveler.firstNameKana, i);
        }
        if (method === 'setLastNameKana') {
          this.props.reserveInfo.setRCLastNameKana(traveler.lastNameKana, i);
        }
      }

      if (method === 'setFirstNameRoman') {
        this.props.reserveInfo.setManualHotelFirstName(e.target.value, i);
      }
      if (method === 'setLastNameRoman') {
        this.props.reserveInfo.setManualHotelLastName(e.target.value, i);
      }
      if (method === 'setFirstNameKana') {
        this.props.reserveInfo.setManualHotelFirstNameKana(traveler.firstNameKana, i);
      }
      if (method === 'setLastNameKana') {
        this.props.reserveInfo.setManualHotelLastNameKana(traveler.lastNameKana, i);
      }
    };

  handleTravelerBirthDayChange = (i: number, method: string) => (birthday: moment.Moment) => {
    (this.props.reserveInfo.travelers.list[i] as any)[method](birthday.format('YYYY-MM-DD'));
  };

  handleTravelerNationalityChange = (i: number, method: string) => (id: number) => {
    (this.props.reserveInfo.travelers.list[i] as any)[method](id);
  };

  handleDateChange = (method: string, i: number) => (date: moment.Moment) => {
    (this.props.reserveInfo as any)[method](date.format('YYYY-MM-DDTHH:mm'), i);
  };

  handleReserveInfoChange =
    (method: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      (this.props.reserveInfo as any)[method](e.target.value);
    };

  handleHotelInfoChange =
    (method: string, i: number, j: number) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      (this.props.reserveInfo as any)[method](e.target.value, i, j);
    };

  handleRentalCarInfoChange = (method: string, i: number) => (value: string) => {
    (this.props.reserveInfo as any)[method](value, i);
  };

  handleRentalCarInfoAdd = () => {
    this.props.reserveInfo.addRentalCar();
    this.props.reserveInfo.initOrderItemMappings('department', this.state.departmentDetailMode);
    this.props.reserveInfo.initOrderItemMappings('project', this.state.projectDetailMode);
    this.props.reserveInfo.initOrderItemMappings(
      'expenses_account_type',
      this.state.expensesAccountTypeDetailMode
    );
  };

  handleRentalCarInfoRemove = (i: number) => (_e: React.MouseEvent<HTMLElement>) => {
    this.props.reserveInfo.removeRentalCar(i);
    this.props.reserveInfo.initOrderItemMappings('department', this.state.departmentDetailMode);
    this.props.reserveInfo.initOrderItemMappings('project', this.state.projectDetailMode);
    this.props.reserveInfo.initOrderItemMappings(
      'expenses_account_type',
      this.state.expensesAccountTypeDetailMode
    );
  };

  handleNotifiedUserAdd = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const users = this.props.reserveInfo.notifiedUsers;
    users.push(undefined);
    this.props.reserveInfo.setNotifiedUsers(users);
  };

  handleNotifiedUserRemove = (e: React.MouseEvent<HTMLElement>, i: number) => {
    e.preventDefault();
    const users = this.props.reserveInfo.notifiedUsers;
    users.splice(i, 1);
    this.props.reserveInfo.setNotifiedUsers(users);
  };

  handleNotifiedUserSelect = (i: number, traveler: Traveler, _type: string) => {
    const users = this.props.reserveInfo.notifiedUsers;
    users[i] = traveler;
    this.props.reserveInfo.setNotifiedUsers(users);
  };

  handleAddressDepartmentSelect = (department: Department) => {
    this.props.reserveInfo.setOrganizationAddress(department.organizationAddress);
  };

  handleAddressOrganizationBaseSelect = (organizationBase: OrganizationBase) => {
    this.props.reserveInfo.setOrganizationBase(organizationBase);
  };

  handleChargingDepartmentSelect = async (department: Department) => {
    this.props.reserveInfo.setChargingDepartment(department);
    this.props.reserveInfo.orderItemMappingsDepartmentPart = new OrderItemMappingList([
      {
        traveler_id: -1,
        order_item_type: 'all',
        order_item_index: -1,
        charging_department_id: Number(department.id)
      }
    ]);
    await this.props.reserveInfo.resetExpenseAccountType();
    app.render();
  };

  handleProjectSelect = (option: FilterableSelectorOption | null) => {
    this.props.reserveInfo.setProjectId(option ? option.value : '');
    this.props.reserveInfo.orderItemMappingsProjectPart = new OrderItemMappingList([
      {
        traveler_id: -1,
        order_item_type: 'all',
        order_item_index: -1,
        project_id: Number(option?.value)
      }
    ]);
  };

  handleExpensesAccountTypeSelect = (option: FilterableSelectorOption | null) => {
    const values = option?.value.split('-'); // e.g. expenses-1
    if (values && values?.length === 2) {
      const args: OrderItemMappingArgs[] = [
        {
          traveler_id: -1,
          order_item_type: 'all',
          order_item_index: -1,
          expenses_account_type_id: Number(values![1]),
          selected_option_label: option?.label
        }
      ];
      this.props.reserveInfo.setOrderItemMappingsExpensesPart(args);
    } else {
      this.props.reserveInfo.resetOrderItemMappings('expenses_account_type');
    }
  };

  handleShowDepartmentDetailClick = () => {
    this.setState({
      showDepartmentDetail: true,
      departmentDetailMode: 'item'
    });
    this.props.reserveInfo.setShowDepartmentDetail(true);
    this.props.reserveInfo.initOrderItemMappings('department', 'item');
    this.props.reserveInfo.chargingDepartment = null;
  };

  handleCancelDepartmentDetailClick = () => {
    this.setState({
      showDepartmentDetail: false,
      departmentDetailMode: 'item'
    });
    this.props.reserveInfo.setShowDepartmentDetail(false);
    this.props.reserveInfo.resetOrderItemMappings('department');
  };

  handleShowProjectDetailClick = () => {
    this.setState({
      showProjectDetail: true,
      projectDetailMode: 'item'
    });
    this.props.reserveInfo.setShowProjectDetail(true);
    this.props.reserveInfo.initOrderItemMappings('project', 'item');
    this.props.reserveInfo.projectId = '';
  };

  handleCancelProjectDetailClick = () => {
    this.setState({
      showProjectDetail: false,
      projectDetailMode: 'item'
    });
    this.props.reserveInfo.setShowProjectDetail(false);
    this.props.reserveInfo.resetOrderItemMappings('project');
  };

  handleShowExpensesAccountTypeDetailClick = () => {
    this.setState({
      showExpensesAccountTypeDetail: true,
      expensesAccountTypeDetailMode: 'item'
    });
    this.props.reserveInfo.setShowExpensesAccountTypeDetail(true);
    this.props.reserveInfo.initOrderItemMappings('expenses_account_type', 'item');
  };

  handleCancelExpensesAccountTypeDetailClick = () => {
    this.setState({
      showExpensesAccountTypeDetail: false,
      expensesAccountTypeDetailMode: 'item'
    });
    this.props.reserveInfo.setShowExpensesAccountTypeDetail(false);
    this.props.reserveInfo.resetOrderItemMappings('expenses_account_type');
  };

  handleDepartmentDetailModeChange = (mode: OrderItemMappingMode) => {
    this.setState({ departmentDetailMode: mode });
    this.props.reserveInfo.initOrderItemMappings('department', mode);
  };

  handleProjectDetailModeChange = (mode: OrderItemMappingMode) => {
    this.setState({ projectDetailMode: mode });
    this.props.reserveInfo.initOrderItemMappings('project', mode);
  };

  handleExpensesAccountTypeDetailModeChange = (mode: OrderItemMappingMode) => {
    this.setState({ expensesAccountTypeDetailMode: mode });
    this.props.reserveInfo.initOrderItemMappings('expenses_account_type', mode);
  };

  handleDepartmentDetailChange = async (
    changingMapping: OrderItemMapping,
    option: FilterableSelectorOption | null
  ) => {
    const values = option?.value.split('-'); // e.g. department-1
    if (changingMapping && values && values?.length === 2) {
      if (values[0] === 'department') {
        changingMapping.setChargingDepartmentId(Number(values[1]));
        changingMapping.setSelectedOptionLabel(option?.label || '');
      } else if (values[0] === 'share') {
        changingMapping.setChargingDepartmentShareId(Number(values[1]));
        if (option?.labelLines) {
          changingMapping.setSelectedOptionLabel(option.labelLines[0] || '');
        }
      }
    } else if (option === null) {
      const department = new Department(this.props.reserveInfo.getFirstExistAccountTraveler().department);
      changingMapping.setChargingDepartmentId(department.id);
      changingMapping.setSelectedOptionLabel(department.descriptionWithCode());
    }
    await this.props.reserveInfo.resetExpenseAccountType();
    app.render();
  };

  handleProjectDetailChange = (changingMapping: OrderItemMapping, option: FilterableSelectorOption | null) => {
    const values = option?.value.split('-'); // e.g. project-1
    if (changingMapping && values && values?.length === 2) {
      if (values[0] === 'project') {
        changingMapping.setProjectId(Number(values[1]));
        changingMapping.setSelectedOptionLabel(option?.label || '');
      } else if (values[0] === 'share') {
        changingMapping.setProjectShareId(Number(values[1]));
        if (option?.labelLines) {
          changingMapping.setSelectedOptionLabel(option.labelLines[0] || '');
        }
      }
    } else if (option === null) {
      changingMapping.setProjectId(-1);
      changingMapping.setSelectedOptionLabel('');
    }
  };

  handleApproveTextChange =
    (id: number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      (this.props.reserveInfo as any).setApproveItemValue(id, e.target.value);
    };

  handleApproveCalendarSelect = (id: number) => (date: moment.Moment) => {
    if (date !== null) {
      (this.props.reserveInfo as any).setApproveItemValue(id, date.format('YYYY-MM-DD'));
    } else {
      (this.props.reserveInfo as any).setApproveItemValue(id, null);
    }
  };

  handleApproveListChange = (id: number) => (itemList: ItemList | null) => {
    (this.props.reserveInfo as any).setApproveItemValueCode(id, itemList?.code);
    (this.props.reserveInfo as any).setApproveItemValue(id, itemList?.name);
  };

  handleApproveStagesChange = (stages: Stage[]) => {
    (this.props.reserveInfo as any).setApproveStages(stages);
  };

  handleApproveFileChange = (id: number, file?: ImageFile) => {
    (this.props.reserveInfo as any).setApproveItemFile(id, file);
  };

  handleApproveFileRemove = (id: number) => {
    (this.props.reserveInfo as any).removeApproveItemFile(id);
  };

  handleExpensesAccountTypeDetailChange = (
    changingMapping: OrderItemMapping,
    option: FilterableSelectorOption | null
  ) => {
    const values = option?.value.split('-'); // e.g. expenses-1
    if (values && values?.length === 2 && values[0] === 'expenses') {
      changingMapping.setExpensesAccountTypeId(Number(values[1]));
      changingMapping.setSelectedOptionLabel(option?.label || '');
    } else {
      changingMapping.setExpensesAccountTypeId(null);
      changingMapping.setSelectedOptionLabel('');
    }
  };

  // handleBackToSelect(e) {
  //   e.preventDefault();
  //   location.href = this.props.selectPageUrl || '';
  // }

  // handleIsRentalCar(e) {
  //   this.setState({ isRentalCar: e });
  // }

  validateInput = (e: React.MouseEvent) => {
    trackClick('/reserve_confirm#1 next page button');
    const validationErrors = this.props.reserveInfo.validationErrors(
      this.props.serviceId,
      this.props?.isSimpleRequest
    );
    this.setState({ validationErrors });
    if (_.isEmpty(validationErrors)) {
      // trips_confirmでは離脱チェックを挟んでいる関係で、onSuccess内で別の処理をしている
      if (this.props.isOnTripsConfirm) {
        e.preventDefault();
      }
      this.props.onSuccess();
    } else {
      e.preventDefault();
    }
  };

  // freee_auth_uriがあれば未認証、nullなら認証済み
  fetchFreeeAuthUri() {
    utils.jsonPromise<UserJson>('/organization/freee/auth_uri.json').then(response => {
      if (response.freee_auth_uri) {
        this.setState({
          isSubmitting: false
        });
      } else {
        this.setState({
          isSubmitting: true
        });
      }
    });
  }

  handleExicPasswordType = async (i: number) => {
    if (this.state.exicPasswordTypes[i]) {
      if (this.state.exicPasswordTypes[i] === 'password') {
        this.setState({
          exicPasswordTypes: this.state.exicPasswordTypes.map((value, index) => (index === i ? 'text' : value))
        });
      } else {
        this.setState({
          exicPasswordTypes: this.state.exicPasswordTypes.map((value, index) => (index === i ? 'password' : value))
        });
      }
    }
  };

  handleShowAlert = (showAlert: boolean) => {
    this.setState({ showAlert });
  };

  render() {
    try {
      return (
        <InputCustomerInformationTemplate
          {...this.props}
          {...this.state}
          handleTravelerTypeChange={this.handleTravelerTypeChange}
          handleTravelerSelect={this.handleTravelerSelect}
          handleTravelerInfoChange={this.handleTravelerInfoChange}
          handleTravelerBirthDayChange={this.handleTravelerBirthDayChange}
          handleDateChange={this.handleDateChange}
          handleReserveInfoChange={this.handleReserveInfoChange}
          handleHotelInfoChange={this.handleHotelInfoChange}
          handleRentalCarInfoChange={this.handleRentalCarInfoChange}
          handleRentalCarInfoAdd={this.handleRentalCarInfoAdd}
          handleRentalCarInfoRemove={this.handleRentalCarInfoRemove}
          handleNotifiedUserAdd={this.handleNotifiedUserAdd}
          handleNotifiedUserRemove={this.handleNotifiedUserRemove}
          handleNotifiedUserSelect={this.handleNotifiedUserSelect}
          handleAddressOrganizationBaseSelect={this.handleAddressOrganizationBaseSelect}
          handleAddressDepartmentSelect={this.handleAddressDepartmentSelect}
          validateInput={this.validateInput}
          handleChargingDepartmentSelect={this.handleChargingDepartmentSelect}
          handleShowDepartmentDetailClick={this.handleShowDepartmentDetailClick}
          handleCancelDepartmentDetailClick={this.handleCancelDepartmentDetailClick}
          handleDepartmentDetailModeChange={this.handleDepartmentDetailModeChange}
          handleDepartmentDetailChange={this.handleDepartmentDetailChange}
          handleProjectSelect={this.handleProjectSelect}
          isWithinSixMonth={isWithinSixMonth}
          isOverDate={isOverDate}
          handleShowProjectDetailClick={this.handleShowProjectDetailClick}
          handleCancelProjectDetailClick={this.handleCancelProjectDetailClick}
          handleProjectDetailModeChange={this.handleProjectDetailModeChange}
          handleProjectDetailChange={this.handleProjectDetailChange}
          handleTravelerNationalityChange={this.handleTravelerNationalityChange}
          handleShowExpensesAccountTypeDetailClick={this.handleShowExpensesAccountTypeDetailClick}
          handleCancelExpensesAccountTypeDetailClick={this.handleCancelExpensesAccountTypeDetailClick}
          handleExpensesAccountTypeDetailModeChange={this.handleExpensesAccountTypeDetailModeChange}
          handleExpensesAccountTypeDetailChange={this.handleExpensesAccountTypeDetailChange}
          handleExpensesAccountTypeSelect={this.handleExpensesAccountTypeSelect}
          handleApproveTextChange={this.handleApproveTextChange}
          handleApproveCalendarSelect={this.handleApproveCalendarSelect}
          handleApproveListChange={this.handleApproveListChange}
          handleApproveStagesChange={this.handleApproveStagesChange}
          handleApproveFileChange={this.handleApproveFileChange}
          handleApproveFileRemove={this.handleApproveFileRemove}
          handleExicPasswordType={this.handleExicPasswordType}
          handleShowAlert={this.handleShowAlert}
        />
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default InputCustomerInformation;
