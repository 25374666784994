import _ from 'lodash';
import Approver from './approver';

class ApproverList {
  list: Approver[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(raw: any) {
    this.list = _.toArray(raw).map(elem => new Approver(elem));
  }

  joinApproverByApproveStage(approve_stage: number) {
    return this.list.filter(approver => approver.approve_stage === approve_stage).map(approver => approver.name);
  }
}

export default ApproverList;
