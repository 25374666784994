import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import type { KnowledgeCategoryResponseArgs } from '@this/src/domain/knowledge_category';
import KnowledgeCategory, { convertKnowledgeCategoryResponseToArgs } from '@this/src/domain/knowledge_category';
import HowToCategoryDetail from '@this/components/knowledge/how_to_category_detail/how_to_category_detail';
import User from '@this/domain/user/user';
import { ByMedia } from '@this/shared/ui/other/by_media';
import { headerHeight } from '@this/shared/header/header.style';
import { getColor } from '@this/shared/ui/theme';
import HamburgerButton from '@this/shared/header/hamburger_button';
import type UserJson from '@this/domain/user/user_json';
import KnowledgeCategoryDetail from '@this/components/knowledge/knowledge_category_detail/knowledge_category_detail';
import KnowledgeDetail from '@this/components/knowledge/knowledge_detail/knowledge_detail';
import Header from '@this/shared/header/header';
import KnowledgeSearchResults from '@this/components/knowledge/knowledge_search_results/knowledge_search_results';
import { Loading } from '../shared/ui/feedbacks/loading';
import { Link } from '../shared/ui/navigations/link';

export const KnowledgePaths = [
  '/knowledges/:id',
  '/knowledge_categories/:id',
  '/how_to_categories/traveler_approver/:category_type',
  '/how_to_categories/admin/:category_type',
  '/knowledge_search_results'
];

export const AiTravelServiceId = 1;

interface KnowledgeCategoriesResponse {
  knowledge_categories: KnowledgeCategoryResponseArgs[];
}

interface SidebarContentProps {
  isLoading: boolean;
  knowledgeCategories: KnowledgeCategory[];
  user: User;
  active?: boolean;
}

const ChevronIcon: React.FC = () => (
  <img src="/images/help/sidebar/chevron_right_icon.svg" alt="chevron right" style={{ marginRight: '4px' }} />
);

const SidebarLink: React.FC<{ href: string; label: string }> = ({ href, label }) => (
  <li style={{ padding: '8px 0' }}>
    <Link
      href={href}
      style={{
        fontSize: '13px',
        color: 'white',
        fontWeight: 700,
        lineHeight: '19.50px',
        width: '100%',
        display: 'flex'
      }}
    >
      <ChevronIcon />
      {label}
    </Link>
  </li>
);

const renderSidebarLinks = (categories: KnowledgeCategory[]) =>
  categories.map(c => <SidebarLink key={c.id} href={`/knowledge_categories/${c.id}`} label={c.sfName} />);

const renderHowToLinks = () => (
  <>
    <Box padding="8px 0" fontSize="13px" fontWeight="700" marginLeft="20px">
      ガイド
    </Box>
    <HowToTarget>出張者・承認者向け情報</HowToTarget>
    <ul style={{ paddingLeft: '16px' }}>
      <SidebarLink href="/how_to_categories/traveler_approver/guides" label="ご利用はじめてガイド" />
      <SidebarLink href="/how_to_categories/traveler_approver/manuals" label="ご利用マニュアル" />
    </ul>
    <HowToTarget>管理者向け情報</HowToTarget>
    <ul style={{ paddingLeft: '16px' }}>
      <SidebarLink href="/how_to_categories/admin/guides" label="ご利用はじめてガイド" />
      <SidebarLink href="/how_to_categories/admin/manuals" label="ご利用マニュアル" />
    </ul>
  </>
);

const SidebarContentSp: React.FC<SidebarContentProps> = ({ active, isLoading, knowledgeCategories, user }) => (
  <SidebarSp active={active}>
    <Box padding="8px 0" fontSize="13px" fontWeight="700" marginLeft="20px">
      よくあるご質問
    </Box>
    <ul style={{ paddingLeft: '16px' }}>{isLoading ? <Loading /> : renderSidebarLinks(knowledgeCategories)}</ul>
    {user.isMember() && renderHowToLinks()}
  </SidebarSp>
);

const SidebarContent: React.FC<SidebarContentProps> = ({ isLoading, knowledgeCategories, user }) => (
  <Sidebar>
    <Box padding="8px 0" fontSize="13px" fontWeight="700" marginLeft="20px">
      よくあるご質問
    </Box>
    <ul style={{ paddingLeft: '16px' }}>{isLoading ? <Loading /> : renderSidebarLinks(knowledgeCategories)}</ul>
    {user.isMember() && renderHowToLinks()}
  </Sidebar>
);

const Knowledge: React.FC = observer(() => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [knowledgeCategories, setKnowledgeCategories] = useState<KnowledgeCategory[]>([]);
  const [active, setActive] = useState(false);
  const location = useLocation();

  const closeSidebar = () => {
    document.body.removeAttribute('style');
    setActive(false);
  };

  const openSidebar = () => {
    document.body.style.overflow = 'hidden';
    setActive(true);
  };

  const fetchKnowledgeCategories = useCallback(() => {
    setIsLoading(true);
    Fetcher.get<KnowledgeCategoriesResponse>('/knowledge_categories', { include_children: true })
      .then(res => {
        setKnowledgeCategories(
          res.knowledge_categories.map(raw => new KnowledgeCategory(convertKnowledgeCategoryResponseToArgs(raw)))
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchKnowledgeCategories();
  }, [fetchKnowledgeCategories]);

  useEffect(() => {
    Fetcher.get<UserJson>('/users.json').then(
      response => {
        setUser(new User(response));
      },
      () => {
        setUser(null);
      }
    );

    // ロケーションが変更されたらサイドバーを閉じる
    closeSidebar();
  }, []);

  useEffect(closeSidebar, [location]);

  const getDashboardLocation = () => (user && user.id ? '/dashboard' : '/');

  return (
    <TheKnowledge data-wovn-ignore>
      <Box display="flex" flexGrow="9999" flexDirection="column">
        <ByMedia>
          {matches =>
            matches.sm ? (
              <>
                <TheFaqHeader>
                  <FaqHeaderIn>
                    <h1>
                      <Logo href={getDashboardLocation()} />
                    </h1>
                    <Container>
                      <HamburgerButton active={active} onClick={() => (active ? closeSidebar() : openSidebar())} />
                    </Container>
                    {user && (
                      <SidebarContentSp
                        isLoading={isLoading}
                        knowledgeCategories={knowledgeCategories}
                        user={user}
                        active={active}
                      />
                    )}
                  </FaqHeaderIn>
                </TheFaqHeader>
                <Box
                  padding="16px"
                  flexGrow="9999"
                  display="flex"
                  flexDirection="row"
                  bgcolor="white"
                  justifyContent="center"
                >
                  <Switch>
                    <Route path="/knowledges/:id" component={KnowledgeDetail} />
                    <Route path="/knowledge_categories/:id" component={KnowledgeCategoryDetail} />
                    <Route
                      path="/how_to_categories/traveler_approver/:category_type"
                      render={props => <HowToCategoryDetail howToProps={{ isAdmin: false }} {...props} />}
                    />
                    <Route
                      path="/how_to_categories/admin/:category_type"
                      render={props => <HowToCategoryDetail howToProps={{ isAdmin: true }} {...props} />}
                    />
                    <Route path="/knowledge_search_results" component={KnowledgeSearchResults} />
                  </Switch>
                </Box>
              </>
            ) : (
              <>
                <Header user={user} />
                <Box display="flex" minHeight="100vh">
                  {user && (
                    <SidebarContent isLoading={isLoading} knowledgeCategories={knowledgeCategories} user={user} />
                  )}
                  <Box
                    paddingTop="16px"
                    flexGrow="9999"
                    display="flex"
                    flexDirection="row"
                    bgcolor="white"
                    justifyContent="center"
                  >
                    <Switch>
                      <Route path="/knowledges/:id" component={KnowledgeDetail} />
                      <Route path="/knowledge_categories/:id" component={KnowledgeCategoryDetail} />
                      <Route
                        path="/how_to_categories/traveler_approver/:category_type"
                        render={props => <HowToCategoryDetail howToProps={{ isAdmin: false }} {...props} />}
                      />
                      <Route
                        path="/how_to_categories/admin/:category_type"
                        render={props => <HowToCategoryDetail howToProps={{ isAdmin: true }} {...props} />}
                      />
                      <Route path="/knowledge_search_results" component={KnowledgeSearchResults} />
                    </Switch>
                  </Box>
                </Box>
              </>
            )
          }
        </ByMedia>
      </Box>
    </TheKnowledge>
  );
});

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
`;

const TheFaqHeader = styled.header`
  width: 100%;
  height: ${headerHeight};
`;

const FaqHeaderIn = styled.div`
  display: flex;
  align-items: center;
  background: ${getColor('background', 'tertiary')};
  border-bottom: 1px solid ${getColor('brand', 'primary')};
  height: ${headerHeight};
`;

const Logo = styled(Link)`
  display: block;
  width: 120px;
  height: 28px;
  margin-left: 10px;
  background-image: url('${props => props.theme.logoPath}');
  background-repeat: no-repeat;
  background-size: contain;
`;

const TheKnowledge = styled.div`
  flex-grow: 9999;
  display: flex;
  flex-direction: column;
  background: white;
`;

const SidebarSp = styled(Box)<{ active?: boolean }>`
  background: #292c35;
  color: white;
  padding: 10px 0;
  flex-shrink: 0;
  left: ${({ active }) => (active ? 0 : '100%')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.3s, left 0.3s;
  width: 100%;
  position: fixed;
  top: ${headerHeight};
  right: 0;
  bottom: 0;
  z-index: 1000;
`;

const Sidebar = styled(Box)`
  background: #292c35;
  color: white;
  width: 220px;
  padding: 10px 0;
  flex-shrink: 0;
`;

export const Title = styled.h1`
  color: ${getColor('text', 'primary')};
  font-size: 23px;
  font-weight: 700;
  line-height: 28.75px;
  word-wrap: break-word;
`;

export const CategoryTitle = styled.h2`
  color: #3a3a3a;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  word-wrap: break-word;
  margin: 0;
`;

export const ContentWrapper = styled.div`
  max-width: 980px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 60px;
`;

const HowToTarget = styled.div`
  color: #9ca3af;
  font-size: 11px;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  margin: 8px auto 8px 27px;
`;

export default Knowledge;
