import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Button } from '@this/shared/ui/inputs/button';
import { Fetcher } from '@this/src/util';

interface AirRouteTableProps {
  fromAirportFilter: string;
  onFromAirportChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  toAirportFilter: string;
  onToAirportChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface DomesticAirRouteResponse {
  data: DomesticAirRoute[];
}

interface DomesticAirRoute {
  id: number;
  from_airport: string;
  to_airport: string;
  class_name: string;
}

interface AirportData {
  from_airports: string[];
  to_airports: string[];
}

const AirRouteTable: React.FC<AirRouteTableProps> = ({
  fromAirportFilter,
  onFromAirportChange,
  toAirportFilter,
  onToAirportChange
}) => {
  const [airRoute, setAirRoute] = useState<DomesticAirRoute[]>([]);
  const [fromAirports, setFromAirports] = useState<string[]>([]);
  const [toAirports, setToAirports] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAirRoute = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      if (fromAirportFilter) params.append('from_airport', fromAirportFilter);
      if (toAirportFilter) params.append('to_airport', toAirportFilter);
      const response = await Fetcher.get<DomesticAirRouteResponse>(`/god/domestic_air_route?${params.toString()}`);
      setAirRoute(response.data);
    } catch (error) {
      setError('データの取得に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAirRoute();
  }, [fromAirportFilter, toAirportFilter]);

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const response = await Fetcher.get<AirportData>('/god/domestic_air/index_air_route_airports');
        setFromAirports(response.from_airports);
        setToAirports(response.to_airports);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchAirports();
  }, []);

  const handleSave = async (id: number, updatedItem: DomesticAirRoute) => {
    setLoading(true);
    try {
      await Fetcher.put(`/god/domestic_air_route/${id}`, updatedItem);
      fetchAirRoute();
    } catch (error) {
      setError('データの更新に失敗しました');
    }
    setLoading(false);
  };

  const handleInputChange = (id: number, field: keyof DomesticAirRoute, value: string | number) => {
    setAirRoute(prevAirRoute => prevAirRoute.map(item => (item.id === id ? { ...item, [field]: value } : item)));
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container style={{ fontSize: '10px' }}>
      <Filters>
        <div>
          <label htmlFor="from-airport">出発空港</label>
          <select id="from-airport" value={fromAirportFilter} onChange={onFromAirportChange}>
            <option value="">すべて</option>
            {fromAirports.map((airport, index) => (
              <option key={index} value={airport}>
                {airport}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="to-airport">到着空港</label>
          <select id="to-airport" value={toAirportFilter} onChange={onToAirportChange}>
            <option value="">すべて</option>
            {toAirports.map((airport, index) => (
              <option key={index} value={airport}>
                {airport}
              </option>
            ))}
          </select>
        </div>
      </Filters>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>出発空港</th>
            <th>到着空港</th>
            <th>クラス名</th>
            <th>アクション</th>
          </tr>
        </thead>
        <tbody>
          {airRoute.map(p => (
            <tr key={p.id}>
              <td>{p.id}</td>
              <td>{p.from_airport}</td>
              <td>{p.to_airport}</td>
              <td>
                <input
                  type="text"
                  value={p.class_name}
                  onChange={e => handleInputChange(p.id, 'class_name', e.target.value)}
                />
              </td>
              <td style={{ paddingLeft: '10px' }}>
                <Button onClick={() => handleSave(p.id, p)}>保存</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

const Filters = styled.div`
  display: flex;
`;

export default observer(AirRouteTable);
