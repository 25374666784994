import { observable } from 'mobx';

export interface ExclusionAirlineCodeResponse {
  airline_code?: string;
  airline_name?: string;
}

type ExclusionAirlineCodeInputType = 'autocomplete' | 'textfield';

class ExclusionAirlineCode {
  @observable airlineCode: string;

  @observable airlineName: string;

  @observable inputType: ExclusionAirlineCodeInputType = 'autocomplete';

  constructor(response: ExclusionAirlineCodeResponse) {
    this.airlineCode = response.airline_code || '';
    this.airlineName = response.airline_name || '';

    if (this.airlineName !== '') {
      this.inputType = 'textfield';
    }
  }

  changeAirlineCode(airlineCode: string) {
    this.airlineCode = airlineCode.toUpperCase().slice(0, 2);
    app.render();
  }

  changeAirlineName(airlineName: string) {
    this.airlineName = airlineName;
    app.render();
  }

  toggleInputType() {
    this.inputType = this.inputType === 'autocomplete' ? 'textfield' : 'autocomplete';
    app.render();
  }

  submitParams() {
    return {
      airline_code: this.airlineCode,
      airline_name: this.airlineName
    };
  }
}

export default ExclusionAirlineCode;
