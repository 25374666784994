import moment from 'moment';
import type ElementBaseInterface from '@this/domain/element_base_interface';

export type KitElementJson = {
  kind?: string;
  name?: string;
  startedAt?: string;
  endedAt?: string;
  kitSize?: number;
  stockNumber?: string;
  lotNumber?: string;
  remark?: string;
};

class KitElement implements ElementBaseInterface {
  type: string;

  kind: string;

  name: string;

  startedAt: moment.Moment;

  endedAt: moment.Moment;

  kitSize: number;

  stockNumber: string;

  lotNumber: string;

  remark: string;

  constructor(args: KitElementJson = {}) {
    this.type = 'kit';
    this.kind = args.kind ? args.kind : 'PCR検査キット';
    this.name = args.name ? args.name : '新型コロナ抗原検査キット';
    this.startedAt = moment(args.startedAt);
    this.endedAt = args.endedAt ? moment(args.endedAt) : moment(this.startedAt).add(1, 'hour');
    this.kitSize = args.kitSize ? args.kitSize : 0;
    this.stockNumber = args.stockNumber ? args.stockNumber : '';
    this.lotNumber = args.lotNumber ? args.lotNumber : '';
    this.remark = args.remark ? args.remark : '';
  }

  setName(value: string) {
    this.name = value;
    return app.render();
  }

  setKind(value: string) {
    this.kind = value;
    return app.render();
  }

  setKitSize(value: number) {
    this.kitSize = value;
    return app.render();
  }

  setLotNumber(value: string) {
    this.lotNumber = value;
    return app.render();
  }

  setStockNumber(value: string) {
    this.stockNumber = value;
    return app.render();
  }

  setRemark(value: string) {
    this.remark = value;
    return app.render();
  }

  updateParams() {
    return {
      type: this.type,
      name: this.name,
      kind: this.kind,
      started_at: this.startedAt.format('YYYY-MM-DD HH:mm:ss'),
      ended_at: this.endedAt.format('YYYY-MM-DD HH:mm:ss'),
      kit_size: this.kitSize,
      stock_number: this.stockNumber,
      lot_number: this.lotNumber,
      remark: this.remark
    };
  }

  startDate() {
    return this.startedAt;
  }

  endDate() {
    return this.endedAt;
  }

  description() {
    return `\
<新型コロナ検査キット>
■数量：　${this.kitSize} 個
■備考
${this.remark}\
`;
  }

  title() {
    return '新型コロナ検査キット';
  }

  summary() {
    return '[新型コロナ検査キット]';
  }

  detail() {
    return '';
  }

  structuredDetail() {
    return [];
  }

  mappingDescription() {
    return this.title();
  }

  validationErrors() {
    const errors = [];
    if (this.stockNumber === '') {
      errors.push('在庫管理番号を入力してください。');
    }
    return errors;
  }
}

export default KitElement;
