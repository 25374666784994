import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback } from 'react';
import type { Moment } from 'moment';
import moment from 'moment';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import { Select } from '@this/src/components/shared/ui/inputs/select';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import type { OrderItemStepAssignType } from '@this/src/domain/arrangement/order_item_step_todo';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobTypeNumKeys } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStepStatusKey } from '@this/src/domain/order_item/order_item_step_status';
import { OrderItemStepStatusOngoing } from '@this/src/domain/order_item/order_item_step_status';
import type { OrderItemStatusKey } from '@this/src/domain/order_item/order_item_status';
import { OrderItemStatusOngoing } from '@this/src/domain/order_item/order_item_status';
import type { TripStatusKey } from '@this/src/domain/trip/trip_status';
import { TripStatusOngoing } from '@this/src/domain/trip/trip_status';
import type { OrderItemCategoryKey } from '@this/src/domain/order_item/order_item_category';
import { OrderItemCategoryKeys } from '@this/src/domain/order_item/order_item_category';
import type { ElementQueryKey } from '@this/src/domain/order_item/element_query';
import { ElementQuery, ElementQueryKeys } from '@this/src/domain/order_item/element_query';
import type { SnoozeStatusKey } from '@this/src/domain/order_item/snooze_status';
import { SnoozeStatus, SnoozeStatusKeys } from '@this/src/domain/order_item/snooze_status';
import type { WaitingStatusKey } from '@this/src/domain/order_item/waiting_status';
import { WaitingStatus, WaitingStatusKeys } from '@this/src/domain/order_item/waiting_status';
import type { SpQueryKey } from '@this/src/domain/order_item/sp_query';
import { SpQuery, SpQueryKeys } from '@this/src/domain/order_item/sp_query';
import type { UseQrQueryKey } from '@this/src/domain/organization/use_qr_query';
import { UseQrQuery, UseQrQueryKeys } from '@this/src/domain/organization/use_qr_query';
import { Box } from '@material-ui/core';
import OrderItemStatusSelector from '../order_item_status_selector';
import { SearchArea, SearchBlock, SearchLabel } from '../search_area';
import TripStatusSelector from '../trip_status_selector';
import DateRangePicker from '../date_range_picker';
import OrderItemStepCategorySelector from './order_item_step_category_selector';
import type { State } from './order_item_step_todo_list';
import OrderItemStepStatusSelector from '../order_item_step_status_selector';

export type SortKey = 'received_at' | 'started_at';
export type SortDirection = 'asc' | 'desc';

export interface UrlQuery {
  assign_type: OrderItemStepAssignType;
  step_statuses: OrderItemStepStatusKey[];
  job_types: OrderItemJobTypeKey[];
  statuses: OrderItemStatusKey[];
  trip_statuses: TripStatusKey[];
  categories: OrderItemCategoryKey[];
  element_query: ElementQueryKey;
  only_others: boolean;
  only_manual: boolean;
  only_premium_support: boolean;
  waiting: WaitingStatusKey;
  sp: SpQueryKey;
  use_qr: UseQrQueryKey;
  trip_id: number | undefined;
  start_date_from: Moment | undefined;
  start_date_to: Moment | undefined;
  receive_date_from: Moment | undefined;
  receive_date_to: Moment | undefined;
  only_unchanged: boolean;
  sort_key: SortKey;
  sort_direction: SortDirection;
  snooze_status: SnoozeStatusKey;
  assigned_duration_minutes_from: number | undefined;
  assigned_duration_minutes_to: number | undefined;
  order_item_visible: boolean;
}

interface Props {
  urlQuery: UrlQuery;
  renderOrganizationSelector: () => JSX.Element;
  renderArrangerSelector: () => JSX.Element;
  renderMasterStepSequenceSelector: () => JSX.Element;
  setState: Dispatch<SetStateAction<State>>;
  assignType: string;
}

const getNumberParam = (key: string): number | undefined => {
  const str = utils.getParam(key);
  return str ? parseInt(str, 10) : undefined;
};

const getNumbersParam = (key: string): number[] | undefined => {
  const str = utils.getParam(key);
  return str ? str.split(',').map((str: string) => parseInt(str, 10)) : undefined;
};

const getBooleanParam = (key: string): boolean => {
  return utils.getParam(key) === 'true';
};

const getMomentParam = (key: string): Moment | undefined => {
  const str = utils.getParam(key);
  return str ? moment(str) : undefined;
};

const getGenericsParam = <T extends string>(key: string): T | undefined => {
  const str = utils.getParam(key);
  return str ? (str as T) : undefined;
};

const getDefaultCategories = (): OrderItemCategoryKey[] => {
  return (utils
    .getParam('categories')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) ||
    OrderItemCategoryKeys.filter(k => k !== 'message')) as OrderItemCategoryKey[];
};

export const initialUrlQuery = (): UrlQuery => ({
  assign_type: getGenericsParam<OrderItemStepAssignType>('assign_type') || 'assigned_to_me',
  step_statuses: (getNumbersParam('step_statuses') as OrderItemStepStatusKey[]) || OrderItemStepStatusOngoing,
  job_types: (getNumbersParam('job_types') as OrderItemJobTypeKey[]) || OrderItemJobTypeNumKeys,
  statuses: (getNumbersParam('statuses') as OrderItemStatusKey[]) || OrderItemStatusOngoing,
  trip_statuses: (getNumbersParam('trip_statuses') as TripStatusKey[]) || TripStatusOngoing,
  categories: getDefaultCategories(),
  element_query: getGenericsParam<ElementQueryKey>('element_query') || 'all',
  only_others: getBooleanParam('only_others'),
  only_manual: getBooleanParam('only_manual'),
  only_premium_support: getBooleanParam('only_premium_support'),
  waiting: getGenericsParam<WaitingStatusKey>('waiting') || 'without',
  sp: getGenericsParam<SpQueryKey>('sp') || 'all',
  use_qr: getGenericsParam<UseQrQueryKey>('use_qr') || 'all',
  trip_id: getNumberParam('trip_id'),
  start_date_from: getMomentParam('start_date_from'),
  start_date_to: getMomentParam('start_date_to'),
  receive_date_from: getMomentParam('receive_date_from'),
  receive_date_to: getMomentParam('receive_date_to'),
  only_unchanged: getBooleanParam('only_unchanged'),
  sort_key: getGenericsParam<SortKey>('sort_key') || 'started_at',
  sort_direction: getGenericsParam<SortDirection>('sort_direction') || 'asc',
  snooze_status: getGenericsParam<SnoozeStatusKey>('snooze_status') || 'todo',
  assigned_duration_minutes_from: getNumberParam('assigned_duration_minutes_from'),
  assigned_duration_minutes_to: getNumberParam('assigned_duration_minutes_to'),
  order_item_visible: getBooleanParam('order_item_visible')
});

export const OrderItemStepTodoSearchArea: React.FC<Props> = ({
  urlQuery,
  renderOrganizationSelector,
  renderArrangerSelector,
  renderMasterStepSequenceSelector,
  setState,
  assignType
}) => {
  const {
    step_statuses: stepStatuses,
    job_types: jobTypes,
    statuses,
    trip_statuses: tripStatuses,
    categories,
    element_query: elementQuery,
    only_others: onlyOthers,
    only_manual: onlyManual,
    only_premium_support: onlyPremiumSupport,
    waiting,
    sp,
    use_qr: useQr,
    trip_id: tripId,
    start_date_from: startDateFrom,
    start_date_to: startDateTo,
    receive_date_from: receiveDateFrom,
    receive_date_to: receiveDateTo,
    only_unchanged: onlyUnchanged,
    sort_key: sortKey,
    sort_direction: sortDirection,
    snooze_status: snoozeStatus,
    assigned_duration_minutes_from: assignedDurationMinutesFrom,
    assigned_duration_minutes_to: assignedDurationMinutesTo,
    order_item_visible: orderItemVisible
  } = urlQuery;

  const setUrlQueryField = useCallback(
    <T extends UrlQuery>(key: keyof T) =>
      (value: T[keyof T]) => {
        setState(state => ({ ...state, urlQuery: { ...state.urlQuery, [key]: value } }));
      },
    []
  );

  const setUrlQuery = useCallback(<T extends UrlQuery>(key: keyof T, value: T[keyof T]) => {
    setState(state => ({ ...state, urlQuery: { ...state.urlQuery, [key]: value } }));
  }, []);

  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>ステップステータス</SearchLabel>
        <OrderItemStepStatusSelector
          selectedOrderItemStepStatuses={stepStatuses}
          onChangeSelectedOrderItemStepStatuses={setUrlQueryField('step_statuses')}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>商品ステータス</SearchLabel>
        <OrderItemStatusSelector
          selectedOrderItemJobTypes={jobTypes}
          onChangeSelectedOrderItemJobTypes={setUrlQueryField('job_types')}
          selectedOrderItemStatuses={statuses}
          onChangeSelectedOrderItemStatuses={setUrlQueryField('statuses')}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>旅程ステータス</SearchLabel>
        <TripStatusSelector
          selectedTripStatuses={tripStatuses}
          onChangeSelectedTripStatuses={setUrlQueryField('trip_statuses')}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>カテゴリ</SearchLabel>
        <OrderItemStepCategorySelector
          selectedOrderItemCategories={categories}
          onChange={setUrlQueryField('categories')}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>エレメント</SearchLabel>
        <Select
          value={elementQuery}
          onChange={e => setUrlQuery('element_query', e.target.value as ElementQueryKey)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          {ElementQueryKeys.map(key => (
            <option key={key} value={key}>
              {ElementQuery[key]}
            </option>
          ))}
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>スヌーズ</SearchLabel>
        <Select
          value={snoozeStatus}
          onChange={e => setUrlQuery('snooze_status', e.target.value as SnoozeStatusKey)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          {SnoozeStatusKeys.map(key => (
            <option key={key} value={key}>
              {SnoozeStatus[key]}
            </option>
          ))}
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>引き継ぎ</SearchLabel>
        <Select
          value={waiting}
          onChange={e => setUrlQuery('waiting', e.target.value as WaitingStatusKey)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          {WaitingStatusKeys.map(key => (
            <option key={key} value={key}>
              {WaitingStatus[key]}
            </option>
          ))}
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>SP</SearchLabel>
        <Select
          value={sp}
          onChange={e => setUrlQuery('sp', e.target.value as SpQueryKey)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          {SpQueryKeys.map(key => (
            <option key={key} value={key}>
              {SpQuery[key]}
            </option>
          ))}
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>QR利用企業</SearchLabel>
        <Select
          value={useQr}
          onChange={e => setUrlQuery('use_qr', e.target.value as UseQrQueryKey)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          {UseQrQueryKeys.map(key => (
            <option key={key} value={key}>
              {UseQrQuery[key]}
            </option>
          ))}
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>Trip ID</SearchLabel>
        <Input
          value={tripId || ''}
          onChange={e => {
            const value = parseInt(e.target.value, 10);
            setUrlQuery('trip_id', isNaN(value) ? undefined : value);
          }}
          style={{ width: '80px' }}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>出発日</SearchLabel>
        <DateRangePicker
          from={startDateFrom}
          to={startDateTo}
          onFromChange={d => setUrlQuery('start_date_from', d)}
          onToChange={d => setUrlQuery('start_date_to', d)}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>依頼日</SearchLabel>
        <DateRangePicker
          from={receiveDateFrom}
          to={receiveDateTo}
          onFromChange={d => setUrlQuery('receive_date_from', d)}
          onToChange={d => setUrlQuery('receive_date_to', d)}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>担当者</SearchLabel>
        {renderArrangerSelector()}
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>シーケンス</SearchLabel>
        {renderMasterStepSequenceSelector()}
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>法人</SearchLabel>
        {renderOrganizationSelector()}
      </SearchBlock>
      {assignType !== 'unassigned' && (
        <SearchBlock>
          <SearchLabel>アサインからの経過時間</SearchLabel>
          <Input
            value={assignedDurationMinutesFrom || ''}
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              setUrlQuery('assigned_duration_minutes_from', isNaN(value) ? undefined : value);
            }}
            style={{ width: '80px' }}
          />
          <Box>〜</Box>
          <Input
            value={assignedDurationMinutesTo || ''}
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              setUrlQuery('assigned_duration_minutes_to', isNaN(value) ? undefined : value);
            }}
            style={{ width: '80px' }}
          />
        </SearchBlock>
      )}
      <SearchBlock>
        <Checkbox checked={onlyOthers} onChange={e => setUrlQuery('only_others', e.target.checked)}>
          <b style={{ fontSize: '12px' }}>ステータス最終更新者が自分以外</b>
        </Checkbox>
      </SearchBlock>
      <SearchBlock>
        <Checkbox checked={onlyUnchanged} onChange={e => setUrlQuery('only_unchanged', e.target.checked)}>
          <b style={{ fontSize: '12px' }}>依頼時からの内容変更がない商品のみ</b>
        </Checkbox>
      </SearchBlock>
      <SearchBlock>
        <Checkbox checked={onlyManual} onChange={e => setUrlQuery('only_manual', e.target.checked)}>
          <b style={{ fontSize: '12px' }}>手動作成のみ</b>
        </Checkbox>
      </SearchBlock>
      <SearchBlock>
        <Checkbox
          checked={onlyPremiumSupport}
          onChange={e => setUrlQuery('only_premium_support', e.target.checked)}
        >
          <b style={{ fontSize: '12px' }}>土日祝オプション企業のみ</b>
        </Checkbox>
      </SearchBlock>
      <SearchBlock>
        <Checkbox checked={orderItemVisible} onChange={e => setUrlQuery('order_item_visible', e.target.checked)}>
          <b style={{ fontSize: '12px' }}>OrderItem項目を表示</b>
        </Checkbox>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>並び順</SearchLabel>
        <Select
          value={sortKey}
          onChange={e => setUrlQuery('sort_key', e.target.value as SortKey)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          <option value="received_at">依頼日</option>
          <option value="started_at">出発日</option>
        </Select>
        <Radio
          checked={sortDirection === 'asc'}
          onChange={() => setUrlQuery('sort_direction', 'asc')}
          style={{ marginRight: '5px' }}
        >
          昇順▲
        </Radio>
        <Radio checked={sortDirection === 'desc'} onChange={() => setUrlQuery('sort_direction', 'desc')}>
          降順▼
        </Radio>
      </SearchBlock>
    </SearchArea>
  );
};
