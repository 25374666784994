import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';

import type Traveler from '@this/domain/traveler/traveler';
import Modal from '@this/components/shared/modal/modal';
import { Loading } from '../../shared/ui/feedbacks/loading/loading';
// import NotifiedUserSelectorTemplate from '@this/shared/user_selector/notified_user_selector.template';

interface Props {
  applicant: any;
  users: any[] | null;
  showConfirmationModal: boolean;
  handleSubmitConfirmation: () => void;
  handleCancelConfirmation: () => void;
  handleNotifiedUserAdd: (e: React.MouseEvent<HTMLElement>) => void;
  handleNotifiedUserSelect: (i: number, traveler: Traveler) => void;
  handleNotifiedUserRemove: (e: React.MouseEvent<HTMLElement>, i: number) => void;
}

const ConfirmationModalTemplate: React.SFC<Props> = ({
  applicant,
  users,
  showConfirmationModal,
  handleSubmitConfirmation,
  handleCancelConfirmation,
  handleNotifiedUserAdd,
  handleNotifiedUserSelect,
  handleNotifiedUserRemove
}) => (
  <Modal hideModal={handleCancelConfirmation} show={showConfirmationModal} title="見積もり内容の承認">
    <Hint>見積もり内容を承認します。よろしいですか？</Hint>
    {users === null ? (
      <Loading />
    ) : (
      <>
        {/*
          本当はadminの設定値に合わせてここのフォームの表示/非表示はコントロールされないとならない
          <Hint>この旅程に関するメール通知対象者の変更が必要な場合は、下記より設定してください。</Hint>
          <NotifiedUserSelectorTemplate
            applicant={applicant}
            users={users}
            onAdd={handleNotifiedUserAdd}
            onSelect={handleNotifiedUserSelect}
            onRemove={handleNotifiedUserRemove}
          />
        */}
        <Buttons>
          <AButton onClick={handleSubmitConfirmation}>承認する</AButton>
          <BackButton onClick={handleCancelConfirmation}>戻る</BackButton>
        </Buttons>
      </>
    )}
  </Modal>
);

const Hint = styled.p`
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  margin-top 20px;
  text-align: center;
`;

const AButton = styled.a`
  &&& {
    ${ButtonBase}
    display: inline-block;
    padding: 7px 10px;
  }
`;

const BackButton = styled.button`
  ${ButtonBase}
  display: inline-block;
  margin-left: 20px;
`;

export default ConfirmationModalTemplate;
