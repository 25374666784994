User = require('../user/user')
MarginType = require('./margin_type')
BulkTicket = require('../bulk_ticket2').default
FreeeCompany = require('../freee_company').default
OrganizationBillReceiver = require('./organization_bill_receiver').default
ArrangementRequestSetting = require('./arrangement_request_setting').default
ShinkansenLimitType = require('./shinkansen_limit_type').default
RentalCarLimitType = require('./rental_car_limit_type').default

class Organization
  @revenueReferenceDateTypes =
    'confirmed_at': '予約確定日'
    'end_date': '出張完了日'

  @orientations =
    'trip': '出張予約'
    'expenses': '経費精算'

  constructor: (args = {}) ->
    _.assign @, args
    @members = _.map((args.members || []), (m) -> new User(m))
    @margin_types = _.map((args.margin_types || []), (m) -> new MarginType(m))
    @domestic_margin_type =
      _.find(@margin_types, (m) -> m.isDomestic()) || new MarginType()
    @foreign_margin_type =
      _.find(@margin_types, (m) -> m.isForeign()) || new MarginType({ category: 'foreign' })
    @bulkTickets = _.map (args.bulk_tickets || []), (t) -> new BulkTicket(t)
    @planId = args.plan_id
    @selectedFreeeCompanyId = args.selected_freee_company_id
    @freeeCompanies = if args.freee_companies then args.freee_companies.map (raw) -> new FreeeCompany(raw)
    @freeePartnerId = args.freee_partner_id
    @freeeMonthlyPriceSectionId = args.freee_monthly_price_section_id
    @isCreateFreeeTag = args.is_create_freee_tag
    @freeeDepartment = args.freee_department
    @ssoEnabled = args.sso_enabled
    @ssoEntityId = args.sso_entity_id
    @ssoUrl = args.sso_url
    @ssoLogoutUrl = args.sso_logout_url
    @ssoIdpCert = args.sso_idp_cert
    @enableRentalcarForm = args.enable_rentalcar_form

    # for /admin/organizations
    @amadeusCompanyName = args.amadeus_company_name
    @personInChargeName = args.person_in_charge_name
    @personInChargeEmail = args.person_in_charge_email
    @billingEmail = args.billing_email
    @bankAccountInfo = args.bank_account_info
    @agencyBankAccountInfo = args.agency_bank_account_info
    @memberCount = args.member_count
    @adminCount = args.admin_count
    @latestPlanName = args.latest_plan_name
    @showFee = args.show_fee
    @includeTax = args.include_tax
    @useBulkTicket = args.use_bulk_ticket
    @useKyuusyuuTicket = args.use_kyuusyuu_ticket
    @shinkansenLimitType = new ShinkansenLimitType(args.shinkansen_limit_type || {})
    @rentalCarLimitType = new RentalCarLimitType(args.rental_car_limit_type || {})
    @showEx = args.show_ex
    @domesticMarginType = _.find(@margin_types, (m) -> m.isDomestic()) || new MarginType()
    @foreignMarginType = _.find(@margin_types, (m) -> m.isForeign()) || new MarginType({ category: 'foreign' })
    @rakutenInnerFeeRate = args.rakuten_inner_fee_rate
    @exicAgencyFeeRate = args.exic_agency_fee_rate
    @revenueReferenceDateType = args.revenue_reference_date_type
    @expediaType = args.expedia_type
    @organizationBillReceivers =
      if args.organization_bill_receivers
        _.map args.organization_bill_receivers, (r) -> new OrganizationBillReceiver(r)
      else
        []
    @arrangementRequestSetting = new ArrangementRequestSetting(args.arrangement_request_setting || {})
    @requestFormApprovalRequired = args.request_form_approval_required
    @salesforceId = args.salesforce_id
    @salesforceImportId = args.salesforce_import_id
    @enterpriseIdentifactionNumber = args.enterprise_identification_number
    @invoiceDay = args.invoice_day
    @serviceId = args.service_id
    @dayOffOperation = args.day_off_operation
    @enableAutoStockCheck = args.enable_auto_stock_check
    @enableBatchApproval = args.enable_batch_approval
    @useSmartSearch = args.use_smart_search

  revenueReferenceDateTypeText: ->
    Organization.revenueReferenceDateTypes[@revenue_reference_date_type]

  orientationText: ->
    Organization.orientations[@orientation]

  ssoIdpCertSet: ->
    !_.isEmpty(@ssoIdpCert)

  setName: (value) ->
    @name = value
    app.render()

  setDomain: (value) ->
    @domain = value
    app.render()

  setUrl: (value) ->
    @url = value
    app.render()

  setAmadeusCompanyName: (value) ->
    @amadeus_company_name = value
    @amadeusCompanyName = value
    app.render()

  setAddress: (value) ->
    @address = value
    app.render()

  setTel: (value) ->
    @tel = value
    app.render()

  setPersonInChargeName: (value) ->
    @person_in_charge_name = value
    @personInChargeName = value
    app.render()

  setPersonInChargeEmail: (value) ->
    @person_in_charge_email = value
    @personInChargeEmail = value
    app.render()

  setBillingEmail: (value) ->
    @billing_email = value
    @billingEmail = value
    app.render()

  setBankAccountInfo: (value) ->
    @bank_account_info = value
    @bankAccountInfo = value
    app.render()

  setAgencyBankAccountInfo: (value) ->
    @agency_bank_account_info = value
    @agencyBankAccountInfo = value
    app.render()

  setDomesticBaseType: (value) ->
    @domestic_margin_type.setBaseType(value)
    @domesticMarginType.setBaseType(value)
    app.render()

  setDomesticBaseAmount: (value) ->
    @domestic_margin_type.setBaseAmount(value)
    @domesticMarginType.setBaseAmount(value)
    app.render()

  setForeignBaseType: (value) ->
    @foreign_margin_type.setBaseType(value)
    @foreignMarginType.setBaseType(value)
    app.render()

  setForeignBaseAmount: (value) ->
    @foreign_margin_type.setBaseAmount(value)
    @foreignMarginType.setBaseAmount(value)
    app.render()

  setPlanId: (value) ->
    @plan_id = parseInt value
    app.render()

  setRakutenInnerFeeRate: (value) ->
    num = parseInt(value)
    @rakuten_inner_fee_rate = if num || num == 0 then num else ''
    @rakutenInnerFeeRate = if num || num == 0 then num else ''
    app.render()

  setExicAgencyFeeRate: (value) ->
    num = parseInt(value)
    @exic_agency_fee_rate = if num || num == 0 then num else ''
    @exicAgencyFeeRate = if num || num == 0 then num else ''
    app.render()

  setRevenueReferenceDateType: (value) ->
    @revenue_reference_date_type = value
    @revenueReferenceDateType = value
    app.render()

  setRequestFormApprovalRequired: (value) ->
    @request_form_approval_required = value
    @requestFormApprovalRequired = value
    app.render()

  setOrientation: (value) ->
    @orientation = value
    app.render()

  setInvoiceDay: (value) ->
    @invoiceDay = value
    app.render()

  toggleShowFee: ->
    @show_fee = !@show_fee
    @showFee = !@showFee
    app.render()

  toggleIncludeTax: ->
    @include_tax = !@include_tax
    @includeTax = !@includeTax
    app.render()

  toggleUseBulkTicket: ->
    @use_bulk_ticket = !@use_bulk_ticket
    @useBulkTicket = !@useBulkTicket
    app.render()

  toggleUseKyuusyuuTicket: ->
    @use_kyuusyuu_ticket = !@use_kyuusyuu_ticket
    @useKyuusyuuTicket = !@useKyuusyuuTicket
    app.render()

  toggleShowEx: ->
    @show_ex = !@show_ex
    @showEx = !@showEx
    app.render()

  toggleEnableRentalcarForm: ->
    @enable_rentalcar_form = !@enable_rentalcar_form
    @enableRentalcarForm = !@enableRentalcarForm
    app.render()

  toggleDayOffOperation: ->
    @dayOffOperation = !@dayOffOperation
    app.render

  toggleEnableAutoStockCheck: ->
    @enableAutoStockCheck = !@enableAutoStockCheck
    app.render

  toggleEnableBatchApproval: ->
    @enableBatchApproval = !@enableBatchApproval
    app.render

  toggleUseSmartSearch: ->
    @use_smart_search = !@use_smart_search
    @useSmartSearch = !@useSmartSearch
    app.render()

  setSelectedFreeeCompanyId: (value) ->
    @selectedFreeeCompanyId = value
    app.render()

  setExpediaType: (value) ->
    @expediaType = value
    app.render()

  expediaTypeEan: () ->
    @expediaType == 'ean'

  expediaTypeEpsRapid: () ->
    @expediaType == 'eps_rapid'

  setFreeePartnerId: (value) ->
    @freeePartnerId = value || null
    app.render()

  setFreeeMonthlyPriceSectionId: (value) ->
    @freeeMonthlyPriceSectionId = value || null
    app.render()

  setSalesforceId: (value) ->
    @salesforceId = value
    app.render()

  setSalesforceImportId: (value) ->
    @salesforceImportId = value
    app.render()

  setEnterpriseIdentifactionNumber: (value) ->
    @enterpriseIdentifactionNumber = value
    app.render()

  addBillReceiver: () ->
    receiver = new OrganizationBillReceiver(organization_id: @id)
    @organizationBillReceivers.push(receiver)
    app.render()

  removeBillReceiverAtIndex: (index) ->
    @organizationBillReceivers.splice(index, 1)
    app.render()

  toggleSsoEnabled: () ->
    @ssoEnabled = !@ssoEnabled
    app.render()

  setSsoEntityId: (value) ->
    @ssoEntityId = value
    app.render()

  setSsoUrl: (value) ->
    @ssoUrl = value
    app.render()

  setSsoLogoutUrl: (value) ->
    @ssoLogoutUrl = value
    app.render()

  setSsoIdpCertFile: (file) ->
    reader = new FileReader()
    reader.onload = (e) =>
      @ssoIdpCert = e.target.result
      app.render()
    reader.readAsText(file)

  submitParams: ->
    params =
      name: @name
      domain: @domain
      url: @url
      amadeus_company_name: @amadeus_company_name
      address: @address
      tel: @tel
      person_in_charge_name: @person_in_charge_name
      person_in_charge_email: @person_in_charge_email
      billing_email: @billing_email
      bank_account_info: @bank_account_info
      agency_bank_account_info: @agency_bank_account_info
      show_fee: if @show_fee then 1 else 0
      include_tax: if @include_tax then 1 else 0
      use_bulk_ticket: if @use_bulk_ticket then 1 else 0
      use_kyuusyuu_ticket: if @use_kyuusyuu_ticket then 1 else 0
      margin_types_attributes: [@domestic_margin_type.submitParams(), @foreign_margin_type.submitParams()]
      show_ex: if @show_ex then 1 else 0
      plan_id: @plan_id
      rakuten_inner_fee_rate: @rakuten_inner_fee_rate
      exic_agency_fee_rate: @exic_agency_fee_rate
      revenue_reference_date_type: @revenue_reference_date_type
      orientation: @orientation
      expedia_type: @expediaType
      organization_bill_receivers_attributes: _.map @organizationBillReceivers, (r) -> r.submitParams()
      invoice_day: @invoiceDay
      request_form_approval_required: @request_form_approval_required
      enable_rentalcar_form: @enable_rentalcar_form
      day_off_operation: @dayOffOperation
      enable_auto_stock_check: @enableAutoStockCheck
      enable_batch_approval: @enableBatchApproval
      use_smart_search: @useSmartSearch
    if @arrangementRequestSetting
      params.arrangement_request_setting_attributes = @arrangementRequestSetting.submitParams()
    if !_.isEmpty(@salesforceId)
      params.salesforce_id = @salesforceId
    if !_.isEmpty(@salesforceImportId)
      params.salesforce_import_id = @salesforceImportId
    if !_.isEmpty(@enterpriseIdentifactionNumber)
      params.enterprise_identification_number = @enterpriseIdentifactionNumber
    if @shinkansenLimitType
      params.shinkansen_limit_type_attributes = @shinkansenLimitType.submitParams()
    if @rentalCarLimitType
      params.rental_car_limit_type_attributes = @rentalCarLimitType.submitParams()
    if @packageLimitType
      params.package_limit_type_attributes = @packageLimitType.submitParams()
    params

module.exports = Organization
