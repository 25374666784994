import React from 'react';
import type { Moment } from 'moment';
import { Button } from '@this/shared/ui/inputs/button';
import type HotelElement from '@this/src/domain/hotel_element';
import CancelLimit from '@this/domain/cancel_limit';

interface Props {
  hotelElement: HotelElement;
  checkinDate: Moment;
  classNameForModifiedField: (...path: (string | number)[]) => string;
}

const CancelLimitForm = ({ hotelElement, checkinDate, classNameForModifiedField }: Props) => {
  const handleRemoveCancelLimit = (cancelLimit: CancelLimit) => {
    hotelElement.removeCancelLimit(cancelLimit);
  };

  return (
    <div className="virtual-counte-price-change-form__input-wrapper" style={{ alignItems: 'flex-start' }}>
      <label className="virtual-counte-price-change-form__label">キャンセルポリシー</label>
      <div>
        {hotelElement.cancelLimits.map((cancelLimit, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
              className={classNameForModifiedField('cancelLimits', index, 'limitDatetime')}
            >
              <input
                type="number"
                min={0}
                value={cancelLimit.daysBefore(checkinDate)}
                onChange={e => {
                  cancelLimit.setDaysBefore(parseInt(e.target.value, 10), checkinDate);
                }}
                style={{ margin: 0, width: '50px' }}
              />
              <span>日前</span>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              className={classNameForModifiedField('cancelLimits', index, 'cancelFeePercent')}
            >
              <input
                type="number"
                min={0}
                max={100}
                value={cancelLimit.cancelFeePercent}
                onChange={e => {
                  cancelLimit.setCancelFeePercent(parseInt(e.target.value, 10));
                }}
                style={{ margin: 0, width: '60px' }}
              />
              <span>%</span>
            </div>
            <Button
              onClick={() => {
                handleRemoveCancelLimit(cancelLimit);
              }}
              style={{ borderRadius: '10px', height: '20px', minWidth: '20px', padding: 0 }}
            >
              ×
            </Button>
          </div>
        ))}
        <Button
          size="small"
          style={{ marginLeft: 0 }}
          onClick={() => {
            const newLimit = new CancelLimit({
              cancelFeePercent: 0,
              limitDatetime: checkinDate.startOf('days').toISOString()
            });
            hotelElement.addCancelLimit(newLimit);
          }}
        >
          追加
        </Button>
      </div>
    </div>
  );
};

export default CancelLimitForm;
