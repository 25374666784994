/**
 * 金額関係のUtilです
 */

import { zenToHan } from '../string';

/**
 * 受け取った数字をカンマ区切りにします(例: 1000 → 1,000)
 */
export const digits = (num: number | string | null | undefined) => {
  if (num == null || (typeof num === 'number' && isNaN(num))) {
    return null;
  }
  return Number(num).toLocaleString(undefined, { maximumFractionDigits: 5 });
};

/**
 * 受け取った数字をカンマ区切りにして「円」をつけます(例: 1000 → 1,000円)
 */
export const formatPrice = (int: number | null) => {
  if (int == null || isNaN(int)) {
    return undefined;
  }

  return `${digits(int)}円`;
};

/**
 * 受け取った数字からカンマを除去します(例: 1,000 → 1000)
 */
export const formatPriceText = (string: string | null) => {
  if (string == null) {
    return undefined;
  }

  return string.split(',').join('').trim();
};

/**
 * 受け取った数字をカンマ区切りにして¥をつけます(例: 1000 → ¥1,000)
 */
export const formatPriceWithMark = (int: number | null) => {
  if (int == null || isNaN(int)) {
    return undefined;
  }

  return `¥${digits(int)}`;
};

/**
 * 全角を半角に直したり、数字以外の文字列を除去します
 */
export const sanitizePrice = (int: string | number) => {
  if (int === '') {
    return null;
  }

  return Number(zenToHan(int.toString()).replace(/[^-?\d]/g, ''));
};
