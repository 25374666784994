import { Fetcher, objectToFormData } from '@this/src/util';
import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import ExpensesMain from '@this/components/expenses/main/main';
import { BaseButton } from '@this/components/expenses/approvals/reject';
import { Select, SelectWrapper } from '@this/components/expenses/report_items/search_form';

const ZenginData: React.FC = () => {
  const [code, setCode] = useState('deposit');
  const [month, setMonth] = useState('1');
  const [day, setDay] = useState('1');
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [downloaded, setDownloaded] = useState(false);

  const reportIds: string[] = utils.getParam('report_ids');

  const submit = async () => {
    const params = { code, month, day, report_ids: reportIds };

    setSubmitting(true);
    setDownloaded(false);
    setErrors([]);
    try {
      await Fetcher.post('/biztra/approvals/zengin_data.json', objectToFormData(params));
      setSubmitting(false);
      location.href = `/biztra/approvals/zengin_data.txt?${utils.toParams(params)}`;
      setDownloaded(true);
    } catch (e) {
      setSubmitting(false);
      if (e.response.status === 400) {
        setErrors(e.response.data.errors);
      } else {
        setErrors(['通信エラーが発生しました。時間をおいて再度お試しください。']);
      }
    }
  };

  return (
    <ExpensesMain title="振込用データダウンロード" backLinkPath="/biztra/approvals">
      <List>
        <Title>申請書数</Title>
        <Content>{reportIds.length}</Content>
        <Title>種別コード</Title>
        <SelectContent>
          <SelectWrapper>
            <Select value={code} onChange={e => setCode(e.target.value)}>
              <option value="deposit">総合</option>
              <option value="allowance">給与</option>
              <option value="bonus">賞与</option>
            </Select>
          </SelectWrapper>
        </SelectContent>
        <Title>振込指定日</Title>
        <Content>
          <DateWrapper>
            <DateInput type="text" value={month} onChange={e => setMonth(e.target.value)} />
            <DateLabel>月</DateLabel>
            <DateInput type="text" value={day} onChange={e => setDay(e.target.value)} />
            <DateLabel>日</DateLabel>
          </DateWrapper>
        </Content>
      </List>
      <SubmitArea>
        <BaseButton
          onClick={() => {
            submit();
          }}
          disabled={submitting}
        >
          ダウンロード
        </BaseButton>
      </SubmitArea>
      <Messages>
        {errors.map((error, i) => (
          <p key={i} className="error">
            {error}
          </p>
        ))}
        {downloaded && <p className="success">テキストファイルをダウンロードしました</p>}
      </Messages>
    </ExpensesMain>
  );
};

const blackTxtColor = '#323232';

const List = styled.dl`
  margin: unset;
`;

const Title = styled.dt`
  color: ${blackTxtColor};
  margin-bottom: 5px;
`;

const Content = styled.dd``;

const SelectContent = styled.dd`
  width: 100px;
`;

const DateWrapper = styled.div`
  display: flex;
  width: 150px;
`;

const DateInput = styled.input`
  margin-bottom: 0 !important;
  flex-grow: 1;
  padding: 10px !important;
  background: #faf9f8 !important;
  border-color: #f1ede5 !important;
`;

const DateLabel = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 5px;
  margin-right: 5px;
`;

const Messages = styled.div`
  margin-top: 20px;
`;

const SubmitArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
`;

export default ZenginData;
