import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

import moment from 'moment';
import User from '@this/domain/user/user';
import type UserJson from '@this/domain/user/user_json';
import ErrorBoundary from '@this/shared/error_boundary/error_boundary';
import { ConfirmModalContainer } from '@this/shared/ui/feedbacks/modal/confirm_modal';
import { AITThemeProvider } from '@this/shared/ui/theme';
import AITravel from './services/ai_travel/ai_travel';
import Tabikobo from './services/tabikobo/tabikobo';
import { createTheme } from './constants/themes';

// serviceId
const TABIKOBO = 2;

interface Props {
  serviceId: number;
  userJson?: UserJson;
  env?: string;
  i18n?: number;
}

// AIカレンダーは月曜始まり
const isAICalendar = location.href.indexOf('aicalendar') > -1;
moment.locale('ja', {
  week: {
    dow: isAICalendar ? 1 : 0,
    doy: isAICalendar ? 1 : 0
  },
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
  months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
});

const Root: React.FC<Props> = ({ serviceId, userJson, env, i18n }: Props) => {
  let user: User | undefined;
  if (userJson) {
    user = new User(userJson);
  }

  if (i18n && (env === 'staging' || env === 'production')) {
    const s = document.createElement('script');
    s.src = 'https://j.wovn.io/1';
    s.setAttribute('data-wovnio', 'key=BPxw2o');
    s.async = true;
    document.body.appendChild(s);
  }
  const theme = createTheme(serviceId);

  return (
    <AITThemeProvider theme={theme}>
      <HelmetProvider>
        <ErrorBoundary>
          <ConfirmModalContainer>
            {(() => {
              switch (serviceId) {
                case TABIKOBO:
                  return <Tabikobo serviceId={serviceId} user={user} env={env} />;
                default:
                  return <AITravel serviceId={serviceId} user={user} env={env} />;
              }
            })()}
          </ConfirmModalContainer>
        </ErrorBoundary>
      </HelmetProvider>
    </AITThemeProvider>
  );
};

export default Root;
