import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import { styled } from '@this/constants/themes';
import type { KnowledgeResponseArgs } from '@this/src/domain/knowledge';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import { GtmPageview } from '@this/src/util/gtm';
import { defaultPageTitleText } from '@this/src/util/utils';
import Knowledge, { convertKnowledgeResponseToArgs } from '@this/src/domain/knowledge';
import { Fetcher } from '@this/src/util';
import { Text } from '@this/shared/ui/data_displays/typography';
import { ContentWrapper, Title, AiTravelServiceId } from '../knowledge';

type Props = RouteComponentProps<{ id?: string }>;
interface KnowledgeResponse {
  knowledge: KnowledgeResponseArgs;
}

const KnowledgeDetail: React.FC<Props> = (props: Props) => {
  const id = props.match.params.id;
  const [knowledge, setKnowledge] = useState<Knowledge | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    Fetcher.get<KnowledgeResponse>(`/knowledges/${id}.json`)
      .then(res => {
        setKnowledge(new Knowledge(convertKnowledgeResponseToArgs(res.knowledge)));
        setError(null);
      })
      .catch(e => {
        setError(
          e.response.status === 404
            ? 'お探しの記事はありません'
            : '通信エラーが発生しました。時間をおいて再度お試しください。'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (knowledge) {
      document.title = knowledge.title;
      GtmPageview(window, knowledge.title);
    }
    return () => {
      document.title = defaultPageTitleText(AiTravelServiceId);
    };
  }, [knowledge]);

  return (
    <ContentWrapper>
      {loading ? (
        <Loading />
      ) : error ? (
        <Text color="danger" level="body">
          {error}
        </Text>
      ) : (
        knowledge && (
          <>
            <Title>{knowledge.title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: knowledge.answer }} />
          </>
        )
      )}
    </ContentWrapper>
  );
};

const Description = styled.div`
  color: #3a3a3a;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  word-wrap: break-word;
`;

export default KnowledgeDetail;
