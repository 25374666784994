import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import type { KnowledgeCategoryResponseArgs } from '@this/src/domain/knowledge_category';
import KnowledgeCategory, { convertKnowledgeCategoryResponseToArgs } from '@this/src/domain/knowledge_category';
import type { RouteComponentProps } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { ChevronIcon } from '@this/components/reserve_trip/dashboard/help_area_for_ait/faq_item_for_ait.template';
import { Button } from '@this/shared/ui/inputs/button';
import { GtmPageview } from '@this/src/util/gtm';
import { defaultPageTitleText } from '@this/src/util/utils';
import { Loading } from '../../shared/ui/feedbacks/loading';
import { ContentWrapper, Title, CategoryTitle, AiTravelServiceId } from '../knowledge';
import { Link } from '../../shared/ui/navigations/link';

type Props = RouteComponentProps<{ id?: string }>;

interface Response {
  knowledge_category: KnowledgeCategoryResponseArgs;
}

const KnowledgeCategoryDetail = observer((props: Props) => {
  const id = props.match.params.id;
  const hash = props.location.hash;
  const [isLoading, setIsLoading] = useState(true);
  const [knowledgeCategory, setKnowledgeCategory] = useState<KnowledgeCategory | undefined>(undefined);
  const [pageTitle, setPageTitle] = useState(knowledgeCategory ? knowledgeCategory.sfName : '　');

  const fetchKnowledgeCategories = useCallback(() => {
    setIsLoading(true);
    Fetcher.get<Response>(`/knowledge_categories/${id}.json`)
      .then(result => {
        setKnowledgeCategory(
          new KnowledgeCategory(convertKnowledgeCategoryResponseToArgs(result.knowledge_category))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleButtonClick = (subCatId: string) => {
    // ボタンがクリックされたときにセクションまでスクロール
    scrollToSection(subCatId);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchKnowledgeCategories();
    };

    fetchData();
  }, [fetchKnowledgeCategories]);

  // ページロード時にハッシュが存在する場合、セクションまでスクロール
  useEffect(() => {
    if (!isLoading && hash) {
      const sectionId = hash.substring(1);
      scrollToSection(sectionId);
    }
  }, [isLoading, hash]);

  useEffect(() => {
    setPageTitle(knowledgeCategory ? knowledgeCategory.sfName : '　');
  }, [knowledgeCategory]);

  useEffect(() => {
    document.title = pageTitle;
    GtmPageview(window, pageTitle);
    return () => {
      document.title = defaultPageTitleText(AiTravelServiceId);
    };
  }, [pageTitle]);

  return (
    <ContentWrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Title>よくあるご質問</Title>
          <CategoryTitle>{pageTitle}</CategoryTitle>

          <div
            style={{
              borderTop: '1px #ECECEC solid',
              padding: '24px 0',
              gap: '8px',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            {knowledgeCategory &&
              knowledgeCategory.children.map(subCat =>
                subCat.knowledges && subCat.knowledges.length > 0 ? (
                  <Button
                    key={subCat.id}
                    onClick={() => handleButtonClick(String(subCat.id))}
                    color="sub"
                    size="medium"
                  >
                    {subCat.sfName}
                  </Button>
                ) : null
              )}
          </div>
          {knowledgeCategory &&
            knowledgeCategory.children.map(subCat =>
              subCat.knowledges && subCat.knowledges.length > 0 ? (
                <section key={subCat.id} id={subCat.id.toString()}>
                  <h3 style={{ marginBottom: `24px` }}>{subCat.sfName}</h3>
                  <ul
                    style={{
                      display: 'flex',
                      gap: '12px',
                      flexDirection: 'column'
                    }}
                  >
                    {subCat.knowledges?.map(knowledge => (
                      <Box key={knowledge.salesforceId}>
                        <Link
                          href={`/knowledges/${knowledge.salesforceId}`}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start'
                          }}
                        >
                          <ChevronIcon />
                          {knowledge.title}
                        </Link>
                      </Box>
                    ))}
                  </ul>
                </section>
              ) : null
            )}
        </>
      )}
    </ContentWrapper>
  );
});

export default KnowledgeCategoryDetail;
