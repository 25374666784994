import { observable } from 'mobx';
import moment from 'moment';
import type { Moment } from 'moment';

export type CancelLimitArgs = {
  id?: number;
  cancelFeePercent: number;
  limitDatetime: string;
};

export default class CancelLimit {
  readonly id?: number;

  @observable
  cancelFeePercent: number;

  @observable
  limitDatetime: Moment;

  constructor(args: CancelLimitArgs) {
    this.id = args.id;
    this.cancelFeePercent = args.cancelFeePercent;
    this.limitDatetime = moment(args.limitDatetime);
  }

  setCancelFeePercent = (value: number) => {
    this.cancelFeePercent = value;
    app.render();
  };

  setDaysBefore = (value: number, checkinDate: Moment) => {
    if (value < 0) return;
    const date = checkinDate.clone();
    this.limitDatetime = date.subtract(value, 'days');
    app.render();
  };

  daysBefore = (checkinDate: Moment) => {
    return checkinDate.clone().startOf('day').diff(this.limitDatetime.clone().startOf('day'), 'days');
  };

  updateParams = () => {
    return {
      limit_datetime: this.limitDatetime.format('YYYY-MM-DD HH:mm:SS ZZ'),
      cancel_fee_percent: this.cancelFeePercent
    };
  };

  validationErrors = (): string[] => {
    const errors: string[] = [];
    if (this.cancelFeePercent < 0 || this.cancelFeePercent > 100) {
      errors.push('キャンセルポリシーのキャンセル料率は0〜100の間で指定してください。');
    }
    return errors;
  };
}
