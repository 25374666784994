import { Fetcher } from '@this/src/util';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { reportError } from '@this/lib/bugsnag';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

import User from '../../../domain/user/user';
import type UserJson from '../../../domain/user/user_json';

interface Props {
  user: User | null | undefined;
}

interface State {
  loading: boolean;
  currentUser: User | null | undefined;
  redirect: boolean;
}

interface CheckTokenResponse {
  user: UserJson;
}

class Confirmation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      currentUser: undefined,
      redirect: false
    };
  }

  componentDidMount() {
    this.checkToken();
  }

  async checkToken() {
    const token = utils.getParam('confirmation_token');
    this.setState({ loading: true });
    try {
      const response = await Fetcher.get<CheckTokenResponse>('/biztra/users/confirmation.json', {
        confirmation_token: token
      });
      location.href = 'https://biztra.cloud/thanks.html';
      const user = new User(response.user);
      this.setState({ loading: false, currentUser: user });
      mixpanel.track('[Biztra] sign up confirmed');
    } catch (e) {
      this.setState({ loading: false, currentUser: null });
      mixpanel.track('[Biztra] sign up confirmation failed');
    }
  }

  render() {
    try {
      const { user } = this.props;
      const { loading, currentUser, redirect } = this.state;
      const classBase = 'expenses-users-signup';
      return (
        <div className={classBase}>
          {user || redirect ? (
            <Redirect to="/biztra/report_items" />
          ) : loading ? (
            <SimpleLoading />
          ) : currentUser ? (
            <div className={`${classBase}__confirmed`}>
              <div className={`${classBase}__success`}>本登録が完了しました！</div>
              <button
                type="button"
                onClick={() => {
                  location.pathname = '/biztra/';
                }}
              >
                ログインして始める
              </button>
            </div>
          ) : (
            <div>
              このリンクは有効期限が切れています。
              <br />
              下記のリンクから再度仮登録を行ってください。
              <br />
              <a href="/biztra/users/sign_up">仮登録ページ</a>
            </div>
          )}
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default Confirmation;
