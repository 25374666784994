import moment from 'moment-timezone';

// TODO: transport_element.coffeeのts化に伴い、必要であればスネークケースに修正
export type TransportElementTerminalJson = {
  id?: number;
  name?: string;
  time?: string;
  timeZone?: string;
  timeInTimeZoneStr?: string;
  code?: string;
};

class TransportElementTerminal {
  id: number | undefined;

  name: string;

  time: moment.Moment;

  timeZone: string;

  timeInTimeZoneStr: string | undefined;

  code: string | undefined;

  constructor(args: TransportElementTerminalJson = {}) {
    this.id = args.id;
    this.name = args.name || '';
    this.timeZone = args.timeZone || (args.timeZone = 'Asia/Tokyo');
    this.time =
      typeof moment(args.time).tz(this.timeZone) !== 'undefined'
        ? moment(args.time).tz(this.timeZone)
        : moment().tz(this.timeZone);
    this.timeInTimeZoneStr = args.timeInTimeZoneStr;
    this.code = args.code;
  }

  handleChangeName(value: string) {
    this.name = value;
    return app.render();
  }

  handleTimeChange(time: moment.Moment) {
    this.time = time;
    return app.render();
  }

  dateStr() {
    return `${this.time.tz(this.timeZone).format('YYYY-MM-DD')}`;
  }

  dateTimeStr() {
    return `${this.time.tz(this.timeZone).format('MM/DD HH:mm')}`;
  }

  timeStr() {
    return `${this.time.tz(this.timeZone).format('HH:mm')}`;
  }

  description() {
    const time = this.time.clone();
    return `${this.name || ''} ${time.tz(this.timeZone).format('YYYY/MM/DD HH:mm')}`;
  }

  foreignAirDescription() {
    const time = this.time.clone();
    return `${time.tz(this.timeZone).format('YYYY/MM/DD HH:mm')} ${this.name}`;
  }

  changeTimeZone(timezone: string) {
    this.timeZone = timezone;
    // 時間を変えずタイムゾーンだけを変える
    this.time.tz(timezone, true);
    return app.render();
  }

  updateParams() {
    const time = this.time.clone();
    return {
      name: this.name,
      time: time.tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm'),
      time_zone: this.timeZone,
      code: this.code
    };
  }
}

export default TransportElementTerminal;
