OrderItemPrice = require('./order_item_price').default
OrderItemPayment = require('./order_item_payment')
OrderItemMappingList = require('./order_item_mapping_list').default
TransportElement = require('./transport_element')
HotelElement = require('./hotel_element')
CancelChargeElement = require('./cancel_charge_element')
SupportFeeElement = require('./support_fee_element')
ShippingElement = require('./shipping_element')
WifiElement = require('./wifi_element')
KitElement = require('./kit_element').default
RoomElement = require('./room_element').default
Traveler = require('./traveler/traveler')
Fetcher = require("../util").Fetcher
TravelAuthorizationElement = require('./travel_authorization_element').default
InsuranceElement = require('./insurance_element').default
ChangeFeeElement = require('./change_fee_element').default
Journal = require('./journal').default
MarginType = require('./organization/margin_type2').default
TripStatus = require('./trip/trip_status').default
OrderItemStatus = require('./order_item/order_item_status').OrderItemStatus
OrderItemSnoozedTodo = require('./order_item_snoozed_todo').default
ETicket = require('./e_ticket/e_ticket').default
OrderItemTodoMessageLog = require('./order_item_todo_message_log').default
diff = require('deep-object-diff').diff
OrderItemStatusLog = require('./arrangement/order_item_status_log').default

class OrderItem
  constructor: (args = {}) ->
    if args.marginType
      @marginType = new MarginType(args.margin_type)

    # Serializer の値をそのまま使った方がコードがシンプルになるのでここで実行する
    orderItemSnoozedTodos =
      if args.order_item_snoozed_todos
        _.map(args.order_item_snoozed_todos, (raw) -> new OrderItemSnoozedTodo({
          id: raw.id,
          orderItemId: raw.order_item_id,
          arrangerId: raw.arranger_id,
          snoozeTo: raw.snooze_to,
          memo: raw.memo,
          canceled: raw.canceled
        }))
      else
        []

    orderItemTodoMessageLog =
      if args.message_log
        new OrderItemTodoMessageLog({
          id: args.message_log.id,
          traceId: args.message_log.trace_id,
          arrangerId: args.message_log.arranger_id,
          arrangerName: args.message_log.arranger_name,
          createdAt: args.message_log.created_at,
          body: args.message_log.body
        })
      else
        null

    orderItemMappings =
      if args.order_item_mappings
        new OrderItemMappingList(args.order_item_mappings)
      else
        new OrderItemMappingList([])

    args = snakeToCamelObject(args)
    _.assign(@, args)
    @peopleNum = args.peopleNum || 1
    @payment = new OrderItemPayment(args.payment)
    @transports = _.map args.transports, (raw) =>
      peopleNum = raw.peopleNum || @peopleNum
      new TransportElement(_.merge(raw, {
        peopleNum: peopleNum,
        carTypeOptions: @carTypeOptions,
        carProviderOptions: @carProviderOptions,
        serviceId: @serviceId
      }))
    @hotels = _.map args.hotels, (raw) =>
      peopleNum = raw.peopleNum || @peopleNum
      new HotelElement(_.merge(raw, { peopleNum: peopleNum }))
    @cancelCharges = _.map (args.cancelCharges || args.cancel_charges), (raw) -> new CancelChargeElement(raw)
    @changeFees = _.map args.changeFees, (raw) -> new ChangeFeeElement(raw)
    @supportFees = _.map args.supportFees, (raw) -> new SupportFeeElement(raw)
    @shippings = _.map args.shippings, (raw) =>
      new ShippingElement(_.merge(raw, { shippingCategoryOptions: @shippingCategoryOptions }))
    @wifis = _.map args.wifis, (raw) =>
      new WifiElement(_.merge(raw, { wifiProviderOptions: @wifiProviderOptions }))
    @rooms = _.map args.rooms, (raw) -> new RoomElement(raw)
    @kits = _.map args.kits, (raw) -> new KitElement(raw)
    @travelAuthorizations = _.map args.travelAuthorizations, (raw) -> new TravelAuthorizationElement(raw)
    @insurances = _.map args.insurances, (raw) -> new InsuranceElement(raw)
    @elements =
      @transports
        .concat(@hotels)
        .concat(@cancelCharges)
        .concat(@changeFees)
        .concat(@supportFees)
        .concat(@shippings)
        .concat(@wifis)
        .concat(@rooms)
        .concat(@kits)
        .concat(@travelAuthorizations)
        .concat(@insurances)
    marginType = @currentMarginType()
    @price =
      new OrderItemPrice(
        _.merge(args.price, { showFee: @showFee, marginType: marginType, peopleNum: @peopleNum })
      )
    @firstPrice =
      new OrderItemPrice(
        _.merge(args.firstPrice, { showFee: @showFee, marginType: marginType, peopleNum: @peopleNum })
      )
    @type = args.orderItemType
    @additionalType = 'hotel'
    @travelerInformations = if args.travelerInformations then Object.values(args.travelerInformations) else []
    @travelers = _.map @travelerInformations, (raw) -> new Traveler(raw)
    @hotelPriceLimit = args.hotelPriceLimit
    @billedAt = moment(args.billedAt) if args.billedAt
    @status = args.status
    @jobTypeValue = args.jobType

    @initialData = _.cloneDeep(@)
    @isEditing = args.isEditing || false

    @orderItemMappings = orderItemMappings

    @eTickets = _.map args.eTickets, (raw) -> new ETicket(raw)
    @prevItem =
      if args.prevItem
        new OrderItem(args.prevItem)
      else
        null
    @firstItem =
      if args.firstItem
        new OrderItem(args.firstItem)
      else
        null

    # 旅程履歴用
    # j.tagsが連想配列になってるので通常の配列に変換
    @journals =
      if args.journals
        _.map(args.journals, (j) -> j.tags = _.values(j.tags); new Journal(j))
      else
        []
    @tmpGmoSalesJournals =
      if args.tmpGmoSalesJournals
        _.map(args.tmpGmoSalesJournals, (j) -> j.tags = _.values(j.tags); new Journal(j))
      else
        []
    @tmpGmoCancelJournals =
      if args.tmpGmoCancelJournals
        _.map(args.tmpGmoCancelJournals, (j) -> j.tags = _.values(j.tags); new Journal(j))
      else
        []

    # for /arrangement/todo_list
    @status = args.status
    @statusStr = if @status then OrderItemStatus[@status] else ''
    @arrangerName = if args.arranger && args.arranger.name then args.arranger.name else ''
    @orderItemSnoozedTodos = orderItemSnoozedTodos
    @orderItemTodoMessageLog = orderItemTodoMessageLog
    @supplierSuppliedItemNames = []
    for originalPrice in @price.originalPrices
      if originalPrice.suppliedItem && originalPrice.suppliedItem.supplier
        @supplierSuppliedItemNames.push(originalPrice.suppliedItem.supplier.name + '：' + originalPrice.suppliedItem.name)
    @deliveryAndBookingConfirmation =
      if @orderItemCategory in ['hotel', 'domestic_hotel', 'foreign_hotel']
        'ホテル（予約番号）：' + [hotel.hotelReservationNumber for hotel in @hotels].join(', ')
      else if @orderItemCategory == 'domestic_air'
        '国内航空券（確認番号）：' + [transport.airConfirmationNumber for transport in @transports].join(', ')
      else if @orderItemCategory == 'foreign_air'
        '海外航空券（PNR）：' + @pnr_id
      else if @orderItemCategory == 'shinkansen'
        '新幹線（配達記録番号）：' + [transport.postingNumber for transport in @transports].join(', ')
      else if @orderItemCategory == 'express'
        '特急（配達記録番号）：' + [transport.postingNumber for transport in @transports].join(', ')
      else
        ''
    @deliveryCheckedByName = if args.deliveryCheckedBy && args.deliveryCheckedBy.name then args.deliveryCheckedBy.name else ''
    @deliveryCheckedAt = moment(args.deliveryCheckedAt) if args.deliveryCheckedAt
    @orderItemStatusLogs = _.map(args.orderItemStatusLogs, (log) -> new OrderItemStatusLog(log))
    @foreignFlightTicketIssuer = args.foreignFlightTicketIssuer
    @tsdInvoiceNumber = args.tsdInvoiceNumber

  startDate: ->
    dates = _.compact(_.map(@elements, (element) -> element.startDate()))
    _.first(_.sortBy(dates, (d) -> d.toDate()))

  startDateStr: ->
    d = @startDate()
    if d
      d.format('YYYY-MM-DD')
    else
      ''

  # /trips/:id/edit での日時順並び替えのため
  startDateTime: ->
    date = _.first(_.sortBy(_.compact(_.map(@elements, (element) ->
      if element instanceof HotelElement
        moment(element.startDate().format('YYYY-MM-DD') + ' 23:59:59')
      else
        element.startDate()
    ))))
    date

  endDate: ->
    dates = _.compact(_.map(@elements, (element) -> element.endDate()))
    _.last(_.sortBy(dates, (d) -> d.toDate()))

  endDateStr: ->
    d = @endDate()
    if d
      d.format('YYYY-MM-DD')
    else
      ''

  typeStr: ->
    switch @type
      when 0 then '往路'
      when 1 then '復路'
      when 2 then 'ホテル'
      when 3 then '航空券'
      when 4, 9, 10, 11, 12 then 'パッケージ'
      when 5 then '経路'
      when 20 then '出張サポート費'
      when 6 then '配送料'
      when 7 then 'WiFi'
      when 8 then '会議室'
      when 21 then '検査キット'
      when 22 then '変更手数料'
      when 23 then '査証・渡航認証関連'
      when 24 then '保険'

  categoryStr: ->
    switch @orderItemCategory
      when 'hotel' then 'ホテル'
      when 'domestic_air' then '航空券'
      when 'foreign_air' then '航空券'
      when 'shinkansen' then '新幹線'
      when 'express' then '特急'
      when 'railway_ticket' then '在来線'
      when 'rental_car' then 'レンタカー'
      when 'wbf_package' then 'パッケージ'
      when 'cancel' then 'キャンセル料'
      when 'other' then 'その他'
      when 'support_fee' then '出張サポート費'
      when 'change_fee' then '変更手数料'
      when 'shipping' then '配送料'
      when 'wifi' then 'WiFi'
      when 'room' then '会議室'
      when 'kit' then '検査キット'
      when 'bus' then 'バス'
      when 'ana_tabisaku' then 'ANA旅作'
      when 'arrangement_request' then 'リクエストフォーム利用料'
      when 'travel_authorization' then '査証・渡航認証関連'
      when 'insurance' then '保険'

  elementType: ->
    switch @typeStr()
      when 'ホテル' then 'hotel'
      when '往路', '復路', '経路', '航空券' then 'transport'
      else
        undefined

  isPackage: ->
    switch @type
      when 4, 9, 10, 11, 12 then true
      else
        false

  mappingDescription: ->
    desc = _.first(@elements)?.mappingDescription()
    if !!desc then desc else @categoryStr()

  totalPriceWithAll: ->
    @price.totalPriceWithAll()

  isHotelOnly: ->
    for element in @elements
      return false unless element.type == 'hotel'
    true

  isDomesticAirOnly: ->
    for element in @elements
      return false unless element.type == 'transport' && element.isDomesticAir()
    true

  isForeignAirOnly: ->
    for element in @elements
      return false unless element.type == 'transport' && element.isForeignAir()
    true

  isShinkansenOnly: ->
    for element in @elements
      return false unless element.type == 'transport' && (element.isShinkansen() || element.isExpress())
    true

  isOtherOnly: ->
    @typeStr() == 'パッケージ'

  isChangeableExist: ->
    for element in @elements
      return true if element.type == 'transport' && element.isDomesticAir() && element.isChangeable()
    false

  isSnoozing: ->
    snoozing = false
    if @orderItemSnoozedTodos
      _.each @orderItemSnoozedTodos, (todo) ->
        if todo.isSnoozing()
          snoozing = true
          return
    snoozing

  currentSnooze: ->
    snooze = null
    if @orderItemSnoozedTodos
      _.each @orderItemSnoozedTodos, (todo) ->
        if todo.isSnoozing()
          snooze = todo
          return
    snooze

  isAvailableBulkTicket: ->
    @isShinkansenOnly() && @payment.isBulkTicket()

  isExist: ->
    !_.isEmpty(@elements)

  isPriceChanged: ->
    @firstItem && @firstItem.price.totalPrice() != @price.totalPrice()

  isTicketingExpired: ->
    expired_flights = _.filter @elements, (e) ->
      e.type == 'transport' && e.isForeignAir() && e.isTicketingExpired()
    expired_flights.length > 0

  isFollowingElement: (element) ->
    return false unless element.type == 'transport' && element.isForeignAir()

    firstForeignAir =_.filter(@elements, (e) -> e.type == 'transport' && e.isForeignAir())[0]
    firstForeignAir && firstForeignAir.id != element.id

  isPrecedingElement: (element) ->
    return false unless element.type == 'transport' && element.isForeignAir()

    lastForeignAir =_.filter(@elements, (e) -> e.type == 'transport' && e.isForeignAir()).pop()
    lastForeignAir && lastForeignAir.id != element.id

  showTicketingDeadline: (element) ->
    return false unless element.ticketingExpiredAt
    !@isPrecedingElement(element)

  travelerShinkansenTypes: ->
    return [] if @orderItemCategory != 'shinkansen' && @orderItemCategory != 'express'
    return @_travelerShinkansenTypes if @_travelerShinkansenTypes

    @_travelerShinkansenTypes = @travelers.map (travelerInformation) ->
      travelerInformation.getTravelerShinkansenType()
    @_travelerShinkansenTypes

  travelerShinkansenSeatTypes: ->
    return [] if @orderItemCategory != 'shinkansen' && @orderItemCategory != 'express'
    return @_travelerShinkansenSeatTypes if @_travelerShinkansenSeatTypes

    @_travelerShinkansenSeatTypes = @travelers.map (travelerInformation) ->
      travelerInformation.getShinkansenSeatTypeText()
    @_travelerShinkansenSeatTypes

  travelerShinkansenTicketTypes: ->
    return [] if @orderItemCategory != 'shinkansen' && @orderItemCategory != 'express'
    return @_travelerShinkansenTicketTypes if @_travelerShinkansenTicketTypes

    @_travelerShinkansenTicketTypes = @travelers.map (travelerInformation) ->
      travelerInformation.getShinkansenTicketTypeText()
    @_travelerShinkansenTicketTypes

  travelerAirlineTypes: ->
    return [] if @orderItemCategory != 'domestic_air' && @orderItemCategory != 'foreign_air'
    return @_travelerAirlineTypes if @_travelerAirlineTypes

    @_travelerAirlineTypes = @travelers.map (travelerInformation) ->
      travelerInformation.getTravelerAirlineType()
    @_travelerAirlineTypes

  handleToggleEditing: ->
    @_restore() if @isEditing == true
    @isEditing = !@isEditing
    app.render()

  handleChangeType: (index, value) ->
    @elements[index] =
      switch value
        when 'transport' then new TransportElement
        when 'hotel' then new HotelElement
        when 'cancel' then new CancelChargeElement({
          startAt: @elements[index].startDate()
          endAt: @elements[index].endDate()
        })
        when 'change_fee' then new ChangeFeeElement({
          startAt: @elements[index].startDate()
          endAt: @elements[index].endDate()
        })
        when 'support_fee' then new SupportFeeElement
        when 'shipping'
          new ShippingElement({ shippingCategoryOptions: @shippingCategoryOptions })
        when 'wifi' then new WifiElement({ wifiProviderOptions: @wifiProviderOptions })
        when 'room' then new RoomElement
        when 'kit' then new KitElement
        when 'travel_authorization' then new TravelAuthorizationElement({})
        when 'insurance' then new InsuranceElement({})
    @updatePriceShowFee()
    @fetchCategory()
    app.render()

  handleSelectAdditionalType: (type) ->
    @additionalType = type
    app.render()

  handleAddElementByType: (e) ->
    e.preventDefault()
    args = peopleNum: @peopleNum
    element =
      switch @additionalType
        when 'hotel' then new HotelElement(args)
        when 'transport' then new TransportElement(args)
        when 'cancel' then new CancelChargeElement(args)
        when 'change_fee' then new ChangeFeeElement(args)
        when 'support_fee' then new SupportFeeElement(args)
        when 'shipping'
          new ShippingElement(_.merge(args, { shippingCategoryOptions: @shippingCategoryOptions }))
        when 'wifi' then new WifiElement(_.merge(args, { wifiProviderOptions: @wifiProviderOptions }))
        when 'room' then new RoomElement(args)
        when 'kit' then new KitElement(args)
        when 'travel_authorization' then new TravelAuthorizationElement(args)
        when 'insurance' then new InsuranceElement(args)
    @elements.push(element)
    @updatePriceShowFee()
    @updatePriceMarginType()
    @fetchCategory()
    app.render()

  fetchCategory: () ->
    Fetcher.get(
      '/arrangement/order_item_categories',
      @updateParams(),
      { parser: 'jquery' }
    ).then (result) =>
      @orderItemCategory = result.order_item_category
      app.render()

  updatePriceShowFee: ->
    return if @showFee
    @price.showFee = false
    app.render()

  updatePriceMarginType: ->
    @price.marginType = @currentMarginType()
    app.render()

  handleTransportTypeChange: (transportElement, value) ->
    transportElement.handleTransportTypeChange(value)
    @updatePriceMarginType()
    @fetchCategory()
    app.render()

  handleRemoveElement: (index) ->
    @elements.splice(index, 1)
    @updatePriceMarginType()
    @fetchCategory()
    app.render()

  description: ->
    """
    #{
      if @orderItemCategory == 'arrangement_request'
        'リクエストフォーム利用料'
      else
        _.map(@elements, (el) -> el.description()).join('\n\n')
    }

    #{@price.description()}
    """

  validationErrors: ->
    status = this.status
    errors = _.map(@elements, (element) -> element.validationErrors(status))
    errors.push @price.validationErrors()
    if @travelerInformations.length == 0
      errors.push ['出張者が誰もいません']
    if !@isPaymentPossible()
      errors.push ['海外航空券はカード払いでは購入できません']
    if @foreignFlightTicketIssuer == 'tsd' && (@tsdInvoiceNumber == '' || @tsdInvoiceNumber == null)
      errors.push ['発券先がティ・エス・ディの場合は、ティ・エス・ディ請求書番号を入力してください']
    return errors

  currentMarginType: ->
    if @marginType
      @marginType
    else if @marginTypes
      # 旧ソース(@marginTypeがセットされない場合用)
      if @isForeignAirOnly()
        @marginTypes.foreignMarginType()
      else
        # 海外/国内が存在した場合、国内航空券の手数料を利用
        # 通常のユースケースでは発生しない
        @marginTypes.domesticMarginType()

  isPaymentPossible: ->
    # 海外航空券のelements
    foreign_flights = _.filter @elements, (e) ->
      e.type == 'transport' && e.isForeignAir()
    if @payment.paymentType == 0 && foreign_flights.length > 0
      # クレカ払いかつ海外航空券が1件以上 -> 支払い不可
      return false
    else true

  isNeedReCalcMarginAmount: ->
    @price.isNeedReCalcMarginAmount()

  updateParams: ->
    if _.isEmpty(@elements) && @orderItemCategory != 'arrangement_request'
      null
    else
      price: @price.updateParams()
      payment: @payment.updateParams()
      elements: _.map(@elements, (e) -> e.updateParams())
      people_num: @peopleNum
      trace_id: @traceId
      modified: @isEditing
      job_type: @jobType
      status: @status
      billed_at: @billedAt?.format()
      order_item_category: @orderItemCategory
      pnr_id: @pnrId
      foreign_flight_ticket_issuer: @foreignFlightTicketIssuer
      tsd_invoice_number: @tsdInvoiceNumber
      traveler_informations: @travelerInformations.map((t) -> {
        air_seat: t.airSeat
        birthday: t.birthday
        first_name_kana: t.firstNameKana
        first_name_roman: t.firstNameRoman
        flight_birthday: t.flightBirthday
        flight_first_name: t.flightFirstName
        flight_gender: t.flightGender
        flight_last_name: t.flightLastName
        flight_middle_name: t.flightMiddleName
        flight_tel: if t.flightTel then t.flightTel.replace(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '') else ''
        flight_first_name_roman: t.flightFirstNameRoman
        flight_last_name_roman: t.flightLastNameRoman
        flight_middle_name_roman: t.flightMiddleNameRoman
        id: if t.id?.toString().match(/^temp-.*/) then null else t.id # 手配画面の出張者編集で仮IDを付与しているため
        last_name_kana: t.lastNameKana
        last_name_roman: t.lastNameRoman
        middle_name_roman: t.middleNameRoman
        mileage_number: t.mileageNumber
        passport_expire: t.passportExpire,
        passport_issued_country: t.passportIssuedCountry
        passport_number: t.passportNumber
        shinkansen_seat: t.shinkansenSeat
        shinkansen_seat_type: t.shinkansenSeatType
        shinkansen_ticket_type: t.shinkansenTicketType
        exic_number: t.exicNumber
        exic_password: t.exicPassword
        user_id: t.userId
      })

  handlePeopleNumChange: (value) ->
    @peopleNum = value
    for el in @elements
      el.peopleNum = value

    @price.setPeopleNum(@peopleNum)
    app.render()

  handleJobTypeChange: (value) ->
    @jobType = value
    app.render()

  handleStatusChange: (value) ->
    @status = value
    @statusStr = if @status then OrderItemStatus[@status] else ''
    app.render()

  handleWaitingChange: (value) ->
    @waiting = value
    app.render()

  handleBilledAtChange: (value) ->
    @billedAt = moment(value) if value
    app.render()

  handlePnrIdChange: (value) ->
    @pnrId = value
    app.render()

  handleForeignFlightTicketIssuerChange: (value) ->
    @foreignFlightTicketIssuer = value
    app.render()

  handleTsdInvoiceNumber: (value) ->
    @tsdInvoiceNumber = value
    app.render()

  elementChanged: (elementId) ->
    changed = false
    element = @elements.find (e) -> e.id == elementId
    initElement = @initialData.elements.find (e) -> e.id == elementId
    for k, v of element
      if "#{v}" != "#{initElement[k]}"
        changed = true
        break

    return changed

  addTraveler: (traveler) ->
    @travelerInformations.push(traveler)
    @updatePeopleNum()

  updateTraveler: (traveler) ->
    index = @travelerInformations.findIndex((t) -> t.id == traveler.id)
    @travelerInformations.splice(index, 1, traveler)
    @updatePeopleNum()

  removeTraveler: (travelerId) ->
    index = @travelerInformations.findIndex((t) -> t.id == travelerId)
    @travelerInformations.splice(index, 1)
    @updatePeopleNum()

  updatePeopleNum: () ->
    @elements.forEach((e) => e.peopleNum = @travelerInformations.length)
    @price.setPeopleNum(@travelerInformations.length)

  diffWith: (orderItem) ->
    if orderItem
      thisItem = _.cloneDeep(@)
      originalItem = _.cloneDeep(orderItem)
      delete thisItem.initialData
      delete originalItem.initialData
      delete thisItem.order
      delete originalItem.order
      diff(originalItem, thisItem)
    else
      null

  _restore: ->
    _.assign(@, _.cloneDeep(@initialData))

module.exports = OrderItem
