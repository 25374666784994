import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Button } from '@this/shared/ui/inputs/button';
import { Fetcher } from '@this/src/util';

interface SettingTableProps {}

interface Scheduler {
  id?: number;
  from_airport: string;
  to_airport: string;
  source: number;
  date_range: number;
  interval_hour: number;
  expire_hour: number;
  last_queued: string;
}

const SettingTable: React.FC<SettingTableProps> = () => {
  const [csvData, setCsvData] = useState<string>('');
  const [schedulers, setSchedulers] = useState<Scheduler[]>([]);
  const [lodingCsvData, setLodingCsvData] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');

  const handleCsvInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCsvData(event.target.value);
  };

  const parseCsvData = useCallback((): Array<any> => {
    const rows = csvData.trim().split('\n');
    const parsedData = rows.map(row => {
      const columns = row.split(',');
      return {
        from_airport: columns[0],
        to_airport: columns[1],
        source: columns[2],
        date_range: columns[3],
        interval_hour: columns[4],
        expire_hour: columns[5]
      };
    });
    return parsedData;
  }, [csvData]);

  const handleCsvUpload = async () => {
    setLodingCsvData(true);
    try {
      const parsedData = parseCsvData();
      setSchedulers(parsedData);
      await Fetcher.post<any>('/god/domestic_air/update_routes', {
        data: parsedData
      });
      setMessage('CSVデータが正常にアップロードされました。');
      await fetchData();
    } catch (error) {
      setMessage('CSVデータのアップロード中にエラーが発生しました。');
    }
    setLodingCsvData(false);
  };

  const handleRegisterTask = async (schedulerId: number | undefined) => {
    if (!schedulerId) return;

    setLodingCsvData(true);
    try {
      await Fetcher.post<any>('/god/domestic_air/register_task', {
        id: schedulerId
      });
      setMessage('タスクが正常に登録されました。');
      await fetchData();
    } catch (error) {
      setMessage('タスク登録中にエラーが発生しました。');
    }
    setLodingCsvData(false);
  };

  const fetchData = async () => {
    try {
      const response = await Fetcher.get<any>('/god/domestic_air/index_routes');
      const data = response.data.map((record: Scheduler) => ({
        ...record,
        expire_hour: record.expire_hour ?? 0
      }));
      const csv = data
        .map((record: Scheduler) => {
          return `${record.from_airport},${record.to_airport},${record.source},${record.date_range},${record.interval_hour},${record.expire_hour}`;
        })
        .join('\n');
      setCsvData(csv);
      setSchedulers(data);
    } catch (error) {
      setMessage('航路データのタイムテーブルを取得中にエラーが発生しました。');
    }
    setLodingCsvData(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      {lodingCsvData ? (
        <Loading />
      ) : (
        <>
          {message && <Message>{message}</Message>}
          <Title>データ取得タイムテーブル登録</Title>
          <Section>
            <Textarea
              placeholder="CSV形式でデータを入力してください。例: from_airport,to_airport,source,date_range,interval_hour,expire_hour データは洗替されるので変更の際、注意ください"
              value={csvData}
              onChange={handleCsvInputChange}
            />
            <Button onClick={handleCsvUpload}>保存</Button>
          </Section>
          <Title>データ取得タイムテーブル</Title>
          <Section>
            <table style={{ fontSize: '10px' }}>
              <thead>
                <tr>
                  <th>出発空港</th>
                  <th>到着空港</th>
                  <th>キャリア</th>
                  <RightAlignTh>何日先までデータを取得するか(日)</RightAlignTh>
                  <RightAlignTh>タスク登録インターバル(時間)</RightAlignTh>
                  <RightAlignTh>期限(時間)</RightAlignTh>
                  <RightAlignTh>最終タスク登録時刻</RightAlignTh>
                  <RightAlignTh>手動タスク登録</RightAlignTh>
                </tr>
              </thead>
              <tbody>
                {schedulers.map((scheduler, index) => (
                  <tr key={index}>
                    <td>{scheduler.from_airport}</td>
                    <td>{scheduler.to_airport}</td>
                    <td>{scheduler.source}</td>
                    <RightAlignTd>{scheduler.date_range}</RightAlignTd>
                    <RightAlignTd>{scheduler.interval_hour}</RightAlignTd>
                    <RightAlignTd>{scheduler.expire_hour}</RightAlignTd>
                    <RightAlignTd>{scheduler.last_queued}</RightAlignTd>
                    <RightAlignTd>
                      <Button onClick={() => handleRegisterTask(scheduler.id)}>タスク登録</Button>
                    </RightAlignTd>
                  </tr>
                ))}
              </tbody>
            </table>
          </Section>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Textarea = styled.textarea`
  width: 80%;
  height: 150px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Message = styled.p`
  margin-top: 20px;
  font-size: 14px;
  color: green;
`;

const RightAlignTd = styled.td`
  text-align: right;
  padding: 4px;
`;

const RightAlignTh = styled.th`
  text-align: right;
  padding: 4px;
`;

export default observer(SettingTable);
