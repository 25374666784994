import { Fetcher } from '@this/src/util';
import React from 'react';
import { Redirect } from 'react-router-dom';
import Link from '@this/shared/atoms/link';
import { reportError } from '@this/lib/bugsnag';
import { TextField } from '../../shared/form_elements/form_elements';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

import User from '../../../domain/user/user';
import type UserJson from '../../../domain/user/user_json';

import PasswordResetForm from './password_reset_form';

interface Props {
  user: User | null | undefined;
  onSignedIn: (user: User) => void;
}

interface State {
  email: string;
  password: string;
  submitting: boolean;
  errors: {
    email?: string;
    password?: string;
  };
  submitError: string | null;
  succeed: boolean;
  showPasswordResetForm: boolean;
  redirectPath: string | null;
}

class Signin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      submitting: false,
      errors: {},
      submitError: null,
      succeed: false,
      showPasswordResetForm: false,
      redirectPath: null
    };
  }

  componentDidMount() {
    mixpanel.track('[Biztra][view] /users/sign_in');
  }

  handleEmailChange(email: string) {
    this.setState({ email });
  }

  handlePasswordChange(password: string) {
    this.setState({ password });
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      this.setState({ submitting: true, errors: {}, submitError: null });
      const args = {
        email: this.state.email,
        password: this.state.password
      };
      const response = await Fetcher.post<UserJson>('/biztra/users/sign_in.json', args);
      const user = new User(response);
      this.props.onSignedIn(user);
      this.setState({ submitting: false, succeed: true, redirectPath: response.redirect_path });
    } catch (e) {
      if (e.response.status === 400) {
        this.setState({ submitting: false, errors: e.response.data.errors });
      } else if (e.response.status === 401) {
        this.setState({ submitting: false, submitError: 'メールアドレスかパスワードが違います' });
      } else {
        this.setState({
          submitting: false,
          submitError: '通信エラーが発生しました。時間をおいて再度お試しください。'
        });
      }
    }
  }

  handleShowPasswordResetForm = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({ showPasswordResetForm: true });
  };

  navigateToSignin = () => {
    this.setState({ showPasswordResetForm: false });
  };

  render() {
    try {
      const { user } = this.props;
      const { email, password, submitting, succeed, errors, submitError, showPasswordResetForm, redirectPath } =
        this.state;
      const classBase = 'expenses-users-signup';
      return (
        <div className={classBase}>
          <div className={`${classBase}__content`}>
            {redirectPath ? (
              <Redirect to={redirectPath} />
            ) : user || succeed ? (
              <Redirect to="/biztra/report_items" />
            ) : showPasswordResetForm ? (
              <PasswordResetForm navigateToBack={this.navigateToSignin} />
            ) : (
              <form onSubmit={e => this.handleSubmit(e)}>
                <TextField
                  label="メールアドレス（必須）"
                  value={email}
                  example="例：biztra@aitravel.jp"
                  error={errors.email}
                  onChange={value => this.handleEmailChange(value)}
                />
                <TextField
                  label="パスワード（必須）"
                  value={password}
                  example=""
                  type="password"
                  error={errors.password}
                  onChange={value => this.handlePasswordChange(value)}
                />
                {submitting ? <SimpleLoading /> : <input type="submit" value="ログイン" />}
                {submitError && <div className="error">{submitError}</div>}
                <div className={`${classBase}__utils`}>
                  <a href="#" onClick={this.handleShowPasswordResetForm}>
                    パスワードをお忘れですか？
                  </a>
                </div>
              </form>
            )}
          </div>
          <div className={`${classBase}__footer`}>
            <Link to="/biztra/users/sign_up">新規登録はこちら</Link>
          </div>
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default Signin;
