import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import type { SvgIconComponent } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Flex } from '@this/shared/ui/layout/flex';

type Props = {
  className?: string;
  value: 'good' | 'bad' | 'not_rated';
  onClick: (value: 'good' | 'bad') => void;
  onReload?: () => void;
};

export const FeedbackButtons = ({ className, value, onClick, onReload }: Props) => (
  <Flex className={className} gap="4">
    {onReload && (
      <IconButton aria-label="reload" size="small" onClick={onReload}>
        <XSmallIcon Icon={RefreshIcon} />
      </IconButton>
    )}
    <IconButton aria-label="good" size="small" onClick={() => onClick('good')}>
      {value === 'good' ? (
        <XSmallIcon Icon={ThumbUpIcon} color="primary" />
      ) : (
        <XSmallIcon Icon={ThumbUpOutlinedIcon} />
      )}
    </IconButton>
    <IconButton aria-label="bad" size="small" onClick={() => onClick('bad')}>
      {value === 'bad' ? (
        <XSmallIcon Icon={ThumbDownIcon} color="primary" />
      ) : (
        <XSmallIcon Icon={ThumbDownOutlinedIcon} />
      )}
    </IconButton>
  </Flex>
);

const XSmallIcon = ({ Icon, color = 'inherit' }: { Icon: SvgIconComponent; color?: 'inherit' | 'primary' }) => (
  <Icon style={{ fontSize: '16px' }} color={color} />
);
