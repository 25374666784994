import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import {
  ReserveErrorAlert,
  StyledAlertTitle
} from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/input_customer_information.template';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Section } from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/trip_rule_section/trip_rule_error_section';
import type { TripDuplicateLogAPIProps } from '@this/src/api/trips/trip_duplicate_log_api';
import tripDuplicateLogAPI from '@this/src/api/trips/trip_duplicate_log_api';
import type { TripDuplicationInterface } from '../../../reserve_confirm';

interface Props {
  tripDuplication: TripDuplicationInterface;
  apiProps?: TripDuplicateLogAPIProps;
}

const SESSION = 'tripDuplicateLogsLastSent';

const handleTripDuplicateLogs = (apiProps?: TripDuplicateLogAPIProps) => {
  if (!apiProps?.sendLog) {
    return;
  }

  // コンポーネントが再レンダリングされた際に重複ログを送信しないようにする
  const cacheKey = JSON.stringify(_.pick(apiProps, ['searchQueryId', 'tripId', 'path']));
  const lastSent = sessionStorage.getItem(SESSION);

  if (lastSent === cacheKey) {
    return;
  }

  sessionStorage.setItem(SESSION, cacheKey);

  tripDuplicateLogAPI({
    ...apiProps,
    path: apiProps.path || window.location.pathname,
    tripDuplications: apiProps.tripDuplications || [],
    onError: () => {
      sessionStorage.removeItem(SESSION);
    }
  });
};

const TripDuplicationSection = observer(({ tripDuplication, apiProps }: Props) => {
  useEffect(() => handleTripDuplicateLogs(apiProps), [apiProps?.sendLog]);

  return (
    <ReserveErrorAlert icon={<ErrorOutlineIcon />} variant="filled" severity="error">
      <StyledAlertTitle>
        {tripDuplication.user_name}さんの以下の旅程番号のご依頼内容と重複している可能性がございます
      </StyledAlertTitle>
      <Section>
        <p>・旅程番号：{tripDuplication.trip_id}</p>
        <p>
          ・重複可能性がある旅行商品：
          {tripDuplication.element_types
            .map(
              type =>
                ({
                  hotel: 'ホテル',
                  shinkansen: '新幹線',
                  express: '特急',
                  domestic_air: '国内航空券',
                  foreign_air: '海外航空券',
                  rental_car: 'レンタカー',
                  taxi: 'タクシー',
                  bus: 'バス',
                  railway_ticket: '在来線'
                }[type])
            )
            .join()}
        </p>
        <p>
          申請前に申請必要性の有無をご確認いただき、申請の実行または過去の重複可能のある申請のキャンセルをお願いします。当該旅程の
          <Link href={`/trips/${tripDuplication.trip_id}`} target="_blank" rel="noopener noreferrer">
            詳細はこちら
          </Link>
        </p>
      </Section>
    </ReserveErrorAlert>
  );
});

const Link = styled.a`
  color: #0645ad;
`;

export default TripDuplicationSection;
