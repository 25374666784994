import React, { useEffect, useState } from 'react';
import { Fetcher } from '@this/src/util';
import type { KnowledgeResponseArgs } from '@this/domain/knowledge';
import Knowledge, { convertKnowledgeResponseToArgs } from '@this/domain/knowledge';
import { Heading, Text, TextTruncate } from '@this/shared/ui/data_displays/typography';
import { Title } from '@this/components/knowledge/knowledge';
import HelpSearchBlock from '@this/components/reserve_trip/dashboard/help_area_for_ait/help_search_block';
import { getColor } from '@this/shared/ui/theme';
import { Link } from '@this/shared/ui/navigations/link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { styled } from '@this/constants/themes';
import { Pagination } from '@this/shared/ui/navigations/pagination';
import { useHistory } from 'react-router-dom';
import { Loading } from '@this/shared/ui/feedbacks/loading';

interface Response {
  knowledges: KnowledgeResponseArgs[];
  total_count: number;
  current_page: number;
  total_pages: number;
}
const KnowledgeSearchResults = () => {
  const params = utils.getParams();
  const keyword = params.keyword || '';
  const initialPage = parseInt(params.page, 10) || 1;
  const itemsPerPage = 8;

  const [searchKeyword, setSearchKeyword] = useState<string>(keyword);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchResults, setSearchResults] = useState<Knowledge[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const itemCountEnd = currentPage * itemsPerPage > totalItemCount ? totalItemCount : currentPage * itemsPerPage;

  const history = useHistory();
  const handlePageChange = (newPageNumber: number) => {
    setCurrentPage(newPageNumber);
    history.push(`?keyword=${searchKeyword}&page=${newPageNumber}`);
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    Fetcher.get<Response>(`/knowledge_search_results.json?keyword=${searchKeyword}&page=${currentPage}`)
      .then(res => {
        setSearchResults(res.knowledges.map(k => new Knowledge(convertKnowledgeResponseToArgs(k))));
        setTotalItemCount(res.total_count);
        setCurrentPage(res.current_page);
        setTotalPages(res.total_pages);
      })
      .catch(e => {
        setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchKeyword, currentPage]);

  const handleSearchSubmit = (newKeyword: string) => {
    setSearchKeyword(newKeyword);
    setCurrentPage(1); // ページ番号をリセット
  };

  return (
    <div style={{ width: '100%', margin: '0 100px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <Title>よくあるご質問</Title>
      <HelpSearchBlock showTitle={false} searchKeyword={searchKeyword} onSearchSubmit={handleSearchSubmit} />
      {loading ? (
        <Loading />
      ) : error ? (
        <Text color="danger" level="body">
          {error}
        </Text>
      ) : (
        <>
          <div>
            <ResultDescription>
              <Heading level="2">「{searchKeyword}」の検索結果</Heading>
              {totalItemCount > 0 && (
                <Text color="primary" level="body">
                  {(currentPage - 1) * itemsPerPage + 1}-{itemCountEnd}/{totalItemCount}件
                </Text>
              )}
            </ResultDescription>
            <ul>
              {searchResults.map(result => (
                <KnowledgeLink key={result.salesforceId} href={`/knowledges/${result.salesforceId}`} style={{}}>
                  <div
                    style={{
                      padding: '15px 20px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '10px'
                    }}
                  >
                    <Text color="description" level="caption">
                      {result.breadcrumb}
                    </Text>
                    <TextTruncate lines={1}>
                      <Text color="brand" level="body">
                        {result.question}
                      </Text>
                    </TextTruncate>
                    <TextTruncate lines={1}>
                      <Text color="primary" level="body">
                        {result.answer}
                      </Text>
                    </TextTruncate>
                  </div>
                  <div>
                    <NavigateNextIcon />
                  </div>
                </KnowledgeLink>
              ))}
            </ul>
          </div>
          {totalItemCount === 0 ? (
            <Text color="primary" level="body">
              検索結果がありません。別のキーワードで検索してみてください。
            </Text>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination page={currentPage} count={totalPages} onChange={(_, page) => handlePageChange(page)} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const KnowledgeLink = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${getColor('border', 'divider')};
`;

const ResultDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 30px;
  border-bottom: 2px solid ${getColor('border', 'divider')};
`;

export default KnowledgeSearchResults;
