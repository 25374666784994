import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import type { IndividualDomesticAirSuppliedItem } from '@this/domain/individual_domestic_air_supplied_item';
import type { SuppliedItemArgs } from './supplied_item/supplied_item';
import SuppliedItem from './supplied_item/supplied_item';

type SuppliedItemCamelArgs = any;

export interface OrderItemOriginalPriceArgs {
  id?: number;
  orderItemOriginalPriceType: number;
  price: number;
  taxTypeId?: number;
  suppliedItemId?: number;
  suppliedItem?: SuppliedItemCamelArgs;
  originalPriceTraceId?: number;
  paymentMethodId?: number;
  purchasedAt?: string;
  accountRecordedAt?: string;
  closingDay?: string;
  dueDate?: string;
}

class OrderItemOriginalPrice {
  id: number | null;

  orderItemOriginalPriceType: number;

  price: number;

  taxTypeId: number | null;

  suppliedItemId: number | null;

  suppliedItem: SuppliedItem | null = null;

  supplied_item: SuppliedItemArgs | null = null;

  originalPriceTraceId: number | null;

  paymentMethodId: number | null;

  purchasedAt?: Moment;

  accountRecordedAt?: Moment | null;

  closingDay?: Moment | null;

  dueDate?: Moment | null;

  constructor(args: OrderItemOriginalPriceArgs) {
    this.id = args.id || null;
    this.orderItemOriginalPriceType = args.orderItemOriginalPriceType;
    this.price = args.price;
    this.taxTypeId = args.taxTypeId || null;
    this.suppliedItemId = args.suppliedItemId || null;
    this.originalPriceTraceId = args.originalPriceTraceId || null;
    this.paymentMethodId = args.paymentMethodId || null;
    this.purchasedAt = args.purchasedAt ? moment(args.purchasedAt) : undefined;
    this.closingDay = args.closingDay ? moment(args.closingDay) : undefined;
    this.dueDate = args.dueDate ? moment(args.dueDate) : undefined;
    this.accountRecordedAt = args.accountRecordedAt ? moment(args.accountRecordedAt) : undefined;
    if (args.suppliedItem) {
      // Argsをスネークケースで受け取っている為戻す
      const suppliedItemArgs = utils.camelToSnakeObject(args.suppliedItem);
      if (suppliedItemArgs.plane_object) {
        // OrderItem.diffWith内のcloneDeepに失敗するため、`plane_object`が指定された場合はインスタンス化しない
        this.supplied_item = suppliedItemArgs;
      } else {
        this.suppliedItem = new SuppliedItem(suppliedItemArgs);
      }
    }
  }

  updateParams() {
    return {
      order_item_original_price_type: this.orderItemOriginalPriceType,
      price: this.price,
      tax_type_id: this.taxTypeId,
      supplied_item_id: this.suppliedItemId,
      original_price_trace_id: this.originalPriceTraceId,
      payment_method_id: this.paymentMethodId,
      purchased_at: this.purchasedAt?.format('YYYY-MM-DD'),
      account_recorded_at: this.accountRecordedAt?.format('YYYY-MM-DD')
      // 以下2つのパラメーターはpayment_methodから導出されるものなので外からupdateできないため送らない
      // closing_day: this.closingDay?.format('YYYY-MM-DD'),
      // due_date: this.dueDate?.format('YYYY-MM-DD')
    };
  }

  setPrice(value: number) {
    this.price = value;
    app.render();
  }

  setTaxTypeId(value: number | null) {
    this.taxTypeId = value;
    app.render();
  }

  setSuppliedItemId(value: number | null) {
    this.suppliedItemId = value;
    app.render();
  }

  setPurchasedAt(value: Moment | undefined) {
    this.purchasedAt = value;
    app.render();
  }

  setPaymentMethod(value: number | null) {
    this.paymentMethodId = value;
    app.render();
  }

  defaultPaymentMethod(suppliedItems?: SuppliedItem[]) {
    if (suppliedItems) {
      const suppliedItem = suppliedItems?.find(s => s.id === this.suppliedItemId);

      return suppliedItem?.paymentMethodId;
    }

    return this.suppliedItem?.paymentMethodId || this.supplied_item?.payment_method_id;
  }

  validationErrors(): string[] {
    const errors: string[] = [];
    if (!_.isNil(this.price) && this.price > 0 && _.isNil(this.suppliedItemId)) {
      errors.push('新卸値が0円以外の場合は仕入商品を指定してください。');
    }
    return errors;
  }

  individualSuppliedItemId(
    name: string,
    ticketType: number,
    suppliedItems: SuppliedItem[],
    individualDomesticAirSuppliedItems: IndividualDomesticAirSuppliedItem[]
  ) {
    const items = individualDomesticAirSuppliedItems.filter(item => item.ticketing_type === ticketType);
    if (items.length > 0) {
      this.suppliedItemId = items[0].supplied_item_id;
    } else {
      this.suppliedItemId = null;
    }
    const paymentMethodId = this.defaultPaymentMethod(suppliedItems);
    this.setPaymentMethod(paymentMethodId ?? null);
  }
}

export default OrderItemOriginalPrice;
