import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import HowToCategory, { convertHowToCategoryResponseToArgs } from '@this/src/domain/how_to_category';
import type { RouteComponentProps } from 'react-router-dom';
import type { HowToCategoryResponseArgs } from '@this/domain/how_to_category';
import { ChevronIcon } from '@this/components/reserve_trip/dashboard/help_area_for_ait/faq_item_for_ait.template';
import { GtmPageview } from '@this/src/util/gtm';
import { defaultPageTitleText } from '@this/src/util/utils';
import { Loading } from '../../shared/ui/feedbacks/loading';
import { ContentWrapper, Title, CategoryTitle, AiTravelServiceId } from '../knowledge';
import { Link } from '../../shared/ui/navigations/link';

interface Props extends RouteComponentProps<{ category_type?: string }> {
  howToProps: {
    isAdmin: boolean;
  };
}

interface Response {
  how_to_category: HowToCategoryResponseArgs;
}

const HowToCategoryDetail: React.FC<Props> = observer(({ match, howToProps }) => {
  const categoryType = match.params.category_type;
  const [isLoading, setIsLoading] = useState(true);
  const isAdmin = howToProps.isAdmin;
  const [howToCategory, setHowToCategory] = useState<HowToCategory | undefined>(undefined);
  const [pageTitle, setPageTitle] = useState(howToCategory ? howToCategory.name : '　');

  const fetchHowToCategories = useCallback(() => {
    setIsLoading(true);
    const target = isAdmin ? 'admin' : 'traveler_approver';
    Fetcher.get<Response>(`/how_to_categories/${target}/${categoryType}.json`)
      .then(result => {
        setHowToCategory(new HowToCategory(convertHowToCategoryResponseToArgs(result.how_to_category)));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isAdmin, categoryType]);

  useEffect(() => {
    fetchHowToCategories();
  }, [fetchHowToCategories, isAdmin, categoryType]);

  useEffect(() => {
    setPageTitle(howToCategory ? howToCategory.name : '　');
  }, [howToCategory]);

  useEffect(() => {
    document.title = pageTitle;
    GtmPageview(window, pageTitle);
    return () => {
      document.title = defaultPageTitleText(AiTravelServiceId);
    };
  }, [pageTitle]);

  return (
    <ContentWrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Title>{howToCategory ? `${howToCategory.targetText}情報` : '　'}</Title>
          <CategoryTitle>{pageTitle}</CategoryTitle>
          <ul
            style={{
              display: 'flex',
              gap: '12px',
              flexDirection: 'column'
            }}
          >
            {howToCategory &&
              howToCategory.knowledges.map(k => (
                <Link
                  key={k.salesforceId}
                  href={`/knowledges/${k.salesforceId}`}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <ChevronIcon />
                  {k.title}
                </Link>
              ))}
          </ul>
        </>
      )}
    </ContentWrapper>
  );
});

export default HowToCategoryDetail;
