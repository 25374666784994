import { Fetcher } from '@this/src/util';
/* eslint max-lines: ["error", {"max": 600, "skipBlankLines": true, "skipComments": true}] */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import type { RouteComponentProps } from 'react-router-dom';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import { styled } from '@this/constants/themes';
import SearchForm from '@this/components/expenses/reports/search_form';
import { Checkbox } from '@this/components/expenses/ui/inputs/checkbox';
import type { ReportJson } from '../../../domain/expenses/report';
import { Report } from '../../../domain/expenses/report';
import type { ItemJson } from '../../../domain/expenses/item';
import { Item } from '../../../domain/expenses/item';
import ExpensesMain from '../main/main';
import { Header } from '../report_items/search_form';
import ReportItemCards from '../report_items/report_item_cards';
import ReportCard from './report_card';
import type { CutoffDate, CutoffDateJson } from '../../../domain/expenses/cutoff_date';

import SimpleLoading from '../../shared/simple_loading/simple_loading';

interface ReportResponse {
  report: ReportJson;
  items: ItemJson[];
  payees: string[];
  trip_reports: [number, string][];
  cutoff_dates: CutoffDateJson[];
  trip_report_available: boolean;
}

interface Props extends RouteComponentProps<{ id?: string }> {
  action: 'new' | 'edit';
}

interface State {
  report?: Report;
  items?: Item[];
  payees: string[];
  tripReports: [number, string][];
  filteredItems?: Item[];
  error?: string;
  loading: boolean;
  submittingReport?: Report;
  submitting: boolean;
  allSelected: boolean;
  cutoffDates?: CutoffDate[];
  formErrors?: boolean;
  // 検索関連
  showSearchModal: boolean;
  paidAtFrom: string;
  paidAtTo: string;
  selectedPayee: string;
  priceFrom: string;
  priceTo: string;
  tripReportId: string;
  // オプション関連
  tripReportAvailable: boolean;
}

class Form extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      payees: [],
      tripReports: [],
      loading: true,
      submitting: false,
      allSelected: false,
      showSearchModal: false,
      paidAtFrom: '',
      paidAtTo: '',
      selectedPayee: '',
      priceFrom: '',
      priceTo: '',
      tripReportId: '',
      tripReportAvailable: false
    };
  }

  async componentDidMount() {
    await this.fetchReport();
    mixpanel.track('[Biztra][view] /reports/new');
  }

  handleAddItem = () => {
    this.props.history.push(`/biztra/reports/${this.state.report!.id}/items/new`);
  };

  handleBackToReports() {
    this.props.history.push('/biztra/reports');
  }

  handleSelectItem = (item: Item) => {
    item.toggleSelected();
  };

  handleConfirm = () => {
    const report = _.cloneDeep(this.state.report);
    report!.items = this.selectedItems();
    this.setState({
      submittingReport: report
    });
  };

  handleCancelConfirm = () => {
    if (this.state.submitting) return;
    this.setState({
      submittingReport: undefined
    });
  };

  handleSubmit = () => {
    if (this.state.submittingReport && _.isEmpty(this.state.submittingReport.validationErrors())) {
      this.submitReport();
    } else {
      this.setState({
        formErrors: true
      });
    }
  };

  handleToggleSelectAll = () => {
    if (this.state.allSelected) {
      this.deselectAllItems();
    } else {
      this.selectAllItems();
    }
    this.setState({ allSelected: !this.state.allSelected });
  };

  setSearchParams = async (
    appliedAtFrom: string,
    appliedAtTo: string,
    selectedPayee: string,
    priceFrom: string,
    priceTo: string,
    tripReportId: string
  ) => {
    await this.setState({
      paidAtFrom: appliedAtFrom,
      paidAtTo: appliedAtTo,
      selectedPayee,
      priceFrom,
      priceTo,
      tripReportId,
      showSearchModal: false
    });
    this.filterReports();
  };

  private getFormat = (): string => 'YYYY-MM-DD';

  private filterReports = () => {
    const { items, paidAtFrom, paidAtTo, selectedPayee, priceFrom, priceTo, tripReportId, tripReportAvailable } =
      this.state;
    const filteredItems = items?.filter(i => {
      const isSameOrAfterPaidAt = paidAtFrom ? i.paidAt.isSameOrAfter(moment(paidAtFrom, this.getFormat())) : true;
      const isSameOrBeforePaidAt = paidAtTo
        ? i.paidAt.isSameOrBefore(moment(paidAtTo, this.getFormat()).add(1, 'days'))
        : true;

      const isSelectedPayee = selectedPayee ? i.payee === selectedPayee : true;

      const isHigherPrice = priceFrom ? i.price >= Number(priceFrom) : true;
      const isLowerPrice = priceTo ? i.price <= Number(priceTo) : true;

      const isTripReportId =
        tripReportAvailable && tripReportId ? i.tripReport?.id?.toString() === tripReportId : true;

      return (
        isSameOrAfterPaidAt &&
        isSameOrBeforePaidAt &&
        isSelectedPayee &&
        isHigherPrice &&
        isLowerPrice &&
        isTripReportId
      );
    });

    this.setState({
      filteredItems
    });
  };

  private async fetchReport() {
    try {
      this.setState({ loading: true });
      const path = this.fetchPath();
      const response = await Fetcher.get<ReportResponse>(path);
      const items = response.items.map(item => new Item(item));
      this.setState(
        {
          report: new Report(response.report),
          items,
          filteredItems: items,
          payees: response.payees,
          tripReports: response.trip_reports,
          loading: false,
          tripReportAvailable: response.trip_report_available
        },
        () => this.selectReportItems()
      );
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async submitReport() {
    try {
      this.setState({ submitting: true });
      const { submittingReport } = this.state;
      const url = this.submitPath();
      const data = { ids: this.selectedItemIds(), title: submittingReport ? submittingReport.title : null };
      const method = this.submitMethod();
      await Fetcher.request({ url, data, method });
      this.setState(
        {
          submittingReport: undefined,
          submitting: false
        },
        () => {
          this.props.history.push('/biztra/reports');
        }
      );
      mixpanel.track('[Biztra] report submitted');
    } catch (e) {
      this.setState({
        submittingReport: undefined,
        error: e.response.data?.message || '通信エラーが発生しました。時間をおいて再度お試しください。',
        submitting: false
      });
    }
  }

  private selectReportItems() {
    const ids = this.state.report!.items.map(i => i.id);
    this.state.items!.forEach(item => {
      if (ids.indexOf(item.id) > -1) item.selected = true;
    });
    app.render();
  }

  private selectedItems(): Item[] {
    if (!this.state.filteredItems) return [];
    return this.state.filteredItems!.filter(item => item.selected);
  }

  private selectedItemIds(): number[] {
    return this.selectedItems().map(item => item.id!);
  }

  private selectAllItems() {
    if (this.state.items) {
      this.state.items.forEach(item => {
        item.selected = true;
      });
    }
  }

  private deselectAllItems() {
    if (this.state.items) {
      this.state.items.forEach(item => {
        item.selected = false;
      });
    }
  }

  private fetchPath(): string {
    return this.props.action === 'new'
      ? '/biztra/reports/new'
      : `/biztra/reports/${this.props.match.params.id}/edit`;
  }

  private submitPath(): string {
    return this.props.action === 'new' ? '/biztra/reports' : `/biztra/reports/${this.props.match.params.id}/apply`;
  }

  private submitMethod(): 'POST' | 'PUT' {
    return this.props.action === 'new' ? 'POST' : 'PUT';
  }

  filteredPaidAt() {
    return (
      <FilteredWrapper>
        <FilteredTitle>期間</FilteredTitle>
        <span>：</span>
        <span>{this.state.paidAtFrom}</span>
        <FromTo>～</FromTo>
        <span>{this.state.paidAtTo}</span>
      </FilteredWrapper>
    );
  }

  filteredPayee() {
    return (
      <FilteredWrapper>
        <FilteredTitle>取引先</FilteredTitle>
        <span>：</span>
        {this.state.selectedPayee && <span>{this.state.selectedPayee}</span>}
      </FilteredWrapper>
    );
  }

  filteredPrice() {
    return (
      <FilteredWrapper>
        <FilteredTitle>金額</FilteredTitle>
        <span>：</span>
        {this.state.priceFrom && <span>{this.state.priceFrom}円</span>}
        <FromTo>～</FromTo>
        {this.state.priceTo && <span>{this.state.priceTo}円</span>}
      </FilteredWrapper>
    );
  }

  filteredTripReportId() {
    const label = this.state.tripReports.find(([id]) => id.toString() === this.state.tripReportId)?.[1];
    return (
      <FilteredWrapper>
        <FilteredTitle>出張報告</FilteredTitle>
        <span>：</span>
        {this.state.tripReportId && <span>{label}</span>}
      </FilteredWrapper>
    );
  }

  searchModal() {
    const {
      showSearchModal,
      payees,
      tripReports,
      paidAtFrom,
      paidAtTo,
      selectedPayee,
      priceFrom,
      priceTo,
      tripReportId,
      tripReportAvailable
    } = this.state;

    return (
      <Modal
        size="medium"
        onClose={() => {
          this.setState({ showSearchModal: false });
        }}
        open={showSearchModal}
        PaperProps={{ style: { background: 'white' } }}
      >
        <SearchForm
          onSetSearchParams={this.setSearchParams}
          onCancel={() => this.setState({ showSearchModal: false })}
          payees={payees}
          tripReports={tripReports}
          periodFrom={paidAtFrom}
          periodTo={paidAtTo}
          selectedPayee={selectedPayee}
          priceFrom={priceFrom}
          priceTo={priceTo}
          tripReportId={tripReportId}
          tripReportAvailable={tripReportAvailable}
        />
      </Modal>
    );
  }

  confirmModal() {
    const { submitting, submittingReport, formErrors } = this.state;
    return submittingReport ? (
      <Modal
        size="medium"
        onClose={() => this.handleCancelConfirm()}
        open={!!submittingReport}
        PaperProps={{ style: { background: 'white' } }}
      >
        <ModalWrapper>
          <Header>この内容で申請書を確定しますか？</Header>
          <ReportCard
            report={submittingReport}
            confirm
            error={formErrors}
            submitting={submitting}
            submitReport={() => this.handleSubmit()}
            cancelReport={() => this.handleCancelConfirm()}
          />
        </ModalWrapper>
      </Modal>
    ) : null;
  }

  render() {
    const {
      loading,
      error,
      allSelected,
      paidAtFrom,
      paidAtTo,
      selectedPayee,
      priceFrom,
      priceTo,
      tripReportId,
      tripReportAvailable
    } = this.state;

    return (
      <ExpensesMain title="申請を追加">
        {loading ? (
          <SimpleLoading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <CheckBoxAndBtnAndFilters>
              <CheckBoxLabel className="expenses-report-edit__select-all">
                <Checkbox checked={allSelected} onChange={() => this.handleToggleSelectAll()} />
                {allSelected ? 'すべて解除' : 'すべて選択'}
              </CheckBoxLabel>
              <OpenSearchModalButton
                type="button"
                onClick={e => {
                  e.currentTarget.blur();
                  this.setState({ showSearchModal: true });
                }}
              >
                検索条件を指定
              </OpenSearchModalButton>
              <FiltersWrapper>
                {(!!paidAtFrom || !!paidAtTo) && this.filteredPaidAt()}
                {!!selectedPayee && this.filteredPayee()}
                {(!!priceFrom || !!priceTo) && this.filteredPrice()}
                {!!tripReportId && this.filteredTripReportId()}
              </FiltersWrapper>
            </CheckBoxAndBtnAndFilters>
            <ReportItemCards
              items={this.state.filteredItems!}
              handleAddItem={this.handleAddItem}
              onClickItem={this.handleSelectItem}
              withCheckbox
              tripReportAvailable={tripReportAvailable}
            />
            <ApplyWrapper>
              <BackButton
                onClick={() => {
                  this.handleBackToReports();
                }}
              >
                戻る
              </BackButton>
              <BaseButton
                onClick={e => {
                  e.currentTarget.blur();
                  this.handleConfirm();
                }}
                disabled={this.selectedItems().length === 0}
              >
                申請
              </BaseButton>
            </ApplyWrapper>
          </>
        )}
        {this.confirmModal()}
        {this.searchModal()}
      </ExpensesMain>
    );
  }
}

export default Form;

const outlineBtnColor = '#FFFFFF';

const baseColor = '#927230';

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const OutlineButton = styled.button.attrs({ type: 'button' })`
  background: ${outlineBtnColor};
  color: ${baseColor};
  border: 1px solid ${baseColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${outlineBtnColor};
    color: ${baseColor};
  }
`;

const BaseButton = styled.button.attrs({ type: 'button' })`
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  border-radius: 6px;
  font-size: 14px;
  line-height: 1;
  width: 68px;
  padding: 9px 0 8px;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
    color: ${primaryTxtColor};
  }
`;

const BackButton = styled(BaseButton)`
  background: transparent;
  border: 1px solid ${baseColor};
  color: ${baseColor};
  &&:focus,
  &&:hover {
    background: transparent;
    color: ${baseColor};
  }
`;

const OpenSearchModalButton = styled(OutlineButton)`
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
  margin-left: calc(12 / 1273 * 100%);
  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    margin-left: unset;
  }
`;

const CheckBoxAndBtnAndFilters = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  & label {
    margin-bottom: 0;
    width: auto;
    margin-left: unset;
    margin-right: unset;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
  }
`;

const CheckBoxLabel = styled.label`
  color: ${baseColor};
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilteredWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

const FilteredTitle = styled.span`
  color: ${baseColor};
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 12px;
`;

const FromTo = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`;

const ApplyWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  @media screen and (max-width: 767px) {
    margin-right: auto;
  }
`;

const ModalWrapper = styled.div`
  padding 12px 40px;
`;
