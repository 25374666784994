import moment from 'moment-timezone';
import { toDomesticTel } from '@this/src/util/string';

type RentalCarRequest = {
  id: number;
  trip_id: number;
  departure_date: string;
  return_date: string;
  departure_place: string;
  return_place: string;
  car_type: 'compact' | 'sedan' | 'other';
  car_type_other: string;
  remarks: string;
  first_name_kana: string;
  last_name_kana: string;
  car_number: string;
  driver_tel: string;
};

type RentalCarTypeOptions = [string, string][];

class RentalCarList {
  list: RentalCarRequest[];

  typeOptions: RentalCarTypeOptions;

  constructor(list: RentalCarRequest[], types: RentalCarTypeOptions) {
    this.list = list;
    this.typeOptions = types;
  }

  detail() {
    return this.list
      .map((r, i) => {
        let d = '';
        if (i > 0) {
          d += '\n\n';
        }
        d += `<${r.departure_place} ${this.dateText(r.departure_date)}出発 〜 `;
        d += `${r.return_place} ${this.dateText(r.return_date)}返却>`;
        d += `\n 車種：${this.typeText(r.car_type)}`;
        if (r.car_type === 'other') {
          d += `\n ${r.car_type_other}`;
        }
        d += `\n 台数：${r.car_number}台`;
        d += `\n 運転者氏名：${r.last_name_kana} ${r.first_name_kana}`;
        d += `\n 運転者電話番号：${toDomesticTel(r.driver_tel)}`;
        d += `\n 備考：${r.remarks}`;
        return d;
      })
      .join('');
  }

  dateText(date: string) {
    return moment(date).format('M月D日HH時mm分');
  }

  typeText(type: string) {
    if (type === 'other') {
      return 'その他';
    }
    const typeOption = this.typeOptions.find(option => type === option[1]);
    if (typeOption) {
      return typeOption[0];
    }
    return '';
  }
}

export default RentalCarList;
