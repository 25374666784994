import { Fetcher } from '@this/src/util';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import TextArea from 'react-textarea-autosize';
import type { RuleCabinetJson } from '@this/domain/expenses/rule_cabinet';
import { RuleCabinet } from '@this/domain/expenses/rule_cabinet';
import MainTitle from '@this/shared/organization/main_title/main_title';
import {
  OrganizationFormSection,
  OrganizationLabel
} from '@this/components/expenses/organization/organization.style';
import { ContentBody } from '../organization';

interface RuleCabinetResponse {
  rule_cabinet: RuleCabinetJson;
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  ruleCabinet?: RuleCabinet;
  error?: string;
  loading: boolean;
}

const baseClass = 'expenses-report-item-form';

const basePath = '/biztra/organization/rule_cabinets/';

class Detail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.fetchRuleCabinet();
  }

  private async fetchRuleCabinet() {
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<RuleCabinetResponse>(`${basePath}${this.props.match.params.id}`);
      this.setState({
        ruleCabinet: new RuleCabinet(response.rule_cabinet),
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  render() {
    const { loading, error } = this.state;

    return (
      <>
        {this.props.match.params.id !== 'new' ? (
          <>
            <MainTitle value="ルール保管庫" />
            <ContentBody>
              {loading ? (
                <SimpleLoading />
              ) : error ? (
                <p>{error}</p>
              ) : (
                <OrganizationFormSection>
                  <OrganizationLabel>{this.state.ruleCabinet?.title}</OrganizationLabel>
                  <TextArea
                    value={this.state.ruleCabinet?.content}
                    minRows={20}
                    disabled
                    className={`${baseClass}__rule-content`}
                  />
                </OrganizationFormSection>
              )}
            </ContentBody>
          </>
        ) : null}
      </>
    );
  }
}

export default Detail;
