/**
 * 分類ができない雑多なUtilです。
 * 分類ができ次第ディレクトリを作成します。
 */

import { zenToHan } from './string';

export const calcDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  // ラジアンに変換
  const a_lat = (lat1 * Math.PI) / 180;
  const a_lon = (lon1 * Math.PI) / 180;
  const b_lat = (lat2 * Math.PI) / 180;
  const b_lon = (lon2 * Math.PI) / 180;

  // 緯度の平均、緯度間の差、経度間の差
  const latave = (a_lat + b_lat) / 2;
  const latidiff = a_lat - b_lat;
  const longdiff = a_lon - b_lon;

  // 子午線曲率半径
  // 半径を6335439m、離心率を0.006694で設定してます
  const meridian = 6335439 / Math.sqrt((1 - 0.006694 * Math.sin(latave) * Math.sin(latave)) ** 3);

  // 卯酉線曲率半径
  // 半径を6378137m、離心率を0.006694で設定してます
  const primevertical = 6378137 / Math.sqrt(1 - 0.006694 * Math.sin(latave) * Math.sin(latave));

  // Hubenyの簡易式
  const x = meridian * latidiff;
  const y = primevertical * Math.cos(latave) * longdiff;

  return Math.sqrt(x ** 2 + y ** 2);
};

export const walkMinutesText = (distanceMeter: number) => {
  const minutes = Math.floor(distanceMeter / 70);
  return `${minutes}分`;
};

export const isAiTravel = (id: string | number) => {
  return parseInt(id.toString(), 10) === 1;
};

export const isTabikobo = (id: string | number) => {
  return parseInt(id.toString(), 10) === 2;
};

export const isMynavi = (id: string | number) => {
  return parseInt(id.toString(), 10) === 3;
};

export const transitIconPath = (name: string) => {
  if (!name) {
    return undefined;
  }

  const icons = [
    'ANA',
    'JAL',
    'SKY',
    'ADO',
    'SFJ',
    'JR',
    'SNA',
    'JJP',
    'GK',
    'AMX',
    'FDA',
    'HAC',
    'IBX',
    'ORC',
    'MM',
    'APJ',
    'JW',
    'IJ',
    'JTA',
    'RAC',
    'JAC',
    'DJ'
  ];
  // replace 内の Max はレシーバーが "JR新幹線Maxとき" の場合に対応している。
  // see: https://github.com/BEST10developers/travel.ai/pull/3275/files#r426154951
  const code = zenToHan(name.split('(').shift() ?? '')
    .replace(/([^a-zA-Z]|Max)/g, '')
    .toUpperCase();
  if (icons.indexOf(code) === -1 && typeof bugsnagClient !== 'undefined') {
    bugsnagClient.notify(new Error(`NO_ICON_ERROR: Icon of ${name} is not prepared`));
    return undefined;
  }

  return `/images/transit_icons/${code}.png`;
};

export const flightIconPath = (name: string) => {
  if (!name) {
    return undefined;
  }

  const icons = [
    'AA',
    'AC',
    'AF',
    'AI',
    'AM',
    'AS',
    'AY',
    'B7',
    'BA',
    'BI',
    'BR',
    'CA',
    'CI',
    'CX',
    'CZ',
    'DL',
    'EK',
    'ET',
    'EY',
    'FM',
    'GA',
    'GS',
    'GW',
    'HA',
    'HO',
    'HU',
    'HX',
    'JL',
    'JQ',
    'KA',
    'KE',
    'KL',
    'LA',
    'LH',
    'LJ',
    'LO',
    'LX',
    'MF',
    'MH',
    'MI',
    'MU',
    'NH',
    'NX',
    'NZ',
    'OD',
    'OM',
    'OS',
    'OZ',
    'PG',
    'PK',
    'PR',
    'QF',
    'QR',
    'SA',
    'SC',
    'SK',
    'SL',
    'SQ',
    'SU',
    'TG',
    'TK',
    'TN',
    'TR',
    'TW',
    'UA',
    'UL',
    'UO',
    'VJ',
    'VN',
    'ZH',
    'ZU',
    '3U'
  ];
  // https://aitravel.atlassian.net/browse/AITRAVEL-1929
  const exceptionCodes = ['H1', 'B1'];
  const code = zenToHan(name)
    .replace(/[^a-zA-Z0-9]/g, '')
    .toUpperCase()
    .substr(0, 2);
  if (exceptionCodes.indexOf(code) !== 0) {
    if (icons.indexOf(code) === -1 && typeof bugsnagClient !== 'undefined') {
      bugsnagClient.notify(new Error(`NO_ICON_ERROR: Icon of ${name} is not prepared`));
    } else if (exceptionCodes.indexOf(code) !== 0) {
      return `/images/flight_icons/${code}.png`;
    }
  }

  return undefined;
};

export const flightClassIconPath = (cabin: string) => {
  if (!cabin) {
    return undefined;
  }
  return `/images/flight_class_icons/${cabin}.png`;
};

export const flightClassName = (cabin: string) => {
  if (!cabin) {
    return undefined;
  }
  switch (cabin) {
    case 'F':
      return 'ファーストクラス';
    case 'C':
      return 'ビジネスクラス';
    case 'W':
      return 'プレミアムエコノミークラス';
    case 'M':
      return 'エコノミークラス';
    default:
      return undefined;
  }
};

/**
 * 到達しないコードで使用することで、追加実装漏れをtscで検知できるようにします。
 * swithのdefault節などで使用することを想定します。
 * ref: https://qiita.com/sosomuse/items/b7b36b95686b83ec36f4
 */
export const assertNever = (_: never) => {
  throw new Error('This code should not be called');
};

export const defaultPageTitleText = (serviceId: string | number) => {
  if (serviceId && isMynavi(serviceId)) {
    return 'マイナビBTM 出張サポート：シンプルで使いやすい出張の予約手配サービス';
  }
  return '出張管理システム AI Travel：世界一シンプルな国内出張・海外出張の予約手配サービス';
};
