import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from '@this/shared/header/header';
import Footer from '@this/shared/footer/footer';
import { createTheme, styled } from '@this/constants/themes';
import { AITThemeProvider } from '@this/shared/ui/theme';

export const PageNotFound = () => {
  const theme = createTheme();

  return (
    <AITThemeProvider theme={theme}>
      <Router>
        <div>
          <Header />
          <Wrapper>
            <Content>
              <Title>404 Page Not Found</Title>
              <Section>
                <p>ご指定のページが見つかりません。</p>
              </Section>
              <Section>
                <p>ご利用いただきありがとうございます。</p>
                <p>申し訳ございませんが、ご指定のページが</p>
                <p>存在しない可能性があります。</p>
              </Section>
              <Section>
                <a href="/">トップページに戻る</a>
              </Section>
            </Content>
          </Wrapper>
          <Footer signedIn={false} />
        </div>
      </Router>
    </AITThemeProvider>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  min-height: calc(100vh - 50px - 74px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  margin: 100px 0 20px;
  font-size: 1.5em;
`;

const Section = styled.section`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
