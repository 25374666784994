import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import A from '@this/shared/atoms/a';
import _ from 'lodash';

import FlightSeatMapModal from '@this/components/reserve_trip/reserve_confirm/flight_seat_map_modal';
import { Button } from '@material-ui/core';
import Transit from '@this/domain/transit/transit';
import Flight from '@this/domain/flight/flight';
import Hotel from '@this/domain/hotel/hotel';
import MarketLogHelper from '@this/domain/market_log/market_log_helper';
import type TravelerList from '@this/domain/traveler/traveler_list';
import { Text } from '@this/components/shared/ui/data_displays/typography';

import { Flex } from '@this/shared/ui/layout/flex';
import ReserveConfirmTransitTr from '../tripelement_tr/transit_tr';
import ReserveConfirmHotelTr from '../tripelement_tr/hotel_tr';
import ReserveConfirmFlightTr from '../tripelement_tr/flight_tr';

type Props = {
  isPackage: boolean;
  domesticAirPriceIndex: number | null | undefined;
  elementType: 'transport' | 'hotel' | 'flight' | undefined;
  element: Transit | Hotel | Flight;
  elementRaw: any;
  foreignExist: boolean;
  // shinkansenTooLate: boolean;
  index: number | string | undefined;
  flightDescription: string | undefined;
  deadline: string | undefined;
  minirule: string | undefined;
  flightPrice: number | null;
  hotelPrice: number;
  peoplenum: number;
  roomnum: number | undefined;
  travelers: TravelerList;
  theme: { serviceName: string };
};

const ReserveConfirmOutlineItemTemplate: React.SFC<Props> = ({
  isPackage,
  domesticAirPriceIndex,
  elementType,
  element,
  elementRaw,
  foreignExist,
  // shinkansenTooLate,
  index,
  flightDescription,
  deadline,
  minirule,
  flightPrice,
  peoplenum,
  hotelPrice,
  roomnum,
  travelers,
  theme
}) => {
  const [showFlightSeatMpaModal, setShowFlightSeatMapModal] = useState(false);

  return (
    <ReserveConfirmRightSection>
      {elementType === 'transport' && element instanceof Transit ? (
        <>
          <ReserveConfirmRightTitle>
            <p>{element.reserveConfirmElementLabel()}</p>
            <p>
              {!element.package && (
                <>
                  <span data-wovn-ignore>{utils.digits(element.reserveConfirmElementPrice())}</span>円
                </>
              )}
            </p>
          </ReserveConfirmRightTitle>
          <ReserveConfirmRightContent>
            <ReserveConfirmTransitTr
              transit={element}
              elementRaw={elementRaw}
              domesticAirPriceIndex={domesticAirPriceIndex}
            />
            {MarketLogHelper.isObjectMarketLogLinkAvailable(element, theme.serviceName) && (
              <MarketLink
                href={MarketLogHelper.objectMarketLogPath(element)}
                target="_blank"
                rel="noopener noreferrer"
              >
                マーケットログを確認
                <img
                  className="organization-trips-detail__detail-panel__item-block__market-log-link-confirm"
                  src="/images/external_link_icon.png"
                  alt="link"
                />
              </MarketLink>
            )}
          </ReserveConfirmRightContent>
        </>
      ) : elementType === 'flight' && element instanceof Flight ? (
        <>
          <ReserveConfirmRightTitle>
            <p>飛行機</p>
            <p>
              <span data-wovn-ignore>{utils.digits(element.price)}</span>円
            </p>
          </ReserveConfirmRightTitle>
          <ReserveConfirmRightContent>
            <Flex alignItems="center">
              <Text color="description" level="caption">
                発券期限：
              </Text>
              <Text color="danger" level="caption" bold>
                {element.itineraries.length > 0 && element.itineraries[0].rawTicketingExpiredAt ? (
                  <span data-wovn-ignore>
                    {element.itineraries[0].rawTicketingExpiredAt.format('YYYY/MM/DD HH:mm')}
                  </span>
                ) : (
                  <span data-wovn-ignore>ご依頼から{deadline}時間</span>
                )}
              </Text>
            </Flex>
            <br />
            <ReserveConfirmFlightTr flight={element} />
            {foreignExist && MarketLogHelper.isObjectMarketLogLinkAvailable(element, theme.serviceName) && (
              <MarketLink
                href={MarketLogHelper.objectMarketLogPath(element)}
                target="_blank"
                rel="noopener noreferrer"
              >
                マーケットログを確認
                <img
                  className="organization-trips-detail__detail-panel__item-block__market-log-link-confirm"
                  src="/images/external_link_icon.png"
                  alt="link"
                />
              </MarketLink>
            )}
            {element.seatReservationEnable && (
              <Button
                color="primary"
                onClick={() => {
                  setShowFlightSeatMapModal(true);
                }}
              >
                座席指定する
              </Button>
            )}
            <FlightSeatMapModal
              show={showFlightSeatMpaModal}
              onHide={() => {
                setShowFlightSeatMapModal(false);
              }}
              flight={element}
              travelers={travelers}
            />
          </ReserveConfirmRightContent>
        </>
      ) : elementType === 'hotel' && element instanceof Hotel ? (
        <>
          <ReserveConfirmRightTitle>
            <p>宿泊</p>
            <p>
              <span data-wovn-ignore>{utils.digits(element.price || 0)}</span>円
            </p>
          </ReserveConfirmRightTitle>
          <ReserveConfirmRightContent>
            <ReserveConfirmHotelTr
              hotel={element}
              isPackage={isPackage}
              hotelPrice={hotelPrice}
              peoplenum={peoplenum}
              roomnum={roomnum}
            />
            {!isPackage && (
              <A href={element.detail_path || undefined} target="_blank" rel="noopener noreferrer">
                詳細を確認
              </A>
            )}
            {MarketLogHelper.isObjectMarketLogLinkAvailable(element, theme.serviceName) && (
              <MarketLink
                href={MarketLogHelper.objectMarketLogPath(element)}
                target="_blank"
                rel="noopener noreferrer"
              >
                マーケットログを確認
                <img
                  className="organization-trips-detail__detail-panel__item-block__market-log-link-confirm"
                  src="/images/external_link_icon.png"
                  alt="link"
                />
              </MarketLink>
            )}
          </ReserveConfirmRightContent>
        </>
      ) : null}
    </ReserveConfirmRightSection>
  );
};

const ReserveConfirmRightSection = styled.div`
  margin-bottom: 15px;
`;

const ReserveConfirmRightTitle = styled.div`
  font-weight: bold;
  color: #959595;
  padding: 10px 10px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReserveConfirmRightContent = styled.div`
  font-weight: bold;
  color: #959595;
  padding: 6px 10px 10px 10px;
`;

const MarketLink = styled.a`
  display: block;
  color: ${props => props.theme.linkColor};
  margin-top: 10px;
`;

export default withTheme(ReserveConfirmOutlineItemTemplate);
