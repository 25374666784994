import { Fetcher } from '@this/src/util';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import Button from '@this/shared/atoms/button';
import A from '@this/shared/atoms/a';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { CreditBalanceHistoryResponse } from '@this/domain/credit_balance_history';
import CreditBalanceHistory, {
  convertCreditBalanceHistoryResponseToArgs
} from '@this/domain/credit_balance_history';
import { ExpensesMonthlyPriceOrder } from '@this/src/domain/expenses/monthly_price_order';
import type { ExpensesMonthlyPriceOrderArgs } from '@this/src/domain/expenses/monthly_price_order';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MainTitle from '@this/shared/organization/main_title/main_title';
import { ContentBodyDetail } from './organization';
import CardSetupForm from './CardSetupForm';
import { BiztraModal, BiztraModalHeader, BiztraModalBody } from '../ui/feedbacks/modal';

type PaymentType = 'contract' | 'order';

interface Response {
  payment_type: PaymentType | null;
  current_plan: string | null;
  member_count: number;
  price_per_user: number;
  stripe_pk: string;
  credit_balance_histories: CreditBalanceHistoryResponse[];
  monthly_price_orders: ExpensesMonthlyPriceOrderArgs[];
}

const Plans = observer(() => {
  const [currentPlan, setCurrentPlan] = useState<string | null>(null);
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [memberCount, setMemberCount] = useState<number>(1);
  const [pricePerUser, setPricePerUser] = useState<number>(0);
  const [historyRecords, setHistoryRecords] = useState<CreditBalanceHistory[]>([]);
  const [monthlyPriceOrders, setMonthlyPriceOrders] = useState<ExpensesMonthlyPriceOrder[]>([]);
  const [showDownGradeModal, setShowDownGradeModal] = useState<boolean>(false);
  const [downGradeSubmitting, setDownGradeSubmitting] = useState<boolean>(false);
  const [downGradeError, setDownGradeError] = useState<string | null>(null);
  const [showPriceDetail, setShowPriceDetail] = useState<boolean>(false);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  const fetchData = async () => {
    setLoading(true);
    const res = await Fetcher.get<Response>('/biztra/organization/plans.json');
    setLoading(false);
    setCurrentPlan(res.current_plan);
    setPaymentType(res.payment_type);
    setMemberCount(res.member_count);
    setPricePerUser(res.price_per_user);
    setHistoryRecords(
      res.credit_balance_histories.map(h => {
        return new CreditBalanceHistory(convertCreditBalanceHistoryResponseToArgs(h));
      })
    );
    setMonthlyPriceOrders(res.monthly_price_orders.map(raw => new ExpensesMonthlyPriceOrder(raw)));
    if (!stripePromise) {
      setStripePromise(loadStripe(res.stripe_pk));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmitPayment = () => {
    setShowPaymentModal(false);
    fetchData();
  };

  const handleDownGrade = () => {
    setShowDownGradeModal(true);
  };

  const handleSubmitDownGrade = async () => {
    setDownGradeSubmitting(true);
    setDownGradeError(null);
    try {
      await Fetcher.delete('/biztra/organization/plans.json', {});
      setShowDownGradeModal(false);
      fetchData();
    } catch (e) {
      const message =
        e && e.response.data
          ? e.response.data.message
          : '通信エラーが発生しました。時間をおいて再度お試しください。';
      setDownGradeError(message);
    }
    setDownGradeSubmitting(false);
  };

  const totalPrice = pricePerUser * memberCount;

  return (
    <>
      <MainTitle value="利用プラン" />
      <ContentBodyDetail>
        {loading ? (
          <SimpleLoading />
        ) : (
          <>
            <Section>
              <PlanTitle>現在のプラン</PlanTitle>
              <PlanCuurent>{currentPlan || '無料プラン'}</PlanCuurent>
            </Section>
            {!paymentType && (
              <Section>
                <TheButton onClick={() => setShowPaymentModal(true)}>ビズトラProにアップグレード</TheButton>
                <Ul>
                  <Li>アップグレードすると領収書の画像ファイルを添付できるようになります</Li>
                  <Li>振込用データ（全銀フォーマット）をダウンロードできるようになります</Li>
                  <Li>月額200円（税抜、1ユーザあたり）</Li>
                </Ul>
              </Section>
            )}
            {historyRecords.length > 0 && (
              <Section>
                <h3>履歴</h3>
                <Table>
                  <tbody>
                    <tr>
                      <Th>日時</Th>
                      <Th>内容</Th>
                      <Th>有効期限</Th>
                      <ThRight>請求額</ThRight>
                      <ThRight>お支払額</ThRight>
                      <ThRight>クレジットポイント残高</ThRight>
                      <ThRight>領収書</ThRight>
                    </tr>
                    {historyRecords.map(r => (
                      <tr key={r.id}>
                        <Td>{r.date.format('YYYY/MM/DD hh:mm')}</Td>
                        <Td>{r.description}</Td>
                        <Td>{r.expireAt?.format('YYYY/MM/DD')}</Td>
                        <TdRight>
                          {typeof r.billedAmount === 'number' ? utils.formatPrice(r.billedAmount) : ''}
                        </TdRight>
                        <TdRight>
                          {typeof r.paidAmount === 'number' ? utils.formatPrice(r.paidAmount) : ''}
                        </TdRight>
                        <TdRight>{utils.formatPrice(r.balance)}</TdRight>
                        {moment(r.date.format('YYYY-MM-DD')).isAfter(moment('2023-10-01')) ? (
                          <TdRight>
                            {r.paidAmount && (
                              <>
                                <A href={`/biztra/organization/payment_receipts/${r.id}.pdf`}>PDF</A>
                                {` / `}
                                <A href={`/biztra/organization/payment_receipts/${r.id}_qualified.pdf`}>適格PDF</A>
                              </>
                            )}
                          </TdRight>
                        ) : (
                          <TdRight>
                            {r.paidAmount && <A href={`/biztra/organization/payment_receipts/${r.id}.pdf`}>PDF</A>}
                          </TdRight>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Section>
            )}
            {monthlyPriceOrders.length > 0 && (
              <Section>
                <h3>履歴</h3>
                <Description>
                  <div>当アカウントは請求書払いによる決済でご利用いただいております。</div>
                  <div>
                    発行済みの請求書は<A href="/organization/invoices">こちら</A>よりご確認ください。
                  </div>
                </Description>
                <Table>
                  <tbody>
                    <tr>
                      <Th>品目</Th>
                      <Th>単価</Th>
                      <Th>人数</Th>
                      <Th>請求月</Th>
                      <ThRight>価格</ThRight>
                      <ThRight>合計（税込）</ThRight>
                    </tr>
                    {monthlyPriceOrders.map(r => (
                      <tr key={r.id}>
                        <Td>ビズトラ利用料{r.title}</Td>
                        <Td>{r.formattedPriceUnit()}</Td>
                        <Td>{r.formattedUserCount()}</Td>
                        <Td>{r.formattedBillingTarget()}</Td>
                        <TdRight>{r.formattedPriceWithoutTax()}</TdRight>
                        <TdRight>{r.formattedPriceWithTax()}</TdRight>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Section>
            )}
            {paymentType !== 'order' && (
              <Section>
                {showPriceDetail ? (
                  <>
                    <A onClick={() => setShowPriceDetail(false)}>▼料金詳細</A>
                    <PriceDescriptionBlock>
                      <PriceDescriptionSection>
                        <h3>概要</h3>
                        <Ul>
                          <Li>
                            ビズトラProは、1ユーザーあたり月額200円（税抜き）で、有料機能がご利用いただけるプランです。
                          </Li>
                          <Li>
                            登録ユーザー数の増減の際は、日割りで実際にご利用いただいた日数に応じて料金が発生します。
                          </Li>
                          <Li>
                            クレジットカードの引き落とし回数を可能な限り更新時の月1回に抑えるため、「クレジットポイント」という仕組みを採用しています。ユーザー減少の際はクレジットポイントとして払い戻し、ユーザーの追加または更新の際にクレジットポイントを利用して料金を支払うことができます。
                          </Li>
                          <Li>
                            クレジットポイントには貨幣価値または交換価値がなく、譲渡や返金はできません。また、有料プランの終了に伴い失効します。
                          </Li>
                        </Ul>
                      </PriceDescriptionSection>
                      <PriceDescriptionSection>
                        <h3>料金の発生と決済のタイミングについて</h3>
                        <Ul>
                          <Li>
                            有料プランにアップグレードした日に、有効なユーザー分の料金がクレジットカードから引き落とされます。
                          </Li>
                          <Li>
                            無料プランにダウングレードしない限り、有効期間の最終日に自動で更新され、クレジットカードから引き落とされます。
                          </Li>
                          <Li>
                            有料プランを利用中にユーザーを無効化すると、次の更新日までの日割り料金がクレジットポイント残高に追加されます。
                            <br />
                            例えば、有料プランの契約日または更新日から、次の更新日までの日数が30日で、そのうち15日が経過した時点であるユーザーを無効化すると、そのユーザーに対する支払い済みの月額料金（税込）の50%がクレジットポイント残高に追加されます。
                          </Li>
                          <Li>
                            有料プランを利用中に追加のユーザーを招待すると、次こ更新日までの日割り料金がクレジットポイント残高からマイナスされます。クレジットポイント残高が不足している場合は、次の更新時に合わせてクレジットカードから引き落とされます。
                          </Li>
                          <Li>
                            クレジットポイント残高がプラスの状態で無料プランにダウングレードすると、その時点でクレジットポイント残高が破棄されます。
                          </Li>
                          <Li>
                            クレジットポイント残高がマイナスの状態で無料プランにダウングレードすると、その時点で不足分がクレジットカードから引き落とされます。
                          </Li>
                        </Ul>
                      </PriceDescriptionSection>
                    </PriceDescriptionBlock>
                  </>
                ) : (
                  <A onClick={() => setShowPriceDetail(true)}>▶料金詳細</A>
                )}
              </Section>
            )}
            {paymentType === 'contract' && (
              <Section>
                <A onClick={() => handleDownGrade()}>無料プランにダウングレード</A>
              </Section>
            )}
          </>
        )}
      </ContentBodyDetail>
      <BiztraModal size="medium" onClose={() => setShowPaymentModal(false)} open={showPaymentModal}>
        <BiztraModalHeader>プランのアップグレード</BiztraModalHeader>
        <BiztraModalBody>
          <ModalBody>
            <Description>
              <div>現在のユーザ数：{memberCount}人</div>
              <div>
                お支払い総額：<Price>{utils.formatPrice(totalPrice)}</Price>（税込）
              </div>
              <div>※税込、1ヶ月あたり</div>
              <div>※翌月以降は自動更新されます</div>
            </Description>

            <CreditCardInputWrap>
              <Elements stripe={stripePromise}>
                <CardSetupForm handleSucceeded={() => handleSubmitPayment()} />
              </Elements>
            </CreditCardInputWrap>
          </ModalBody>
        </BiztraModalBody>
      </BiztraModal>
      <BiztraModal size="large" onClose={() => setShowDownGradeModal(false)} open={showDownGradeModal}>
        <BiztraModalHeader>プランのダウングレード</BiztraModalHeader>
        <BiztraModalBody>
          <ModalBody>
            <Section>
              <div>{currentPlan}を無料プランに変更しますか？</div>
              <div>※変更後も、現在のプランの有効期限までは有料機能がご利用いただけます</div>
              <div>※クレジットポイント残高がプラスの場合は、ダウングレードした時点で破棄されます</div>
              <div>
                ※クレジットポイント残高がマイナスの場合は、ダウングレードした時点で不足分がクレジットカードから引き落とされます
              </div>
            </Section>
            <ButtonArea>
              <A onClick={() => setShowDownGradeModal(false)}>戻る</A>
              {downGradeSubmitting ? (
                <RightLoading />
              ) : (
                <RightButton onClick={() => handleSubmitDownGrade()}>はい</RightButton>
              )}
            </ButtonArea>
            <Error>{downGradeError}</Error>
          </ModalBody>
        </BiztraModalBody>
      </BiztraModal>
    </>
  );
});

const CreditCardInputWrap = styled(Box)`
  min-width: 550px;
  margin-top: 24px;
  padding-top: 32px;
  border-top: solid 1px ${props => props.theme.grayBorderColor};
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const PlanTitle = styled.div`
  font-weight: 300;
  font-size: 1.5em;
  line-height: 28px;
  padding-bottom: 10px;
`;

const PlanCuurent = styled.ul`
  font-size: 1.125em;
`;

const TheButton = styled(Button)`
  width: fit-content;
  border-radius: 4px;
  margin-bottom: 12px;
`;

const Table = styled.table`
  margin: 0;
  table-layout: auto;
  width: auto;
  font-size: 12px;
`;

const Th = styled.th`
  font-weight: normal;
  color: ${props => props.theme.grayTextColor};
  padding: 4px;
`;

const ThRight = styled(Th)`
  text-align: right;
`;

const Td = styled.td`
  padding: 4px;
`;

const TdRight = styled(Td)`
  text-align: right;
  min-width: 100px;
`;

const ModalBody = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

const Description = styled.div`
  margin-bottom: 20px;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
`;

const RightButton = styled(TheButton)`
  margin-left: auto;
`;

const RightLoading = styled(SimpleLoading)`
  margin-left: auto;
`;

const Price = styled.span`
  color: ${props => props.theme.redColor};
  font-weight: bold;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const PriceDescriptionBlock = styled.div`
  border: 1px dotted ${props => props.theme.grayTextColor};
  padding: 10px;
  margin-top: 5px;
`;

const PriceDescriptionSection = styled.div`
  margin-bottom: 20px;
`;

const Ul = styled.ul`
  list-style-type: disc;
  padding-left: 25px;
  margin-top: 10px;
`;

const Li = styled.li`
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 1.125em;
`;

export default Plans;
