Expense = require('./expense')
Transit = require('../transit/transit').default

module.exports = class ExpenseList
  # TODO: 未使用の為、ts化時に削除 (trip_report.coffee -> fromTrip削除後)
  @fromTrip: (trip) ->
    items = []

    if trip.hotel
      items.push(Expense.fromHotel(trip.hotel, trip.hotelCheckinDate(), dig(trip, 'search_query', 'hotel_price')))

    if trip.outword
      outword = new Transit(_.merge(trip.outword, { showFee: trip.showFee }))
      items.push(Expense.fromTransit(outword, trip.outwordDate()))

    if trip.homeword
      homeword = new Transit(_.merge(trip.homeword, { showFee: trip.showFee }))
      items.push(Expense.fromTransit(homeword, trip.homewordDate()))

    if trip.order.flight
      items.push(new Expense
        date: trip.order.flight_outword_date?.format('YYYY-MM-DD')
        type: '飛行機代'
        price: trip.order.flightTotalPrice()
        abstract: trip.order.flight
      )

    new @(items)

  constructor: (items) ->
    @items = items || [new Expense()]

  list: ->
    _.filter @items, (item) -> !item.destroyed()

  paidList: ->
    _.filter @list(), (item) -> item.paid

  unpaidList: ->
    _.filter @list(), (item) -> !item.paid

  addExpense: ->
    @items.push(new Expense())
    app.render()

  removeExpense: (expense) ->
    expense.markAsDestroy()
    app.render()

  selectedItems: ->
    _.filter @items, (item) -> item.selected

  typeSummary: ->
    @summarizeByType(@list())

  summarizeByType: (items) ->
    summary = {}
    total = 0
    for expense in items
      if expense.type && expense.type.length > 0
        summary[expense.type] ||= 0
        summary[expense.type] += expense.price
      total += expense.price
    summary['合計'] = total
    for k in _.keys(summary)
      summary[k] = formatPrice(summary[k])
    summary

  errors: ->
    _.flatten(_.map(@list(), (expense) -> expense.errors()))

  totalPrice: ->
    _.sum(_.map(@list(), (e) -> e.price))

  totalPaidPrice: ->
    _.sum(_.map(@paidList(), (e) -> e.price))

  totalUnpaidPrice: ->
    _.sum(_.map(@unpaidList(), (e) -> e.price))

  toCreateParams: ->
    _.map @items, (expense) -> expense.toCreateParams()

  toCreateExpenseApplicationParams: ->
    _.map @selectedItems(), (expense) -> id: expense.id
