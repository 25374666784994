import { Fetcher } from '@this/src/util';
import React, { useState, useEffect } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import { Box, Divider } from '@material-ui/core';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import type { CsvImportActionArgs } from '@this/src/domain/csv_import_action/csv_import_action';
import CsvImportAction from '@this/src/domain/csv_import_action/csv_import_action';
import type { YamatoShippingCostArgs } from '@this/src/domain/yamato_shipping_cost';
import YamatoShippingCost from '@this/src/domain/yamato_shipping_cost';

interface Response {
  action: CsvImportActionArgs;
  yamato_shipping_costs: YamatoShippingCostArgs[];
}

type Props = RouteComponentProps<{ id?: string }>;

const YamatoCsvImportActionDetail = observer((props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [action, setAction] = useState<CsvImportAction | null>(null);
  const [yamatoShippingCosts, setYamatoShippingCosts] = useState<YamatoShippingCost[]>([]);

  const fetchAction = async () => {
    setLoading(true);
    setLoadingError(null);
    try {
      const res = await Fetcher.get<Response>(
        `/arrangement/yamato_csv_import_actions/${props.match.params.id}.json`
      );
      setAction(new CsvImportAction(res.action));
      const costs = res.yamato_shipping_costs.map(args => new YamatoShippingCost(args));
      setYamatoShippingCosts(costs);
    } catch {
      setLoadingError('エラーが発生しました。');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAction();
  }, []);

  return (
    <Wrapper>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box>
            <Link to="/arrangement/yamato_csv_import_actions">一覧へ戻る</Link>
          </Box>
          <TitleWrapper>
            <Box>
              <Title>{action?.filename}</Title>
              <p style={{ color: '#aaa' }}>{action?.createdAt.format('YYYY-MM-DD HH:mm')}</p>
            </Box>
            <p style={{ color: '#aaa' }}>合計：{yamatoShippingCosts.length}件</p>
          </TitleWrapper>
          <Divider />
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>店所コード</TableCell>
                <TableCell>お客様コード</TableCell>
                <TableCell>分類コード</TableCell>
                <TableCell>受付日</TableCell>
                <TableCell>原票No.</TableCell>
                <TableCell>商品区分1</TableCell>
                <TableCell>商品区分2</TableCell>
                <TableCell>サイズ</TableCell>
                <TableCell>個数</TableCell>
                <TableCell>扱店都道府県</TableCell>
                <TableCell>扱店市区町村</TableCell>
                <TableCell>運賃合計</TableCell>
                <TableCell>運賃</TableCell>
                <TableCell>立替金</TableCell>
                <TableCell>保険料</TableCell>
                <TableCell>消費税</TableCell>
                <TableCell>軽減税率対象</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {yamatoShippingCosts.map(cost => (
                <TableRow key={cost.id} hover>
                  <TableCell>{cost.shopCode}</TableCell>
                  <TableCell>{cost.customerCode}</TableCell>
                  <TableCell>{cost.categoryCode}</TableCell>
                  <TableCell>{cost.receptionDate}</TableCell>
                  <TableCell>{cost.registrationNo}</TableCell>
                  <TableCell>{cost.itemCategory1}</TableCell>
                  <TableCell>{cost.itemCategory2}</TableCell>
                  <TableCell>{cost.size}</TableCell>
                  <TableCell>{cost.quantity}</TableCell>
                  <TableCell>{cost.shopPrefecture}</TableCell>
                  <TableCell>{cost.shopCity}</TableCell>
                  <TableCell>{cost.fareTotal}</TableCell>
                  <TableCell>{cost.fare}</TableCell>
                  <TableCell>{cost.disbursement}</TableCell>
                  <TableCell>{cost.preminum}</TableCell>
                  <TableCell>{cost.consumptionTax}</TableCell>
                  <TableCell>{cost.reducedTaxEligibility}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      {loadingError && <Box color="red">{loadingError}</Box>}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 20px;
  font-size: 12px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 0;
`;

const Title = styled.p`
  font-size: large;
  font-weight: bold;
`;

export default YamatoCsvImportActionDetail;
