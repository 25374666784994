import { Fetcher } from '@this/src/util';
/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import moment from 'moment';
import type { RouteComponentProps } from 'react-router-dom';
import type User from '@this/domain/user/user';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { ReportJson } from '@this/domain/expenses/report';
import { Report } from '@this/domain/expenses/report';
import SegmentedControl from '@this/components/expenses/segmented_control/segmented_control';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import type { CutoffDateJson } from '@this/domain/expenses/cutoff_date';
import type { ApprovalStatus } from '@this/domain/expenses/approval';
import ReportSummary from '@this/components/expenses/approvals/report_summary';
import CsvDownload from '@this/components/expenses/approvals/csv_download';
import ZenginDownload from '@this/components/expenses/approvals/zengin_download';
import Reject from '@this/components/expenses/approvals/reject';
import { Checkbox } from '@this/components/expenses/ui/inputs/checkbox';
import { ByMedia } from '@this/shared/ui/other/by_media';
import SearchForm from './search_form';
import ApprovalsTable from './approvals_table';
import ExpensesMain from '../main/main';

interface IndexResponse {
  reports: ReportJson[];
  appliers: { user_id: number; name: string }[];
  cutoff_dates: CutoffDateJson[];
  zengin_available: boolean;
  is_admin: boolean;
  is_multistage_approval_enabled: boolean;
}

interface Props extends RouteComponentProps<{ id?: string }> {
  user: User | null | undefined;
}

interface State {
  filteredReports: Report[];
  error?: string;
  loading: boolean;
  submitting: boolean;
  selectedStatus: ApprovalStatus;
  allSelected: boolean;
  statuses: { [key: string]: string };
  zenginAvailable: boolean;
  showZenginModal: boolean;
  isAdmin: boolean;
  isMultistageApprovalEnabled: boolean;
  showRejectModal: boolean;
  showDownloadCsvModal: boolean;
  // 検索関連
  showSearchModal: boolean;
  appliedAtFrom: string;
  appliedAtTo: string;
  appliers: { userId: number; name: string }[];
  selectedApplierUser: { userId: number; name: string } | undefined;
  priceFrom: string;
  priceTo: string;
}

class Approvals extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const statuses = (): { [key: string]: string } => {
      if (props.user?.is_expenses_multistage_approval || props.user?.organization?.expenses_multistage_approval) {
        return props.user && props.user.isOrganizationAdmin
          ? { applied: '承認待ち', approved: '承認済み', rejected: '差し戻し', completed: '完了' }
          : { applied: '承認待ち', approved: '承認済み', rejected: '差し戻し' };
      }
      return props.user && props.user.isOrganizationAdmin
        ? { applied: '承認待ち', approved: '承認済み', rejected: '差し戻し', completed: '完了' }
        : { applied: '承認待ち', approved: '経理承認待ち', rejected: '差し戻し', completed: '完了' };
    };

    this.state = {
      filteredReports: [],
      loading: false,
      submitting: false,
      selectedStatus: 'applied',
      allSelected: false,
      statuses: statuses(),
      zenginAvailable: false,
      showZenginModal: false,
      isAdmin: false,
      isMultistageApprovalEnabled: false,
      showRejectModal: false,
      showDownloadCsvModal: false,
      showSearchModal: false,
      appliedAtFrom: moment().subtract(2, 'months').format(this.getFormat()),
      appliedAtTo: '',
      appliers: [],
      selectedApplierUser: undefined,
      priceFrom: '',
      priceTo: ''
    };
  }

  componentDidMount() {
    this.searchReports();
    mixpanel.track('[Biztra][view] /approvals');
  }

  setSearchParams = async (
    appliedAtFrom: string,
    appliedAtTo: string,
    priceFrom: string,
    priceTo: string,
    selectedApplierUserId?: number
  ) => {
    const selectedApplierUser = this.state.appliers.find(u => u?.userId === selectedApplierUserId);
    await this.setState({
      appliedAtFrom,
      appliedAtTo,
      selectedApplierUser,
      priceFrom,
      priceTo,
      showSearchModal: false
    });
    await this.searchReports();
  };

  private getFormat = (): string => 'YYYY-MM-DD';

  private async searchReports() {
    const { appliedAtFrom, appliedAtTo, selectedApplierUser, priceFrom, priceTo } = this.state;
    this.setState({ loading: true });
    try {
      const params = {
        applied_at_from: appliedAtFrom,
        applied_at_to: appliedAtTo,
        applier: selectedApplierUser?.userId,
        price_from: priceFrom,
        price_to: priceTo
      };
      const response: IndexResponse = await Fetcher.get<IndexResponse>('/biztra/approvals.json', params);
      const reports = response.reports.map(report => new Report(report));
      const appliers = response.appliers.map(a => ({ userId: a.user_id, name: a.name }));

      this.setState({
        loading: false,
        filteredReports: reports,
        zenginAvailable: response.zengin_available,
        isAdmin: response.is_admin,
        isMultistageApprovalEnabled: response.is_multistage_approval_enabled,
        appliers
      });
    } catch {
      this.setState({
        loading: false,
        error: '通信エラーが発生しました。時間をおいて再度お試しください。'
      });
    }
  }

  private async bulkApprove() {
    this.setState({ submitting: true });
    try {
      const params = { ids: this.selectedReports().map(r => r.id) };
      await Fetcher.put('/biztra/approvals/bulk_approve.json', params);
      this.setState(
        {
          submitting: false,
          allSelected: false
        },
        () => {
          this.searchReports();
        }
      );
      mixpanel.track('[Biztra] bulk approved');
    } catch {
      this.setState({
        submitting: false,
        error: '通信エラーが発生しました。時間をおいて再度お試しください。'
      });
    }
  }

  private async bulkReject(reason: string) {
    this.setState({ submitting: true });
    try {
      const params = { ids: this.selectedReports().map(r => r.id), reject_reason: reason };
      await Fetcher.put('/biztra/approvals/bulk_reject.json', params);
      this.setState(
        {
          submitting: false,
          allSelected: false
        },
        () => {
          this.searchReports();
        }
      );
      mixpanel.track('[Biztra] bulk rejected');
    } catch {
      this.setState({
        submitting: false,
        error: '通信エラーが発生しました。時間をおいて再度お試しください。'
      });
    } finally {
      this.setState({ submitting: false, showRejectModal: false });
    }
  }

  private async bulkComplete() {
    this.setState({ submitting: true });
    try {
      const params = { ids: this.completeTargetReports().map(r => r.id) };
      await Fetcher.put('/biztra/approvals/bulk_complete.json', params);
      this.setState(
        {
          submitting: false,
          allSelected: false
        },
        () => {
          this.searchReports();
        }
      );
      mixpanel.track('[Biztra] bulk approved');
    } catch {
      this.setState({
        submitting: false,
        error: '通信エラーが発生しました。時間をおいて再度お試しください。'
      });
    }
  }

  private handleChangeSelectedStatus(index: number) {
    const keys = Object.keys(this.state.statuses);
    const value = keys[index];
    this.setState({
      selectedStatus: value as 'applied' | 'rejected' | 'approved' | 'completed',
      allSelected: false
    });
  }

  private handleToggleAllSelected() {
    const { user } = this.props;
    if (!user) return;

    if (this.state.allSelected) {
      this.filteredReports().forEach(r => {
        r.selected = false;
      });
    } else {
      this.filteredReports()
        .filter(r => r.hasNextStepAction())
        .forEach(r => {
          r.selected = true;
        });
    }
    this.setState({ allSelected: !this.state.allSelected });
  }

  private handleClickApprove = () => {
    this.bulkApprove();
  };

  private handleClickReject = async (reason: string) => {
    await this.bulkReject(reason);
  };

  private handleClickComplete = () => {
    this.bulkComplete();
  };

  private handleDownloadZenginData = () => {
    if (this.state.zenginAvailable) {
      const params = this.selectedReports()
        .map(r => `report_ids[]=${r.id}`)
        .join('&');
      this.props.history.push(`/biztra/approvals/zengin_data/new?${params}`);
      return;
    }
    this.setState({ showZenginModal: true });
  };

  private filteredReports(): Report[] {
    return this.state.filteredReports.filter(
      report => report.filteringStatusFor(this.props.user!) === this.state.selectedStatus
    );
  }

  private selectedReports(): Report[] {
    return this.filteredReports().filter(report => report.selected);
  }

  private completeTargetReports(): Report[] {
    if (this.state.selectedStatus !== 'approved') return [];
    return this.selectedReports();
  }

  private statusSegmentedControl() {
    let statuses: { [key: string]: string } = {};
    let keys: any[];
    let index: number;
    let labels: any[];
    if (this.props.user && this.props.user.isOrganizationAdmin) {
      statuses = this.state.statuses;
      keys = Object.keys(statuses);
      index = keys.indexOf(this.state.selectedStatus);
      labels = keys.map(key => statuses[key as 'applied' | 'approved' | 'rejected' | 'completed']);
    } else {
      statuses = _.pickBy(this.state.statuses, (_, key) => {
        return key !== 'completed';
      });
      keys = Object.keys(statuses);
      index = keys.indexOf(this.state.selectedStatus);
      labels = keys.map(key => statuses[key as 'applied' | 'approved' | 'rejected']);
    }
    return (
      <SegmentedControl
        name="status"
        labels={labels}
        value={index}
        onChange={i => this.handleChangeSelectedStatus(i)}
      />
    );
  }

  private reportsInfo() {
    return (
      <ReportSummary
        totalCount={this.selectedReports().length}
        totalPrice={utils.formatPriceWithMark(this.totalPrice())}
      />
    );
  }

  private allSelectCheckbox() {
    const { user } = this.props;
    if (!user) return <></>;

    const { filteredReports, allSelected, selectedStatus } = this.state;
    const hasShowCheckbox = filteredReports
      .filter(v => v.approvalStatus() === selectedStatus)
      .some(v => v.hasNextStepAction());
    return (
      hasShowCheckbox && (
        <CheckBoxLabel>
          <Checkbox checked={allSelected} onChange={_e => this.handleToggleAllSelected()} />
          {allSelected ? 'すべて解除' : 'すべて選択'}
        </CheckBoxLabel>
      )
    );
  }

  private csvDownload() {
    const { submitting } = this.state;
    const disabled = submitting || this.selectedReports().length === 0;
    return (
      <DownloadButton
        disabled={disabled}
        onClick={e => {
          e.currentTarget.blur();
          this.setState({ showDownloadCsvModal: true });
        }}
      >
        CSVデータを出力
      </DownloadButton>
    );
  }

  private zenginDownload() {
    const disabled = this.selectedReports().length === 0;
    return (
      <DownloadButton
        disabled={disabled}
        onClick={e => {
          e.currentTarget.blur();
          this.handleDownloadZenginData();
        }}
      >
        全銀データを出力
      </DownloadButton>
    );
  }

  private utils() {
    const { submitting, selectedStatus } = this.state;
    const disabled = submitting || this.selectedReports().length === 0;
    return (
      <UtilsWrapper className="expenses-content-main__button-area">
        {selectedStatus === 'applied' && (
          <UtilsButton
            type="button"
            onClick={e => {
              e.currentTarget.blur();
              this.setState({ showRejectModal: true });
            }}
            disabled={disabled}
          >
            差戻
          </UtilsButton>
        )}
        {selectedStatus === 'approved' && (
          <UtilsButton type="button" onClick={this.handleClickComplete} disabled={disabled}>
            完了
          </UtilsButton>
        )}
        {selectedStatus === 'applied' && (
          <UtilsPrimaryButton type="button" onClick={this.handleClickApprove} disabled={disabled}>
            承認
          </UtilsPrimaryButton>
        )}
        {this.reportsInfo()}
      </UtilsWrapper>
    );
  }

  private utilsForMultistageApproval() {
    const { submitting, isAdmin, selectedStatus } = this.state;
    const disabled = submitting || this.selectedReports().length === 0;
    return (
      <UtilsWrapper className="expenses-content-main__button-area">
        {selectedStatus === 'applied' && (
          <>
            <UtilsButton
              type="button"
              onClick={e => {
                e.currentTarget.blur();
                this.setState({ showRejectModal: true });
              }}
              disabled={disabled}
            >
              差戻
            </UtilsButton>
            <UtilsPrimaryButton type="button" onClick={this.handleClickApprove} disabled={disabled}>
              承認
            </UtilsPrimaryButton>
          </>
        )}
        {isAdmin && selectedStatus === 'approved' && (
          <UtilsButton type="button" onClick={this.handleClickComplete} disabled={disabled}>
            完了
          </UtilsButton>
        )}
        {this.reportsInfo()}
      </UtilsWrapper>
    );
  }

  filteredAppliedAt() {
    return (
      <FilteredWrapper>
        <FilteredTitle>期間</FilteredTitle>
        <span>：</span>
        <span>{this.state.appliedAtFrom}</span>
        <FromTo>～</FromTo>
        <span>{this.state.appliedAtTo}</span>
      </FilteredWrapper>
    );
  }

  filteredApplier() {
    return (
      <FilteredWrapper>
        <FilteredTitle>申請者</FilteredTitle>
        <span>：</span>
        {this.state.selectedApplierUser && <span>{this.state.selectedApplierUser.name}</span>}
      </FilteredWrapper>
    );
  }

  filteredPrice() {
    return (
      <FilteredWrapper>
        <FilteredTitle>金額</FilteredTitle>
        <span>：</span>
        {this.state.priceFrom && <span>{this.state.priceFrom}円</span>}
        <FromTo>～</FromTo>
        {this.state.priceTo && <span>{this.state.priceTo}円</span>}
      </FilteredWrapper>
    );
  }

  private totalPrice(): number {
    const prices = this.selectedReports().map(r => r.totalPrice());
    return prices.length > 0 ? prices.reduce((p, c) => p + c) : 0;
  }

  render() {
    const {
      isAdmin,
      isMultistageApprovalEnabled,
      loading,
      error,
      selectedStatus,
      showZenginModal,
      showRejectModal,
      showDownloadCsvModal,
      showSearchModal,
      appliedAtFrom,
      appliedAtTo,
      appliers,
      selectedApplierUser,
      priceFrom,
      priceTo
    } = this.state;
    return (
      <ExpensesMain
        title="承認一覧"
        utils={isMultistageApprovalEnabled ? this.utilsForMultistageApproval() : this.utils()}
      >
        {loading ? (
          <SimpleLoading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <ApprovalsWrapper>
            <HeaderWrapper>
              <HeaderLeftWrapper>
                {this.statusSegmentedControl()}
                {isMultistageApprovalEnabled && selectedStatus === 'approved' ? (
                  <DownloadButtonWrapper>
                    {this.zenginDownload()}
                    {this.csvDownload()}
                  </DownloadButtonWrapper>
                ) : (
                  (selectedStatus === 'approved' && this.props.user && this.props.user.isOrganizationAdmin) ||
                  (selectedStatus === 'completed' && (
                    <DownloadButtonWrapper>
                      {this.zenginDownload()}
                      {this.csvDownload()}
                    </DownloadButtonWrapper>
                  ))
                )}
              </HeaderLeftWrapper>
            </HeaderWrapper>
            <ByMedia>
              {matches =>
                matches.sm ? (
                  <CheckBoxAndBtnAndFilters>
                    <BtnAndFilters>
                      <OpenSearchModalButton
                        type="button"
                        onClick={e => {
                          e.currentTarget.blur();
                          this.setState({ showSearchModal: true });
                        }}
                      >
                        条件検索
                      </OpenSearchModalButton>
                      <FiltersWrapper>
                        {(!!appliedAtFrom || !!appliedAtTo) && this.filteredAppliedAt()}
                        {!!selectedApplierUser && this.filteredApplier()}
                        {(!!priceFrom || !!priceTo) && this.filteredPrice()}
                      </FiltersWrapper>
                    </BtnAndFilters>
                    {selectedStatus !== 'rejected' && this.allSelectCheckbox()}
                  </CheckBoxAndBtnAndFilters>
                ) : (
                  <CheckBoxAndBtnAndFilters>
                    {selectedStatus !== 'rejected' && this.allSelectCheckbox()}
                    <OpenSearchModalButton
                      type="button"
                      onClick={e => {
                        e.currentTarget.blur();
                        this.setState({ showSearchModal: true });
                      }}
                    >
                      検索条件を指定
                    </OpenSearchModalButton>
                    <FiltersWrapper>
                      {(!!appliedAtFrom || !!appliedAtTo) && this.filteredAppliedAt()}
                      {!!selectedApplierUser && this.filteredApplier()}
                      {(!!priceFrom || !!priceTo) && this.filteredPrice()}
                    </FiltersWrapper>
                  </CheckBoxAndBtnAndFilters>
                )
              }
            </ByMedia>
            {this.filteredReports().length > 0 && this.props.user && (
              <ApprovalsTable
                reports={this.filteredReports()}
                user={this.props.user}
                showCheckbox={selectedStatus !== 'rejected'}
                inRejected={selectedStatus === 'rejected'}
              />
            )}
          </ApprovalsWrapper>
        )}
        <Modal
          open={showRejectModal}
          onClose={() => this.setState({ showRejectModal: false })}
          PaperProps={{ style: { background: 'white' } }}
        >
          <Reject
            onClose={() => this.setState({ showRejectModal: false })}
            onSubmit={(reason: string) => this.handleClickReject(reason)}
          />
        </Modal>
        <Modal
          open={showZenginModal}
          onClose={() => this.setState({ showZenginModal: false })}
          PaperProps={{ style: { background: 'white' } }}
        >
          <ZenginDownload onClose={() => this.setState({ showZenginModal: false })} isAdmin={isAdmin} />
        </Modal>
        <Modal
          size="medium"
          onClose={() => {
            this.setState({ showSearchModal: false });
          }}
          open={showSearchModal}
          PaperProps={{ style: { background: 'white' } }}
        >
          <SearchForm
            onSetSearchParams={this.setSearchParams}
            onCancel={() => this.setState({ showSearchModal: false })}
            appliers={appliers}
            periodFrom={appliedAtFrom}
            periodTo={appliedAtTo}
            selectedApplierUserId={selectedApplierUser?.userId}
            priceFrom={priceFrom}
            priceTo={priceTo}
          />
        </Modal>
        <Modal
          size="medium"
          onClose={() => {
            this.setState({ showDownloadCsvModal: false });
          }}
          open={showDownloadCsvModal}
          PaperProps={{ style: { background: 'white' } }}
        >
          <CsvDownload
            onClose={() => this.setState({ showDownloadCsvModal: false })}
            selectedReportIds={this.selectedReports()
              .filter(r => r.id)
              .map(r => r.id!)}
          />
        </Modal>
      </ExpensesMain>
    );
  }
}

const outlineBtnColor = '#FFFFFF';

const baseColor = '#927230';

const primaryBtnColor = '#1d7c2d';

const UtilsWrapper = styled.div`
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
  }
`;

const UtilsButton = styled.button`
  background: ${baseColor};
  &&:focus,
  &&:hover {
    background: ${baseColor};
  }
`;

const UtilsPrimaryButton = styled.button`
  background: ${primaryBtnColor};
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
  }
`;

const CheckBoxLabel = styled.label`
  color: ${baseColor};
  margin-right: 10px !important;
  @media screen and (max-width: 767px) {
    & label {
      margin-left: unset;
    }
  }
`;

const OutlineButton = styled.button.attrs({ type: 'button' })`
  background: ${outlineBtnColor};
  color: ${baseColor};
  border: 1px solid ${baseColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${outlineBtnColor};
    color: ${baseColor};
  }
`;

const ApprovalsWrapper = styled.div`
  width: 100%;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
`;

const HeaderLeftWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;
  @media screen and (max-width: 767px) {
    row-gap: 24px;
  }
`;

const DownloadButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  & button:first-child {
    margin-right: calc((((100vw - (100vw * 0.02) * 2) - 733.743px) / 2) * 0.037);
  }
  @media screen and (max-width: 767px) {
    margin-left: unset;
    & button:first-child {
      margin-right: unset;
    }
  }
`;

const BtnAndFilters = styled.div`
  display: flex;
  align-items: center;
`;

const OpenSearchModalButton = styled(OutlineButton)`
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
`;

const DownloadButton = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: 1px solid ${baseColor};
  color: ${baseColor};
  border-radius: 6px;
  font-size: 14px;
  line-height: 1;
  padding: 9px 20px 8px;
  &&:hover,
  &&:focus {
    background: transparent;
    color: ${baseColor};
  }
`;

const CheckBoxAndBtnAndFilters = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 24px;
  & label {
    margin-bottom: 0;
    width: auto;
    margin-left: -4px;
    margin-right: unset;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
    margin-bottom: 12px;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    font-size: 0.875rem;
    color: ${baseColor};
    font-weight: bold;
  }
`;

const FilteredWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const FilteredTitle = styled.span`
  color: ${baseColor};
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 12px;
  @media screen and (max-width: 767px) {
    font-size: 0.875rem;
  }
`;

const FromTo = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`;

export default Approvals;
