import { Fetcher, HTTPError } from '@this/src/util';
import React from 'react';

import type { match } from 'react-router-dom';
import {
  OrganizationBody,
  OrganizationHeader,
  OrganizationHeaderTitle
} from '@this/components/expenses/organization/organization.style';
import { styled } from '@this/constants/themes';
import type { BulkActionStatusResponse } from '@this/components/organization/types.ts';
import { BackLink } from '@this/components/expenses/main/main';
import BulkUpsertActionUsers from './bulk_upsert_action_users';
import BulkUpsertActionDepartments from './bulk_upsert_action_departments';
import BulkUpdateActionApprovers from './bulk_update_action_approvers';
import BulkUpsertActionAccountTypes from './bulk_upsert_action_account_types';
import BulkUpsertActionExpensesTypes from './bulk_upsert_action_expenses_types';

const BulkActionStatus = require('@this/domain/bulk_action/bulk_action_status');

interface Props {
  match: match<{ id: string }>;
  expenses?: boolean;
}
interface State {
  status?: any;
  loading: boolean;
  error?: string;
}
export default class BulkactionStatus extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { loading: false };
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const { bulk_action_status } = await Fetcher.get<BulkActionStatusResponse>(
        `./${this.props.match.params.id}`
      );
      this.setState({
        loading: false,
        status: new BulkActionStatus(bulk_action_status)
      });
    } catch (error) {
      this.setState({
        error:
          error instanceof HTTPError && error.response?.status === 400
            ? error.response.data.errors
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
      });
    }
  }

  render() {
    const { status, loading, error } = this.state;
    const { expenses } = this.props;
    return (
      <>
        <Header expenses={expenses}>
          <HeaderTitle expenses={expenses}>一括処理詳細</HeaderTitle>
        </Header>
        <div>
          <BackLink to={`${expenses ? '/biztra' : ''}/organization/bulk_action_statuses`}>
            &lt; 一覧に戻る
          </BackLink>
        </div>
        <OrganizationBody>
          {!status || loading ? (
            <img className="select__loading" src="/images/loading.gif" width="50" height="50" />
          ) : error ? (
            <p>{error}</p>
          ) : status.isUpdateApprovers() ? (
            <BulkUpdateActionApprovers status={status} />
          ) : status.isUpsertDepartments() ? (
            <BulkUpsertActionDepartments status={status} />
          ) : status.isUpsertAccountTypes() ? (
            <BulkUpsertActionAccountTypes status={status} />
          ) : status.isUpsertExpensesTypes() ? (
            <BulkUpsertActionExpensesTypes status={status} />
          ) : status.isUpsertUsers() ? (
            <BulkUpsertActionUsers status={status} expenses={expenses} />
          ) : (
            ''
          )}
        </OrganizationBody>
      </>
    );
  }
}

const Header = styled(OrganizationHeader)`
  padding: ${({ expenses }: { expenses?: boolean }) => (expenses ? '0' : '10px')};
`;

const HeaderTitle = styled(OrganizationHeaderTitle)`
  color: ${({ expenses }: { expenses?: boolean }) => expenses && '#927230'};
  font-size: ${({ expenses }: { expenses?: boolean }) => expenses && '24px'};
  margin: ${({ expenses }: { expenses?: boolean }) => expenses && '0 0 12px 0'};
`;
