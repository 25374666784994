import React, { useCallback, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/src/components/shared/ui/feedbacks/modal';
import { styled } from '@this/src/components/constants/themes';
import { ButtonBase } from '@this/shared/atoms/button';
import type { TripDuplicationInterface } from '../../reserve_confirm';
import TripDuplicationSection from '../input_customer_information/trip_duplication_section/trip_duplication_section';

interface Props {
  open: boolean;
  tripDuplications: TripDuplicationInterface[];
  onClose: () => void;
  onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const TripDuplicationConfirmModal: React.FC<Props> = ({ open, tripDuplications, onClose, onSubmit }) => {
  const [agree, setAgree] = useState(false);

  const handleChange = useCallback(() => {
    setAgree(agree => !agree);
  }, []);

  const handleClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onSubmit(e);
      onClose();
    },
    [onClose, onSubmit]
  );

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>
        <HeaderContent>旅程の重複の可能性がございます</HeaderContent>
      </ModalHeader>
      <ModalBody>
        <Content>
          <p>以下の旅程を確認し、問題なければ手配を依頼してください。</p>
          <label>
            <input type="checkbox" className="trip_duplication_agree" checked={agree} onChange={handleChange} />
            旅程の重複を確認しました
          </label>
          <div>
            {tripDuplications.map((tripDuplication, index) => (
              <TripDuplicationSection key={index} tripDuplication={tripDuplication} />
            ))}
          </div>
        </Content>
      </ModalBody>
      <ModalFooter>
        <ConfirmButton type="button" role="confirm" onClick={handleClick} disabled={!agree}>
          手配を依頼する
        </ConfirmButton>
      </ModalFooter>
    </Modal>
  );
};

const HeaderContent = styled.div`
  padding: 5px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
`;

const ConfirmButton = styled.button`
  ${ButtonBase}
  margin-left: 15px;
  padding: 0.75em 1em;
  line-height: 1;
`;
