import { Fetcher, objectToFormData } from '@this/src/util';
import React from 'react';
import { reportError } from '@this/lib/bugsnag';
import { TextField } from '../../shared/form_elements/form_elements';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

import User from '../../../domain/user/user';
import type UserJson from '../../../domain/user/user_json';

interface Props {}

interface UserResponse {
  user: UserJson;
}

interface State {
  email: string;
  invitationToken: string;
  password: string;
  lastName: string;
  firstName: string;
  tel: string;
  submitting: boolean;
  succeed: boolean;
  isPrivacyChecked: boolean;
  errors: {
    email?: string;
    password?: string;
    last_name?: string;
    first_name?: string;
    tel?: string;
  };
  submitError: string | null;
}

class ExpensesUsersInvitationEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      invitationToken: utils.getParam('invitation_token'),
      password: '',
      lastName: '',
      firstName: '',
      tel: '',
      submitting: false,
      succeed: false,
      isPrivacyChecked: false,
      errors: {},
      submitError: null
    };
  }

  componentDidMount() {
    this.fetchUser();
  }

  async fetchUser() {
    const params = { invitation_token: this.state.invitationToken };
    const res = await Fetcher.get<UserResponse>('/biztra/users/invitation/accept', params);
    const user = new User(res.user);
    this.setState({
      email: user.email,
      lastName: user.lastName || '',
      firstName: user.firstName || '',
      tel: user.tel || ''
    });
  }

  handleEmailChange(email: string) {
    this.setState({ email });
  }

  handlePasswordChange(password: string) {
    this.setState({ password });
  }

  handleLastNameChange(lastName: string) {
    this.setState({ lastName });
  }

  handleFirstNameChange(firstName: string) {
    this.setState({ firstName });
  }

  handleTelChange(tel: string) {
    this.setState({ tel });
  }

  changePrivacyCheck() {
    this.setState({ isPrivacyChecked: !this.state.isPrivacyChecked });
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      this.setState({ submitting: true, errors: {}, submitError: null });
      const params = {
        'user[last_name]': this.state.lastName,
        'user[first_name]': this.state.firstName,
        'user[tel]': this.state.tel,
        'user[password]': this.state.password,
        'user[invitation_token]': this.state.invitationToken
      };
      await Fetcher.put('/biztra/users/invitation.json', objectToFormData(params));
      this.setState({ submitting: false, succeed: true });
    } catch (e) {
      if (e.response.status === 400) {
        this.setState({ submitting: false, errors: e.response.data.errors });
      } else {
        this.setState({
          submitting: false,
          submitError: '通信エラーが発生しました。時間をおいて再度お試しください。'
        });
      }
    }
  }

  render() {
    try {
      const {
        email,
        password,
        lastName,
        firstName,
        tel,
        submitting,
        succeed,
        isPrivacyChecked,
        errors,
        submitError
      } = this.state;
      const classBase = 'expenses-users-signup';
      return (
        <div className={classBase}>
          <div className={`${classBase}__content`}>
            {succeed ? (
              <div className="gray">
                登録が完了しました。
                <br />
                <a href="/biztra">トップページへ</a>
              </div>
            ) : (
              <form onSubmit={e => this.handleSubmit(e)}>
                <div className="expenses-users-invitation__email-field">
                  <label className="user-edit__left-label">メールアドレス</label>
                  <p>{email}</p>
                </div>
                <div className="flex">
                  <TextField
                    label="姓（必須）"
                    value={lastName}
                    example="例：山田"
                    error={errors.last_name}
                    className={`${classBase}__flex-field`}
                    onChange={value => this.handleLastNameChange(value)}
                  />
                  <TextField
                    label="名（必須）"
                    value={firstName}
                    example="例：一郎"
                    error={errors.first_name}
                    className={`${classBase}__flex-field`}
                    onChange={value => this.handleFirstNameChange(value)}
                  />
                </div>
                <TextField
                  label="パスワード（必須）"
                  value={password}
                  example=""
                  type="password"
                  error={errors.password}
                  onChange={value => this.handlePasswordChange(value)}
                />
                <TextField
                  label="電話番号（任意）"
                  value={tel}
                  example="例：03-1234-5678"
                  error={errors.tel}
                  onChange={value => this.handleTelChange(value)}
                />
                <p className={`${classBase}__terms-link`}>
                  <input type="checkbox" onClick={() => this.changePrivacyCheck()} />
                  <a href="http://biztra.cloud/etc.html" target="_blank" rel="noreferrer noopener">
                    利用規約・個人情報の取扱いについて
                  </a>
                  に同意の上、ご登録ください
                </p>
                {submitting ? (
                  <SimpleLoading />
                ) : (
                  <input type="submit" value="登録" disabled={!isPrivacyChecked} />
                )}
                {submitError && <div className="error">{submitError}</div>}
              </form>
            )}
          </div>
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default ExpensesUsersInvitationEdit;
