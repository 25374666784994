import React, { useEffect } from 'react';
import { toDomesticTel } from '@this/src/util';
import CancelLimitForm from '@this/components/arrangement/shared/price_change_form/price_change_form_item/cancel_limit_form/cancel_limit_form';
import type HotelElement from '../../../../../../domain/hotel_element';
import DatetimePicker from '../../../../../shared/datetime_picker/datetime_picker';

interface Props {
  element: HotelElement;
  providerOptions: { [key: string]: string };
  classNameForModifiedField: (...path: (string | number)[]) => string;
  handleIsForeignChange: (element: HotelElement, address: string) => void;
  handleIsForeignLoad: (element: HotelElement) => void;
}

const HotelElementForm = ({
  element,
  providerOptions,
  classNameForModifiedField,
  handleIsForeignChange,
  handleIsForeignLoad
}: Props) => {
  const numArray: number[] = [];
  for (let i = 1; i < 10; i += 1) {
    numArray.push(i);
  }

  useEffect(() => {
    handleIsForeignLoad(element);
  }, []);

  return (
    <>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('provider')}`}>
          提供元
        </label>
        <select value={element.provider} onChange={e => element.handleHotelProviderChange(e.target.value)}>
          {Object.keys(providerOptions).map(key => (
            <option key={key} value={key}>
              {providerOptions[key]}
            </option>
          ))}
          {element.initialData.provider === 'mynavi' && <option value="mynavi">マイナビ</option>}
        </select>
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('roomNum')}`}>
          部屋数
        </label>
        <select value={element.roomNum} onChange={e => element.handleRoomNumChange(e.target.value)}>
          {numArray.map(i => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </select>
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('name')}`}>
          ホテル名
          <span className="red">（必須）</span>
        </label>
        <input type="text" value={element.name} onChange={e => element.handleNameChange(e.target.value)} />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('address')}`}>
          住所
        </label>
        <input
          type="text"
          value={element.address}
          onChange={e => element.handleAddressChange(e.target.value)}
          onBlur={e => handleIsForeignChange(element, e.target.value)}
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('isForeign')}`}>
          海外 / 国内
        </label>
        <select
          value={element.isForeign ? 'true' : 'false'}
          onChange={e => element.handleIsForeignChange(e.target.value === 'true')}
        >
          <option value="false">国内</option>
          <option value="true">海外</option>
        </select>
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('tel')}`}>
          電話番号
        </label>
        <input
          type="text"
          value={toDomesticTel(element.tel)}
          onChange={e => element.handleTelChange(e.target.value)}
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('planName')}`}>
          宿泊プラン
        </label>
        <input type="text" value={element.planName} onChange={e => element.handlePlanNameChange(e.target.value)} />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('roomType')}`}>
          部屋タイプ
        </label>
        <input type="text" value={element.roomType} onChange={e => element.handleRoomTypeChange(e.target.value)} />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('bedType')}`}>
          ベッドタイプ
        </label>
        <input type="text" value={element.bedType} onChange={e => element.handleBedTypeChange(e.target.value)} />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('breakfast')}`}>
          朝食
        </label>
        <select value={element.breakfast} onChange={e => element.handleBreakfastChange(e.target.value)}>
          <option value="true">有り</option>
          <option value="false">無し</option>
          <option value="null">問い合わせ</option>
        </select>
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('refundable')}`}>
          返金
        </label>
        <select value={element.refundable} onChange={e => element.handleRefundableChange(e.target.value)}>
          <option value="true">可</option>
          <option value="false">不可</option>
          <option value="null">問い合わせ</option>
        </select>
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('checkinDate')}`}>
          チェックイン
        </label>
        <DatetimePicker
          value={element.checkinDate}
          onChange={time => element.handleCheckinDateChange(time)}
          showTime={false}
          disabledDays={0}
          showPast
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('checkoutDate')}`}>
          チェックアウト
        </label>
        <DatetimePicker
          value={element.checkoutDate}
          onChange={time => element.handleCheckoutDateChange(time)}
          showTime={false}
          disabledDays={0}
          showPast
        />
      </div>
      <CancelLimitForm
        hotelElement={element}
        checkinDate={element.checkinDate}
        classNameForModifiedField={classNameForModifiedField}
      />
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('guestLastName')}`}>
          宿泊代表者（姓）
        </label>
        <input
          type="text"
          value={element.guestLastName}
          onChange={e => element.handleGuestLastNameChange(e.target.value)}
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label
          className={`virtual-counte-price-change-form__label ${classNameForModifiedField('guestFirstName')}`}
        >
          宿泊代表者（名）
        </label>
        <input
          type="text"
          value={element.guestFirstName}
          onChange={e => element.handleGuestFirstNameChange(e.target.value)}
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('guestTel')}`}>
          宿泊代表者電話番号
        </label>
        <input
          type="text"
          value={toDomesticTel(element.guestTel)}
          onChange={e => element.handleGuestTelChange(e.target.value)}
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label
          className={`virtual-counte-price-change-form__label ${classNameForModifiedField(
            'hotelReservationNumber'
          )}`}
        >
          ホテル予約番号
        </label>
        <input
          type="text"
          value={element.hotelReservationNumber}
          onChange={e => element.handleHotelReservationNumberChange(e.target.value)}
        />
      </div>
    </>
  );
};

export default HotelElementForm;
