Trip = require('./trip')
User = require('../user/user')

formatShareStr = (total, part) ->
  if total && part != null
    share = _.toNumber(part * 100 / total).toFixed(1)
    "#{formatPrice(part)} (#{share}%)"
  else
    ''

class TripList
  constructor: (rawTrips, args = {}) ->
    @showFee = if typeof args['showFee'] == 'undefined' then true else args['showFee']
    @isInternalNumberRequired = args.isInternalNumberRequired
    @isProjectNameRequired = args.isProjectNameRequired
    @shinkansenUrgent =
      if typeof args['shinkansenUrgent'] == 'undefined'
        null
      else
        args['shinkansenUrgent']
    @list = _.map rawTrips, (t, index) =>
      userRaw = _.find args.users, (u) -> u.id == t.user_id
      t.user = new User(userRaw) if userRaw
      if @shinkansenUrgent != null
        new Trip(_.merge(t, {
          showFee: @showFee,
          shinkansenUrgent: @shinkansenUrgent[index],
          isInternalNumberRequired: @isInternalNumberRequired,
          isProjectNameRequired: @isProjectNameRequired,
          carTypeOptions: @carTypeOptions
        }))
      else
        new Trip(_.merge(t, {
          showFee: @showFee,
          isInternalNumberRequired: @isInternalNumberRequired,
          isProjectNameRequired: @isProjectNameRequired,
          carTypeOptions: @carTypeOptions
        }))
    @list ||= []
    @displayStatusKeys = [0, 3, 4, 7, 6, 5]

  update: (rawTrip, args = {}) ->
    index = _.findIndex @list, (t) -> t.id == rawTrip.id
    index = @list.length if index < 0
    userRaw = _.find args.users, (u) -> u.id == rawTrip.user_id
    rawTrip.user = new User(userRaw) if userRaw
    if @shinkansenUrgent != null
      @list[index] = new Trip(_.merge(rawTrip, {
        showFee: @showFee,
        shinkansenUrgent: @shinkansenUrgent[index],
        isInternalNumberRequired: @isInternalNumberRequired,
        isProjectNameRequired: @isProjectNameRequired,
        carTypeOptions: @carTypeOptions
      }))
    else
      @list[index] = new Trip(_.merge(rawTrip, {
        showFee: @showFee,
        isInternalNumberRequired: @isInternalNumberRequired,
        isProjectNameRequired: @isProjectNameRequired,
        carTypeOptions: @carTypeOptions
      }))
    app.render()

  find: (id) ->
    id = parseInt(id) if typeof id == 'string'
    _.find @list, (t) -> t.id == id

  first: ->
    @list[0]

  firstOngoingTrip: ->
    _.find @list, (t) -> t.isOngoing()

  firstArchivedTrip: ->
    _.find @list, (t) -> t.isArchived()

  ongoingTrips: ->
    @list.filter (t) -> t.isOngoing()

  # TODO: 未使用なので削除する
  archivedTrips: ->
    @list.filter (t) -> t.isArchived()

  # TODO: 未使用なので削除する
  totalPriceForCategory: (category) ->
    items = _.flatten(
      _.map @list, (t) -> t.order.orderItems
    )
    items = _.filter(items, (item) -> item.orderItemCategory == category)
    prices = _.map(items, (item) -> item.totalPriceWithAll())
    _.sum(prices)

  # TODO: 未使用なので削除する
  totalHotelPrice: ->
    @totalPriceForCategory('hotel')

  # TODO: 未使用なので削除する
  totalDomesticAirPrice: ->
    @totalPriceForCategory('domestic_air')

  # TODO: 未使用なので削除する
  totalForeignAirPrice: ->
    @totalPriceForCategory('foreign_air')

  # TODO: 未使用なので削除する
  totalShinPrice: ->
    @totalPriceForCategory('shinkansen') +
      @totalPriceForCategory('express') +
      @totalPriceForCategory('railway_ticket')

  # TODO: 未使用なので削除する
  totalOtherPrice: ->
    items = _.flatten(
      _.map @list, (t) -> t.order.orderItems
    )
    categories = ['hotel', 'domestic_air', 'foreign_air', 'shinkansen', 'express', 'railway_ticket']
    items = _.filter(items, (item) -> !_.includes(categories, item.orderItemCategory))
    prices = _.map(items, (item) -> item.totalPriceWithAll())
    _.sum(prices)

  totalPrice: ->
    _.sum(_.map @list, (t) -> t.totalPrice())

  # TODO: 未使用なので削除する
  totalHotelPriceStr: ->
    formatShareStr(@totalPrice(), @totalHotelPrice())

  # TODO: 未使用なので削除する
  totalDomesticAirPriceStr: ->
    formatShareStr(@totalPrice(), @totalDomesticAirPrice())

  # TODO: 未使用なので削除する
  totalForeignAirPriceStr: ->
    formatShareStr(@totalPrice(), @totalForeignAirPrice())

  # TODO: 未使用なので削除する
  totalShinPriceStr: ->
    formatShareStr(@totalPrice(), @totalShinPrice())

  # TODO: 未使用なので削除する
  totalOtherPriceStr: ->
    formatShareStr(@totalPrice(), @totalOtherPrice())

  # TODO: 未使用なので削除する
  totalFeePriceStr: ->
    formatShareStr(@totalPrice(), @totalFeePrice())

  # TODO: 未使用なので削除する
  totalPriceStr: ->
    formatPrice(@totalPrice())

  statusList: ->
    values = _.map @displayStatusKeys, (key) =>
      _.filter(@list, (t) -> t.status == key).length
    _.zipObject(@displayStatusKeys, values)

module.exports = TripList
