import { Fetcher } from '@this/src/util';
import React from 'react';
import { styled } from '@this/constants/themes';
import type { RouteComponentProps } from 'react-router-dom';
import type User from '@this/domain/user/user';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import Reject from '@this/components/expenses/approvals/reject';
import type Item from '@this/domain/expenses/item';
import type { ReportJson } from '../../../domain/expenses/report';
import { Report } from '../../../domain/expenses/report';
import ExpensesMain from '../main/main';
import ReportCard from '../reports/report_card';
import ReportItemCards from '../report_items/report_item_cards';
import { ButtonArea, BaseButton } from '../reports/detail';

import SimpleLoading from '../../shared/simple_loading/simple_loading';

interface ReportResponse {
  report: ReportJson;
}

interface Props extends RouteComponentProps<{ id?: string }> {
  user: User | null | undefined;
}

interface State {
  report?: Report;
  error?: string;
  loading: boolean;
  rejectReason?: string;
  showRejectModal: boolean;
  rejectErrors?: string | string[];
}

class Detail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      showRejectModal: false
    };
  }

  componentDidMount() {
    this.fetchReport();
    mixpanel.track('[Biztra][view] approval detail');
  }

  handleApproveReport = () => {
    const ret = confirm('承認しますか？');
    if (ret) this.approveReport();
  };

  handleCompleteReport = () => {
    const ret = confirm('完了しますか？');
    if (ret) this.completeReport();
  };

  private handleEditItem = (item: Item) => {
    window.open(`/biztra/approvals/${this.props.match.params.id}/approval_items/${item.id}/edit`, '_blank');
  };

  handleShowRejectModal = () => {
    this.setState({ showRejectModal: true });
  };

  private async fetchReport() {
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<ReportResponse>(`/biztra/approvals/${this.props.match.params.id}`);
      this.setState({
        report: new Report(response.report),
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async approveReport() {
    this.setState({ loading: true });
    try {
      await Fetcher.put(`/biztra/approvals/${this.props.match.params.id}/approve`, {});
      mixpanel.track('[Biztra] approved');
      this.props.history.push('/biztra/approvals');
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async completeReport() {
    this.setState({ loading: true });
    try {
      await Fetcher.put(`/biztra/approvals/${this.props.match.params.id}/complete`, {});
      mixpanel.track('[Biztra] completeed');
      this.props.history.push('/biztra/approvals');
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async rejectReport(reason: string) {
    try {
      await Fetcher.put(`/biztra/approvals/${this.props.match.params.id}/reject`, { reject_reason: reason });
      mixpanel.track('[Biztra] rejected');
      this.props.history.push('/biztra/approvals');
    } catch (e) {
      if (e.response.status === 400) {
        this.setState({
          rejectErrors: e.response.data.error
        });
      } else {
        this.setState({
          rejectErrors: '通信エラーが発生しました。時間をおいて再度お試しください。'
        });
      }
    }
  }

  private rejectErrors() {
    const errors = this.state.rejectErrors;
    if (!errors) return null;

    const elm =
      typeof errors === 'string'
        ? errors.split('\n').map(line => <p key={line}>{line}</p>)
        : errors.map(line => <p key={line}>{line}</p>);

    return <div className="expenses-approval__reject-modal__errors">{elm}</div>;
  }

  buttonArea() {
    const { user } = this.props;
    const { report } = this.state;
    return (
      <ButtonArea>
        {user && report?.hasNextStepAction() && !report?.isCompleted() && (
          <>
            {user.isOrganizationAdmin && report && report.isApproved() && (
              <FixButton className="expenses-button" type="button" onClick={this.handleCompleteReport}>
                完了
              </FixButton>
            )}
            {report && user && report.isRejectableFor(user) && (
              <RejectButton className="expenses-button" type="button" onClick={this.handleShowRejectModal}>
                差し戻し
              </RejectButton>
            )}
            {report && user && report.isApprovableFor(user) && (
              <BaseButton className="expenses-button" type="button" onClick={this.handleApproveReport}>
                承認
              </BaseButton>
            )}
          </>
        )}
      </ButtonArea>
    );
  }

  render() {
    const { loading, error, showRejectModal } = this.state;

    return (
      <ExpensesMain title="申請詳細">
        {loading ? (
          <SimpleLoading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <TheWrapper>
            <ReportCard report={this.state.report} detail />
            <ReportItemCards
              items={this.state.report!.items}
              onClickItem={this.handleEditItem}
              handleAddItem={() => {}}
              inDetail
            />
            {this.buttonArea()}
            <Modal
              open={showRejectModal}
              onClose={() => this.setState({ showRejectModal: false })}
              PaperProps={{ style: { background: 'white' } }}
            >
              <Reject
                onClose={() => this.setState({ showRejectModal: false })}
                onSubmit={(reason: string) => this.rejectReport(reason)}
              />
              {this.rejectErrors()}
            </Modal>
          </TheWrapper>
        )}
      </ExpensesMain>
    );
  }
}

const baseColor = '#927230';

const fixBtnColor = '#767676';

const whiteTxtColor = '#FFFFFF';

const TheWrapper = styled.div`
  width: 100%;
`;

export const FixButton = styled(BaseButton)`
  background: ${fixBtnColor};
  color: ${whiteTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  margin-right: 24px;
  &&:focus,
  &&:hover {
    background: ${fixBtnColor};
    color: ${whiteTxtColor};
  }
`;

export const RejectButton = styled(BaseButton)`
  background: ${baseColor};
  color: ${whiteTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  margin-right: 24px;
  &&:focus,
  &&:hover {
    background: ${baseColor};
    color: ${whiteTxtColor};
  }
`;

export default Detail;
