import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { Modal, ModalHeader, ModalBody } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import { Input } from '@this/shared/ui/inputs/input';
import { getColor } from '@this/shared/ui/theme';

import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import type { UserAuthenticate } from '@this/src/domain/user_authenticate/user_authenticate';
import moment from 'moment';
import { Heading } from '../shared/ui/data_displays/typography';

type Props = {
  open: boolean;
  onAbort: () => void;
  userAuthenticates: UserAuthenticate[];
  onAdd: (usage: string) => void;
  onDelete: (id: number) => void;
};

export const AuthKeyModal = ({ open, onAbort, userAuthenticates, onAdd, onDelete }: Props) => {
  const [isNewCardVisible, setIsNewCardVisible] = useState(false);
  const [newUsage, setNewUsage] = useState('');
  const [validationError, setValidationError] = useState<string | null>(null);

  const handleInputChange = (value: string) => {
    setNewUsage(value);

    if (value.length < 1 || value.length >= 20) {
      setValidationError('認証キー名は1~20文字で入力してください。');
      return;
    }

    if (userAuthenticates.some(auth => auth.usage === value)) {
      setValidationError('認証キー名が重複しています。別の名前を入力してください。');
      return;
    }

    setValidationError(null);
  };

  const handleCreate = () => {
    if (validationError || !newUsage.trim()) {
      return;
    }

    onAdd(newUsage);
    setIsNewCardVisible(false);
    setNewUsage('');
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    // eslint-disable-next-line no-alert
    alert('認証キーをコピーしました');
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onAbort();
        setIsNewCardVisible(false);
        setNewUsage('');
        setValidationError(null);
      }}
    >
      <ModalHeader>
        <Title>認証キー発行</Title>
      </ModalHeader>
      <AuthKeyModalBody>
        <AuthkeyModalHeding level="3">認証キー</AuthkeyModalHeding>
        <CardList>
          {userAuthenticates.map(userAuthenticate => (
            <Card key={userAuthenticate.id}>
              <CardImage src="/images/key-icon.svg" />
              <CardTextBlock>
                <CardTitle>{userAuthenticate.usage}</CardTitle>
                <CardText>{userAuthenticate.key}</CardText>
                <CardText>作成日時：{formatJapaneseDate(userAuthenticate.created_at)}</CardText>
                <CardText>
                  最終使用日：
                  {userAuthenticate.last_used_at ? formatJapaneseDate(userAuthenticate.last_used_at) : '-'}
                </CardText>
              </CardTextBlock>
              <CardButtonBlock>
                <CopyButton color="primary" size="medium" onClick={() => copyToClipboard(userAuthenticate.key)}>
                  <CopyIcon />
                  コピー
                </CopyButton>

                <DeleteButton color="danger" size="medium" onClick={() => onDelete(userAuthenticate.id)}>
                  <DeleteIcon />
                  削除
                </DeleteButton>
              </CardButtonBlock>
            </Card>
          ))}

          {isNewCardVisible ? (
            <NewCard>
              <NewCardBlock>
                <CardImage src="/images/key-icon.svg" />
                <CardTextBlock>
                  <AuthKeyInput
                    placeholder="認証キー名"
                    value={newUsage}
                    onChange={e => handleInputChange(e.target.value)}
                  />
                </CardTextBlock>
                <CardButtonBlock>
                  <CreateButton color="primary" size="medium" onClick={handleCreate} disabled={!!validationError}>
                    発行する
                  </CreateButton>

                  <CancelButton
                    color="sub"
                    size="medium"
                    onClick={() => {
                      setIsNewCardVisible(false);
                      setNewUsage('');
                      setValidationError(null);
                    }}
                  >
                    キャンセル
                  </CancelButton>
                </CardButtonBlock>
              </NewCardBlock>
              {validationError && <ValidationError>{validationError}</ValidationError>}
            </NewCard>
          ) : (
            <AddButton onClick={() => setIsNewCardVisible(true)}>＋認証キーを追加</AddButton>
          )}
        </CardList>
      </AuthKeyModalBody>
    </Modal>
  );
};

const formatJapaneseDate = (dateString: string) => {
  return moment(dateString).format('YYYY年M月D日');
};

const Title = styled.span`
  font-size: 18px;
  text-align: center;
  margin: 0;
`;

const AuthKeyModalBody = styled(ModalBody)`
  padding: 8px 24px 24px;
`;

const AuthkeyModalHeding = styled(Heading)`
  font-size: 17px;
  width: 100%;
  border-bottom: 1px solid ${getColor('brand', 'primary')};
  height: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #404040;
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Card = styled.div`
  display: flex;
  gap: 4px;
  border: 1px solid ${getColor('border', 'field')};
  border-radius: 4px;
  padding: 16px;
`;

const NewCard = styled.div`
  display: flex;
  gap: 4px;
  border: 1px solid ${getColor('border', 'field')};
  border-radius: 4px;
  padding: 16px;
  flex-direction: column;
`;

const NewCardBlock = styled.div`
  display: flex;
  gap: 4px;
`;

const CardTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const CardTitle = styled.p`
  font-size: 15px;
  font-weight: bold;
  color: #404040;
`;

const CardText = styled.p`
  font-size: 11px;
  font-weight: bold;
  color: ${getColor('text', 'description')};
`;

const CardImage = styled.img`
  width: 32px;
  height: 32px;
`;

const CardButtonBlock = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const CopyButton = styled(Button)`
  font-size: 13px;
  background-color: ${getColor('info', 'primary')};
  height: 32px;
  &:hover {
    background-color: ${getColor('info', 'hover')};
  }
`;

const DeleteButton = styled(Button)`
  font-size: 13px;
  height: 32px;
`;

const CopyIcon = styled(FileCopyOutlined)`
  height: 14px;
  width: 14px;
  margin-right: 4px;
`;

const DeleteIcon = styled(DeleteOutlinedIcon)`
  height: 14px;
  width: 14px;
  margin-right: 4px;
`;

const AddButton = styled.button`
  color: ${getColor('brand', 'primary')};
  font-size: 15px;
  font-weight: normal;
  margin: 20px 0;
  padding: 0;
  background: none !important;
  text-align: left;
  &:hover {
    color: ${getColor('brand', 'hover')} !important;
  }
`;

const AuthKeyInput = styled(Input)`
  margin-right: 6px;
  & .MuiInputBase-input {
    height: 32px;
    box-sizing: border-box;
  }
`;

const CreateButton = styled(Button)`
  font-size: 13px;
  height: 32px;
`;

const CancelButton = styled(Button)`
  font-size: 13px;
  height: 32px;
  border: none !important;
  background: none !important;
  padding: 0;
  color: ${getColor('text', 'disabled')};
`;

const ValidationError = styled.div`
  color: ${getColor('danger', 'primary')};
  font-size: 12px;
  margin-top: 4px;
  padding-left: 40px;
`;
