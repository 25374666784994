import { Fetcher } from '@this/src/util';
import React from 'react';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { styled } from '@this/constants/themes';
import type { MessageJson } from '../../../domain/expenses/message';
import { Message } from '../../../domain/expenses/message';

interface State {
  message?: Message;
  loading: boolean;
  error?: string;
}

type Props = any;

export default class OnboardMessage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.fetchMessage();
  }

  private async fetchMessage() {
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<MessageJson>('/biztra/messages.json');
      const message = new Message(response);
      this.setState({
        message,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  render() {
    const { message, loading, error } = this.state;
    return (
      <>
        {loading ? (
          <SimpleLoading />
        ) : error ? (
          <p className="expense-message">{error}</p>
        ) : message !== undefined && message.message !== undefined && message.message.length !== 0 ? (
          <MessageParagraph className="expense-message">
            <a href="/biztra/organization/members">{message.message}</a>
          </MessageParagraph>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const MessageTxtColor = '#323232';

const MessageParagraph = styled.p`
  a {
    color: ${MessageTxtColor};
    font-weight: bold;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 12px;
    a {
      font-size: 18px;
    }
  }
`;
