import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import {
  BoxContent,
  Left,
  Segment,
  Name,
  Image,
  BoxFooter
} from '../transport_box/transport_box_foreign.template';
import {
  BoxWrap,
  BoxPriceArea,
  BoxPrice,
  BoxSmall,
  BoxButtonArea,
  BoxButton
} from '../transport_box/right/right_in_outline.template';
import { BoxButtonText, Box, Yen } from '../shared/box_parts';

import { RightButton, RightBody, RightPrice, RightPeopleNum } from '../list_area/list_area.template';

import type { SelectFlightBoxProps } from './flight_box';
import SimpleLoading from '../../../shared/simple_loading/simple_loading';

type SelectFlightBoxTemplateProps = SelectFlightBoxProps & {
  handleChange: () => void;
};

const SelectFlightBoxTemplate: React.SFC<SelectFlightBoxTemplateProps> = ({
  selected,
  key,
  inList,
  flight,
  handleChange,
  tab,
  flightIndex,
  isRequestForm,
  isDomestic
}) => {
  const flightSlice = flight?.cheapestFlight()?.itineraries?.[flightIndex];

  return (
    <FlightBox selected={selected} key={key}>
      {flight ? (
        <>
          <BoxContent>
            <Left>
              {flightSlice?.segments?.map((s, i) => (
                <Segment key={i}>
                  <div className="flex">
                    <Name data-wovn-ignore>
                      {s.carrier_name && `${s.carrier_name} `}
                      {s.name}
                    </Name>
                    <Image src={utils.flightIconPath(s.name)} />
                  </div>
                  <div className="select-transport-box__left__icon-body">
                    <img src="/images/departure.png" width="28" height="17" />
                    <div data-wovn-ignore className="select-transport-box__left__icon-body__text">
                      {`${s.from.date} ${s.from.time} ${s.from.name}`}
                    </div>
                  </div>
                  <div className="select-transport-box__left__icon-body">
                    <img src="/images/arrival.png" width="28" height="17" />
                    <div data-wovn-ignore className="select-transport-box__left__icon-body__text">
                      {`${s.to.date} ${s.to.time} ${s.to.name}`}
                    </div>
                  </div>
                  <div className="select-transport-box__left__icon-body">
                    <img
                      src={utils.flightClassIconPath(s.cabin)}
                      title={utils.flightClassName(s.cabin)}
                      width="28"
                      height="17"
                    />
                    <div data-wovn-ignore className="select-transport-box__left__icon-body__text">
                      {utils.flightClassName(s.cabin)}
                    </div>
                  </div>
                </Segment>
              ))}
            </Left>
            {inList ? (
              <div className="select-transport-box__right-wrapper flight">
                {flightSlice?.ticketing_enable ? (
                  <RightButton
                    isDomestic={isDomestic}
                    selected={selected || flight.hovered}
                    onClick={handleChange}
                    onTouchStart={flight.handleTouched}
                    onMouseOver={flight.setHovered(true)}
                    onMouseLeave={flight.setHovered(false)}
                  >
                    {flight.hovered && !selected ? (
                      <div className="select-transport-box__right__body__select">選択する</div>
                    ) : (
                      <RightBody>
                        {tab === 0 ? (
                          <div>
                            <RightPeopleNum>1名</RightPeopleNum>
                            <RightPrice>
                              <span data-wovn-ignore>{utils.digits(flight.pricePerPerson())}</span>
                              <Yen>円</Yen>
                            </RightPrice>
                          </div>
                        ) : (
                          <div>
                            <RightPeopleNum>1名</RightPeopleNum>
                            <RightPrice>+</RightPrice>
                            <RightPrice>
                              <span data-wovn-ignore>{utils.digits(flight.priceDiffPerPerson())}</span>
                              <Yen>円</Yen>
                            </RightPrice>
                          </div>
                        )}
                      </RightBody>
                    )}
                  </RightButton>
                ) : (
                  <div className="select-transport-box__right flight disabled">
                    <RightBody>期限内に発券できません</RightBody>
                  </div>
                )}
                <div className="select-transport-box__right-deadline">
                  {(flightSlice?.rawTicketingExpiredAt || flightSlice?.timeToLive) && (
                    <DeadlineWrap
                      title="発券期限は航空会社により予告なく変更される場合があります"
                      style={{ cursor: 'pointer' }}
                    >
                      発券期限：
                      <Text color="danger" level="caption" bold>
                        {flightSlice?.rawTicketingExpiredAt
                          ? flightSlice?.rawTicketingExpiredAt?.format('YYYY/MM/DD HH:mm')
                          : `ご依頼から${flightSlice?.timeToLive}時間`}
                      </Text>
                    </DeadlineWrap>
                  )}
                </div>
              </div>
            ) : (
              <FlightBoxWrap>
                <div className="flex">
                  <BoxPriceArea>
                    <div>
                      {flightIndex === 0 ? (
                        <BoxPrice>
                          <span data-wovn-ignore>{utils.digits(flight.pricePerPerson())}</span>
                          <Yen>円</Yen>
                        </BoxPrice>
                      ) : (
                        [
                          <BoxPrice key={0}>+</BoxPrice>,
                          <BoxPrice key={1}>
                            <span data-wovn-ignore>{utils.digits(flight.priceDiffPerPerson())}</span>
                            <Yen>円</Yen>
                          </BoxPrice>
                        ]
                      )}
                      <BoxSmall>/1名</BoxSmall>
                    </div>
                  </BoxPriceArea>
                  {!isRequestForm && (
                    <BoxButtonArea>
                      <BoxButton selected={selected} onClick={handleChange}>
                        <BoxButtonText>
                          {selected ? ['変更を', <br key={0} />, 'キャンセル'] : '変更 >'}
                        </BoxButtonText>
                      </BoxButton>
                    </BoxButtonArea>
                  )}
                </div>
                <div className="select-transport-box__right-deadline">
                  {(flightSlice?.rawTicketingExpiredAt || flightSlice?.timeToLive) && (
                    <DeadlineWrap
                      title="発券期限は航空会社により予告なく変更される場合があります"
                      style={{ cursor: 'pointer' }}
                    >
                      発券期限：
                      <Text color="danger" level="caption" bold>
                        {flightSlice?.rawTicketingExpiredAt ? (
                          <span data-wovn-ignore>
                            {flightSlice?.rawTicketingExpiredAt?.format('YYYY/MM/DD HH:mm')}
                          </span>
                        ) : (
                          <>
                            <span>ご依頼から</span>
                            <span data-wovn-ignore>{flightSlice?.timeToLive}</span>
                            <span>時間</span>
                          </>
                        )}
                      </Text>
                    </DeadlineWrap>
                  )}
                </div>
              </FlightBoxWrap>
            )}
          </BoxContent>
          <BoxFooter selected={selected}>
            <div>
              <span>所要時間：</span>
              <span data-wovn-ignore>{flightSlice?.duration}</span>
            </div>
            <span>表記は現地時刻です。</span>
            {flight.cheapestFlightMinirulePerPerson() && (
              <span data-wovn-ignore>{`(${flight.cheapestFlightMinirulePerPerson()})`}</span>
            )}
          </BoxFooter>
        </>
      ) : (
        <SimpleLoading />
      )}
    </FlightBox>
  );
};

const FlightBox = styled(Box)`
  flex-direction: column;
`;

const FlightBoxWrap = styled(BoxWrap)`
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
`;

const DeadlineWrap = styled(Text).attrs({ level: 'caption' })`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export default SelectFlightBoxTemplate;
