import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import type Department from '@this/domain/department/department';

interface Props {
  selectedDepartmentIds: number[];
  onSelect: (ids: number[]) => void;
  filter: boolean;
  isAdmin?: boolean;
  userId?: number;
}

interface Response {
  departments: Department[];
}

const ArrangementDepartmentSelector = observer(
  ({ selectedDepartmentIds, onSelect, filter, isAdmin = false, userId }: Props) => {
    const [departments, setDepartments] = useState<Department[]>([]);
    const [selectedDepartments, setSelectedDepartments] = useState<Department[]>([]);
    const [query, setQuery] = useState<string>('');

    const fetchDepartments = useCallback(() => {
      const url = isAdmin ? '/admin/departments/autocomplete.json' : '/organization/departments/autocomplete.json';
      const params = {
        text: query,
        ids: selectedDepartmentIds.sort().join(','),
        filter,
        ...(isAdmin && userId ? { user_id: userId } : {}) // adminの場合に user_id を追加
      };
      Fetcher.get<Response>(url, params).then(res => setDepartments(res.departments));
    }, [filter, query, selectedDepartmentIds, isAdmin, userId]);

    useEffect(() => {
      fetchDepartments();
    }, [fetchDepartments]);

    useEffect(() => {
      setSelectedDepartments(departments.filter(d => selectedDepartmentIds.includes(d.id)));
    }, [departments, selectedDepartmentIds]);

    return (
      <Autocomplete
        multiple
        options={departments}
        getOptionLabel={o => `${o.name}(${o.code})`}
        filterOptions={(options, state) => {
          const q: string = state.inputValue;
          return _.filter(options, o => o.name.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1);
        }}
        onChange={(_, selected) => {
          onSelect(selected.map(s => s.id));
          setQuery('');
        }}
        onInputChange={(_e, value, reason) => {
          if (reason === 'input') {
            setQuery(value);
          }
        }}
        inputValue={query}
        value={selectedDepartments}
        size="small"
        renderInput={params => (
          <TextField {...params} variant="outlined" style={{}} InputLabelProps={{ shrink: true }} size="small" />
        )}
        style={{ minWidth: '240px', background: '#fff' }}
      />
    );
  }
);

export default ArrangementDepartmentSelector;
