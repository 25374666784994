import { Fetcher } from '@this/src/util';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { RuleCabinetJson } from '@this/domain/expenses/rule_cabinet';
import { RuleCabinet } from '@this/domain/expenses/rule_cabinet';
import MainTitle from '@this/shared/organization/main_title/main_title';
import { ContentBody } from '../organization';
import Form from './form';

interface RuleCabinetResponse {
  rule_cabinet: RuleCabinetJson;
}

interface RuleCabinetsResponse {
  rule_cabinets: RuleCabinetJson[];
}

interface Props extends RouteComponentProps<{ id?: string }> {
  action: 'new' | 'edit';
}

interface State {
  ruleCabinet?: RuleCabinet;
  error?: string;
  putErrors?: string | string[];
  loading: boolean;
  submitting: boolean;
}

const basePath = '/biztra/organization/rule_cabinets/';

class Edit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      submitting: false
    };
  }

  componentDidMount() {
    this.fetchRuleCabinet();
  }

  handleSubmit() {
    this.submitRuleCabinet();
  }

  private async fetchRuleCabinet() {
    try {
      this.setState({ loading: true });
      const path = this.fetchPath();
      const response = await Fetcher.get<RuleCabinetResponse>(path);
      this.setState({
        ruleCabinet: new RuleCabinet(response.rule_cabinet),
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private fetchPath(): string {
    return this.props.action === 'new' ? `${basePath}new` : `${basePath}${this.ruleId()}/edit`;
  }

  private submitPath(): string {
    return this.props.action === 'new' ? `${basePath}` : `${basePath}${this.ruleId()}`;
  }

  private async submitRuleCabinet() {
    const ruleCabinet = this.state.ruleCabinet;

    if (this.props.action === 'edit' && !ruleCabinet) {
      return;
    }
    this.setState({ submitting: true });
    try {
      await Fetcher.request<RuleCabinetsResponse>({
        url: this.submitPath(),
        data: ruleCabinet?.submitParams(),
        method: this.props.action === 'new' ? 'POST' : 'PUT'
      });
      this.setState({ submitting: false }, () => {
        this.props.history.push('/biztra/organization/rule_cabinets');
      });
    } catch (e) {
      if (e.response.status === 400) {
        this.setState({
          putErrors: e.response.data.error,
          submitting: false
        });
      } else {
        this.setState({
          putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
          submitting: false
        });
      }
    }
  }

  private ruleCabinetForm() {
    const { ruleCabinet, submitting, putErrors } = this.state;
    return (
      <Form
        ruleCabinet={ruleCabinet!}
        onSubmit={() => this.handleSubmit()}
        submitting={submitting}
        errors={putErrors}
        action={this.props.action}
        backPath={`${basePath}`}
      />
    );
  }

  private ruleId = () => this.props.match.params.id;

  render() {
    const { loading, error } = this.state;
    return (
      <>
        <MainTitle value="ルール保管庫" />
        <ContentBody>
          {loading ? <SimpleLoading /> : error ? <p>{error}</p> : <>{this.ruleCabinetForm()}</>}
        </ContentBody>
      </>
    );
  }
}

export default Edit;
