import { Fetcher, HTTPError } from '@this/src/util';
import React from 'react';
import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import {
  OrganizationHeader,
  OrganizationHeaderTitle,
  OrganizationTable,
  OrganizationBiztraTh,
  OrganizationBiztraTd,
  OrganizationBody,
  OrganizationBiztraTr
} from '@this/components/expenses/organization/organization.style';

import type { RouteComponentProps } from 'react-router-dom';
import type { BulkActionStatus as BulkActionStatusType, Organization } from '@this/components/organization/types';
import SegmentedControl from '@this/components/expenses/segmented_control/segmented_control';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';

const BulkActionStatus = require('@this/domain/bulk_action/bulk_action_status');

enum BulkActionType {
  UPSERT_USERS = 'upsert_users',
  UPSERT_DEPARTMENTS = 'upsert_departments',
  UPDATE_APPROVERS = 'update_approvers',
  UPSERT_ACCOUNT_TYPES = 'upsert_account_types',
  UPSERT_EXPENSES_TYPES = 'upsert_expenses_types'
}

interface BulkActionTypeSegment {
  actionType: BulkActionType;
  label: string;
  segmentNumber: number;
  showTitle?: string;
}

interface Props extends RouteComponentProps {
  expenses?: boolean;
}
interface State {
  selectedSegment: number;
  statuses: any[];
  loading: boolean;
  error: string | null;
}

export const ShowTitleOnly = {
  expenses: 'expenses'
};

const BASE_BULK_ACTION_TYPE_SEGMENTS: Pick<BulkActionTypeSegment, 'actionType' | 'label' | 'showTitle'>[] = [
  { actionType: BulkActionType.UPSERT_USERS, label: '社員一括追加・更新' },
  { actionType: BulkActionType.UPSERT_DEPARTMENTS, label: '部署一括追加・更新' },
  { actionType: BulkActionType.UPDATE_APPROVERS, label: '承認者一括追加・更新' },
  {
    actionType: BulkActionType.UPSERT_ACCOUNT_TYPES,
    label: '勘定科目一括追加・更新',
    showTitle: ShowTitleOnly.expenses
  },
  {
    actionType: BulkActionType.UPSERT_EXPENSES_TYPES,
    label: '経費科目一括追加・更新',
    showTitle: ShowTitleOnly.expenses
  }
];

class BulkActionStatuses extends React.Component<Props, State> {
  bulkActionTypeSegments: BulkActionTypeSegment[];

  unListenRouting?: () => void;

  constructor(props: Props) {
    super(props);

    this.bulkActionTypeSegments = BASE_BULK_ACTION_TYPE_SEGMENTS.reduce(
      (acc: BulkActionTypeSegment[], segment) => {
        acc.push({ ...segment, segmentNumber: acc.length });
        return acc;
      },
      []
    );

    this.state = {
      selectedSegment: this.getSegmentNumberBylocation(),
      statuses: [],
      loading: true,
      error: null
    };

    this.unListenRouting = this.props.history.listen(() => {
      this.setState({ selectedSegment: this.getSegmentNumberBylocation() });
    });
  }

  componentDidMount() {
    Fetcher.get<{
      bulk_action_statuses: BulkActionStatusType[];
      orgnization: Organization;
    }>('./bulk_action_statuses.json').then(
      result => {
        this.setState({
          statuses: result.bulk_action_statuses.map(status => new BulkActionStatus(status)),
          loading: false
        });
      },
      error => {
        let errorText: string | null;
        if (error instanceof HTTPError && error.response?.status === 400) {
          errorText = error.response.data.errors;
        } else {
          errorText = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        }
        this.setState({
          loading: false,
          error: errorText
        });
      }
    );
  }

  componentWillUnmount(): void {
    if (this.unListenRouting) {
      this.unListenRouting();
    }
  }

  handleChangeSegment = (i: number) => {
    const segment = this.bulkActionTypeSegments.find(s => s.segmentNumber === i);

    if (segment) {
      this.props.history.push({ search: `?actionType=${segment.actionType}` });
    }
  };

  private getSegmentNumberBylocation() {
    const parsedQueryParams = utils.getParams();
    const actionType =
      parsedQueryParams && parsedQueryParams.actionType
        ? Array.isArray(parsedQueryParams.actionType)
          ? parsedQueryParams.actionType[0]
          : parsedQueryParams.actionType
        : null;

    const segment = this.bulkActionTypeSegments.find(s => s.actionType === actionType);
    return segment ? segment.segmentNumber : 0;
  }

  render() {
    const { selectedSegment, loading, error, statuses } = this.state;
    const { expenses } = this.props;
    const segment = this.bulkActionTypeSegments.find(s => s.segmentNumber === selectedSegment);
    if (!segment) {
      return null;
    }
    const filteredStatuses = statuses.filter(s => s.actionType === segment.actionType);

    return (
      <div className="organization-members">
        <Header expenses={expenses}>
          <HeaderTitle expenses={expenses}>アップロード履歴</HeaderTitle>
        </Header>
        <OrganizationBody>
          <SegmentedControl
            name="type"
            labels={this.bulkActionTypeSegments.map(({ label }) => label)}
            value={selectedSegment}
            onChange={this.handleChangeSegment}
          />
          {loading ? (
            <SimpleLoading />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <OrganizationTable>
              <tbody>
                <tr>
                  <OrganizationBiztraTh>ファイル名</OrganizationBiztraTh>
                  <OrganizationBiztraTh>アップロードユーザー</OrganizationBiztraTh>
                  <OrganizationBiztraTh>送信日時</OrganizationBiztraTh>
                  <OrganizationBiztraTh>ステータス</OrganizationBiztraTh>
                  <OrganizationBiztraTh>詳細</OrganizationBiztraTh>
                </tr>
                {filteredStatuses.map((status, i) => (
                  <OrganizationBiztraTr key={i} className={i % 2 === 1 ? 'even' : 'odd'}>
                    <OrganizationBiztraTd>{status.filename}</OrganizationBiztraTd>
                    <OrganizationBiztraTd>{status.kickedByUser.name}</OrganizationBiztraTd>
                    <OrganizationBiztraTd>{status.createdAt}</OrganizationBiztraTd>
                    <OrganizationBiztraTd>
                      <TdIn color={status.isSuccess() ? 'green' : status.isFail() ? 'red' : 'gray'}>
                        {status.statusMessage()}
                      </TdIn>
                    </OrganizationBiztraTd>
                    <OrganizationBiztraTd>
                      <Link to={`${expenses ? '/biztra' : ''}/organization/bulk_action_statuses/${status.id}`}>
                        詳細
                      </Link>
                    </OrganizationBiztraTd>
                  </OrganizationBiztraTr>
                ))}
              </tbody>
            </OrganizationTable>
          )}
        </OrganizationBody>
      </div>
    );
  }
}

const successBiztraColor = '#1D7C2D';

const dangerBiztraColor = '#C72F62';

const grayBiztraTextColor = '#323232';

const Header = styled(OrganizationHeader)`
  padding: ${({ expenses }: { expenses?: boolean }) => (expenses ? '0' : '10px')};
`;

const HeaderTitle = styled(OrganizationHeaderTitle)`
  color: ${({ expenses }: { expenses?: boolean }) => expenses && '#927230'};
  font-size: ${({ expenses }: { expenses?: boolean }) => expenses && '24px'};
  margin: ${({ expenses }: { expenses?: boolean }) => expenses && '0 0 12px 0'};
`;

const TdIn = styled.p<{ color: string }>`
  ${props =>
    props.color === 'green'
      ? `
      color: ${successBiztraColor};
    `
      : props.color === 'red'
      ? `
      color: ${dangerBiztraColor};
    `
      : `
      color: ${grayBiztraTextColor};
    `}
`;

export default BulkActionStatuses;
