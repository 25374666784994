import React, { useCallback, useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import { styled } from '@this/src/components/constants/themes';
import { FeedbackButtons } from './feedback_buttons';
import { FeedbackModal } from './feedback_modal';

type Rating = 'not_rated' | 'good' | 'bad';

type Props = {
  className?: string;
  defaultRating?: Rating;
  onChangeRating: (rating: Rating) => void;
  onSubmitDetailFeedback: (value: string) => void;
  onReload?: () => void;
};

export const SendFeedback = ({
  className,
  defaultRating = 'not_rated',
  onChangeRating,
  onSubmitDetailFeedback,
  onReload
}: Props) => {
  const [rating, setRating] = useState(defaultRating);
  const [notificationMessage, setNotificationMessage] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleChangeRating = useCallback(
    (value: Rating) => {
      const newValue = rating === value ? 'not_rated' : value;

      setRating(newValue);
      onChangeRating(newValue);

      if (newValue === 'good') {
        setNotificationMessage('フィードバックをお寄せいただきありがとうございます！');
      } else if (newValue === 'bad') {
        setShowModal(true);
      }
    },
    [onChangeRating, rating]
  );

  const handleSnackbarClose = useCallback(() => {
    setNotificationMessage(null);
  }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleSubmit = useCallback(
    feedback => {
      onSubmitDetailFeedback(feedback);
      setNotificationMessage('フィードバックをお寄せいただきありがとうございます！');

      setShowModal(false);
    },
    [onSubmitDetailFeedback]
  );

  return (
    <Root show={rating === 'not_rated'}>
      <FeedbackButtons className={className} value={rating} onClick={handleChangeRating} onReload={onReload} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!notificationMessage}
        onClose={handleSnackbarClose}
        message={notificationMessage}
        autoHideDuration={3000}
      />
      <FeedbackModal open={showModal} onSubmit={handleSubmit} onClose={handleClose} />
    </Root>
  );
};

const Root = styled.div<{ show: boolean }>`
  transition: opacity 0.3s 1s, max-height 0.3s 1s;
  max-height: 100px;
  ${({ show }) =>
    show
      ? 'opacity: 1;'
      : `
          opacity: 0;
          max-height: 0;
          pointer-events: none;
        `}
`;
