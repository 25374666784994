/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Modal as FeedbackModal } from '@this/shared/ui/feedbacks/modal';
import type { ExpensesTypeJson } from '@this/domain/expenses/expenses_type';
import { ExpensesType } from '@this/domain/expenses/expenses_type';
import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type { TaxTypeArgs } from '@this/domain/tax_type';
import TaxType from '@this/domain/tax_type';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MainTitle from '@this/shared/organization/main_title/main_title';
import {
  OrganizationBiztraTh,
  OrganizationBiztraTable,
  OrganizationBiztraTd,
  OrganizationEditModalSelectWrap,
  OrganizationEditModalErr
} from '@this/components/expenses/organization/organization.style';
import { BiztraModal, BiztraModalHeader, BiztraModalBody } from '@this/components/expenses/ui/feedbacks/modal';
import { Fetcher, HTTPError } from '@this/src/util';
import { TheAddButton } from './departments';
import { ContentBody } from './organization';
import { ModalInputArea, ModalInputLabel, ModalInput, ModalSelect } from './members';
import { Wrapper, Header } from '../report_items/search_form';
import { ActionWrapper, BaseButton, CancelButton } from '../approvals/reject';
import { ConfirmTxt, ConfirmBody } from '../approvals/zengin_download';
import {
  EditButton,
  TheDeleteButton,
  ModalDeleteButton,
  TheButtonSection,
  TheOutlinedButton
} from './expenses_account_types';
import { FileUploadOutlinedIcon } from '../icons';
import Notification from '../../../notification';

interface ExpensesTypesResponse {
  expenses_types: ExpensesTypeJson[];
  expenses_account_types: ExpensesAccountTypeJson[];
  tax_types: TaxTypeArgs[];
}

interface ExpensesTypeResponse {
  expenses_type: ExpensesTypeJson;
  expenses_account_type: ExpensesAccountTypeJson;
}

interface ExpensesTypesPutResponse {
  id: string;
  name: string;
  expenses_account_type_id: number;
}

interface ExpensesTypesPostResponse {
  expenses_type: ExpensesTypeJson;
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  expensesTypes?: ExpensesType[];
  expensesAccountTypes?: ExpensesAccountType[];
  taxTypes?: TaxType[];
  categoryOptions: { [key: string]: string };
  error?: string;
  putErrors?: string | string[];
  loading: boolean;
  saving: boolean;
  editingExpensesType: ExpensesType | null;
  deletingExpensesType: ExpensesType | null;
  selectedRowIds: string[];
  title: string;
  showEditModal: boolean;
}

class ExpensesTypes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expensesTypes: [],
      expensesAccountTypes: [],
      categoryOptions: {},
      loading: true,
      error: '',
      putErrors: [],
      editingExpensesType: null,
      deletingExpensesType: null,
      title: '',
      saving: false,
      selectedRowIds: [],
      showEditModal: false
    };
  }

  componentDidMount() {
    this.fetchExpensesTypes();
    mixpanel.track('[Biztra][view] /organization/expenses_types');
  }

  onDragStart = (start: any) => {
    const id = start.draggableId;
    const selected = this.state.selectedRowIds.find(selectedId => selectedId === id);

    // If dragging an item that is not selected, unselect all items
    if (!selected) {
      this.unselectAll();
    }
  };

  onDragEnd = (result: any) => {
    const { destination, source } = result;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const expensesTypes = Object.assign([], this.state.expensesTypes);
    if (this.state.expensesTypes) {
      const quote = this.state.expensesTypes[source.index];
      expensesTypes.splice(source.index, 1);
      expensesTypes.splice(destination.index, 0, quote);
    }
    this.setState({
      expensesTypes
    });
  };

  unselect = () => {
    this.unselectAll();
  };

  unselectAll = () => {
    this.setState({
      selectedRowIds: []
    });
  };

  private async fetchExpensesTypes() {
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<ExpensesTypesResponse>('/biztra/organization/expenses_types.json');
      const expensesTypes = response.expenses_types.map(type => new ExpensesType(type));
      const expensesAccountTypes = response.expenses_account_types.map(
        account => new ExpensesAccountType(account)
      );
      const taxTypes = response.tax_types.map(tax => new TaxType(tax));
      this.setState({
        expensesTypes,
        expensesAccountTypes,
        taxTypes,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async handleAddClick() {
    try {
      const response = await Fetcher.get<ExpensesTypeResponse>(`/biztra/organization/expenses_types/new`);
      const new_type = new ExpensesType(response.expenses_type);
      if (this.state.expensesTypes) {
        this.state.expensesTypes.push(new_type);
      }
      this.setState({
        editingExpensesType: new_type,
        categoryOptions: new_type.categoryOptions,
        title: '追加',
        saving: false,
        showEditModal: true
      });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
        this.setState({
          putErrors: e.response.data.error,
          saving: false
        });
      } else {
        this.setState({
          putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
          saving: false
        });
      }
    }
  }

  allSubmitParams() {
    const expensesTypes = this.state.expensesTypes;
    if (expensesTypes) {
      const expensesTypesParams = expensesTypes.map(ex => ex.submitParams());
      return { expenses_types: expensesTypesParams };
    }
    return {};
  }

  async handleDisplayOrderSubmit() {
    this.setState({
      saving: true
    });
    return Fetcher.put<ExpensesTypesResponse>(
      '/biztra/organization/expenses_types/display_order',
      this.allSubmitParams()
    )
      .then(() => {
        this.setState({
          saving: false
        });
        Notification.success('保存が完了しました');
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
          this.setState({
            putErrors: e.response.data.error,
            saving: false
          });
        } else {
          this.setState({
            putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
            saving: false
          });
        }
      });
  }

  handleEditClick(expensesType: ExpensesType) {
    const type = _.cloneDeep(expensesType);
    this.setState({
      editingExpensesType: type,
      categoryOptions: expensesType.categoryOptions,
      putErrors: [],
      title: '変更',
      showEditModal: true
    });
  }

  handleCancelClick() {
    if (this.state.editingExpensesType) {
      if (!this.state.editingExpensesType.id) {
        if (this.state.expensesTypes) {
          this.state.expensesTypes.pop();
        }
      }
    }
    this.setState({ editingExpensesType: null, showEditModal: false });
  }

  handleExpensesTypeChange(value: string) {
    if (this.state.editingExpensesType) {
      const account = _.find(this.state.expensesAccountTypes, account => {
        return account.id === parseInt(value, 10);
      });
      if (account) {
        this.state.editingExpensesType.setExpensesAccountType(account);
      }
    }
  }

  handleDeleteClick(expensesType: ExpensesType) {
    this.setState({ deletingExpensesType: expensesType });
  }

  handleDeleteAlertClose() {
    this.setState({ deletingExpensesType: null });
  }

  handleDeleteConfirm() {
    Fetcher.delete(`/biztra/organization/expenses_types/${this.state.deletingExpensesType!.id}`).then(
      () => {
        this.setState({
          deletingExpensesType: null
        });
        this.fetchExpensesTypes();
      },
      e => {
        this.setState({
          error:
            e instanceof HTTPError && e.response?.status === 400 && e.response.data.errors
              ? e.response.data.errors
              : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  private handleEditFormSubmit() {
    this.setState({
      saving: true
    });
    if (this.state.editingExpensesType) {
      if (this.state.editingExpensesType.id) {
        this.submitUpdate();
      } else {
        this.submitCreate();
      }
    }
  }

  private submitCreate() {
    Fetcher.post<ExpensesTypesPostResponse>(
      `/biztra/organization/expenses_types`,
      this.state.editingExpensesType!.submitParams() ?? {}
    )
      .then(res => {
        _.assign(this.state.editingExpensesType, { id: res.expenses_type.id });
        this.setState({
          saving: false,
          editingExpensesType: null,
          showEditModal: false
        });
        this.fetchExpensesTypes();
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
          this.setState({
            putErrors: e.response.data.error,
            saving: false
          });
        } else {
          this.setState({
            putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
            saving: false
          });
        }
      });
  }

  private submitUpdate() {
    if (!this.state.editingExpensesType) return;
    if (!this.state.expensesTypes) return;
    Fetcher.put<ExpensesTypesPutResponse>(
      `/biztra/organization/expenses_types/${this.state.editingExpensesType.id}`,
      this.state.editingExpensesType!.submitParams() ?? {}
    )
      .then(() => {
        if (!this.state.editingExpensesType) return;
        const type_id = this.state.editingExpensesType.id;
        const expenses_type = _.find(this.state.expensesTypes, type => {
          return type.id === type_id;
        });
        if (expenses_type) {
          _.assign(expenses_type, this.state.editingExpensesType);
        }
        this.setState({
          saving: false,
          editingExpensesType: null,
          showEditModal: false
        });
        this.fetchExpensesTypes();
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
          this.setState({
            putErrors: e.response.data.error,
            saving: false
          });
        } else {
          this.setState({
            putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
            saving: false
          });
        }
      });
  }

  private expensesTypeCards(expensesTypes: ExpensesType[]) {
    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <OrganizationBiztraTable showBorder>
          <thead>
            <tr>
              <OrganizationBiztraTh width="15%">経費科目コード</OrganizationBiztraTh>
              <OrganizationBiztraTh width="20%">経費科目</OrganizationBiztraTh>
              <OrganizationBiztraTh width="20%">対応させる勘定科目</OrganizationBiztraTh>
              <OrganizationBiztraTh width="20%">デフォルトの税区分</OrganizationBiztraTh>
              <OrganizationBiztraTh width="15%">入力フォーマット</OrganizationBiztraTh>
              <OrganizationBiztraTh width="12%">編集</OrganizationBiztraTh>
              <OrganizationBiztraTh width="12%">削除</OrganizationBiztraTh>
            </tr>
          </thead>
          <Droppable droppableId="tableBody">
            {(provided: any, _snapshot: any) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {expensesTypes.map((expensesType, index) => this.expensesTypeCard(expensesType, index))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </OrganizationBiztraTable>
      </DragDropContext>
    );
  }

  private expensesTypeCard(expensesType: ExpensesType, index: number) {
    if (!expensesType.expensesAccountType) return null;
    return (
      <Draggable draggableId={String(expensesType.id)} index={index} key={expensesType.id}>
        {(provided: any, snapshot: any) => (
          <Tr
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={expensesType.id}
            ref={provided.innerRef}
            style={this.getTrStyle(snapshot.isDragging, provided.draggableProps.style)}
            data-e2e-id={expensesType.id}
          >
            <OrganizationBiztraTd showBorder className="e2e-code">
              {expensesType.code}
            </OrganizationBiztraTd>
            <OrganizationBiztraTd showBorder className="e2e-name">
              {expensesType.name}
            </OrganizationBiztraTd>
            <OrganizationBiztraTd showBorder className="e2e-expenses-account-type">
              {expensesType?.expensesAccountType?.isDeletedName || '未設定'}
            </OrganizationBiztraTd>
            <OrganizationBiztraTd showBorder className="e2e-tax-type">
              {this.state.taxTypes?.find(taxType => taxType.id === expensesType.taxTypeId)?.name || '未設定'}
            </OrganizationBiztraTd>
            <OrganizationBiztraTd showBorder className="e2e-category-name">
              {expensesType.categoryName()}
            </OrganizationBiztraTd>
            <OrganizationBiztraTd showBorder>
              <EditButton onClick={() => this.handleEditClick(expensesType)}>編集</EditButton>
            </OrganizationBiztraTd>
            <OrganizationBiztraTd showBorder>
              <TheDeleteButton onClick={() => this.handleDeleteClick(expensesType)}>削除</TheDeleteButton>
            </OrganizationBiztraTd>
          </Tr>
        )}
      </Draggable>
    );
  }

  private deleteConfirm(deletingExpensesType: ExpensesType) {
    return (
      <Wrapper className="e2e-confirm-delete">
        <Header>{`${deletingExpensesType.name}を削除してよろしいですか？`}</Header>
        <ConfirmBody>
          <ConfirmTxt style={{ textAlign: 'center' }}>※この操作は取り消せません</ConfirmTxt>
        </ConfirmBody>
        <ActionWrapper>
          <CancelButton onClick={() => this.handleDeleteAlertClose()}>キャンセル</CancelButton>
          <ModalDeleteButton onClick={() => this.handleDeleteConfirm()}>削除</ModalDeleteButton>
        </ActionWrapper>
      </Wrapper>
    );
  }

  getTrStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging && '#fff9ed',
    ...draggableStyle
  });

  getTdStyle = (isDragging: any) => ({
    width: isDragging && '25%',
    textAlign: 'left' as const
  });

  getMiniTdStyle = (isDragging: any) => ({
    width: isDragging && '10%',
    textAlign: 'left' as const
  });

  buttons() {
    return (
      <TheButtonSection>
        {!this.state.editingExpensesType && (
          <div>
            <TheAddButton onClick={() => this.handleAddClick()}>
              <AddIcon fontSize="small" style={{ marginRight: 8, verticalAlign: 'text-top' }} />
              直接入力で追加
            </TheAddButton>
          </div>
        )}
        <Link to="/biztra/organization/expenses_types/csv/bulk_upsert">
          <TheOutlinedButton>
            <span style={{ marginRight: 8, verticalAlign: 'text-top', lineHeight: 1 }}>
              <FileUploadOutlinedIcon color="#927230" />
            </span>
            CSVで追加
          </TheOutlinedButton>
        </Link>
        <div>
          <TheOutlinedButton onClick={() => this.handleDisplayOrderSubmit()}>
            <ArrowForwardIosOutlinedIcon style={{ marginRight: 8, verticalAlign: 'text-top', fontSize: 15 }} />
            順番を保存
          </TheOutlinedButton>
        </div>
      </TheButtonSection>
    );
  }

  render() {
    const {
      expensesTypes,
      expensesAccountTypes,
      taxTypes,
      editingExpensesType,
      title,
      saving,
      loading,
      error,
      putErrors,
      deletingExpensesType,
      showEditModal
    } = this.state;
    // eslint-disable-next-line no-unused-expressions
    expensesTypes?.forEach((ex, i) => {
      ex.displayOrder = i;
    });
    return (
      <>
        <MainTitle value="経費科目マスタ" buttons={this.buttons()} flexWrap />
        <ContentBody>
          {editingExpensesType && (
            <BiztraModal
              size="large"
              onClose={() => this.handleCancelClick()}
              open={showEditModal}
              className="e2e-modal-edit"
            >
              <BiztraModalHeader>経費科目を{title}する</BiztraModalHeader>
              <BiztraModalBody>
                <form onSubmit={() => this.handleEditFormSubmit()}>
                  <ModalInputWrapper>
                    <ModalInputArea>
                      <ModalInputBox>
                        <ModalInputLabel htmlFor="code">経費科目コード</ModalInputLabel>
                        <ModalInput
                          id="code"
                          type="text"
                          value={editingExpensesType.code || ''}
                          onChange={e => editingExpensesType.setCode(e.target.value)}
                        />
                      </ModalInputBox>
                      <ModalInputBox>
                        <ModalInputLabel htmlFor="expenses-account-type">対応させる勘定科目</ModalInputLabel>
                        {editingExpensesType.expensesAccountType && (
                          <OrganizationEditModalSelectWrap>
                            <ModalSelect
                              id="expenses-account-type"
                              value={editingExpensesType.expensesAccountType.id || ''}
                              onChange={e => this.handleExpensesTypeChange(e.target.value)}
                            >
                              {expensesAccountTypes &&
                                expensesAccountTypes.map((value: ExpensesAccountType) => (
                                  <>
                                    {(!value.isDeleted ||
                                      (value.isDeleted &&
                                        editingExpensesType.expensesAccountType &&
                                        editingExpensesType.expensesAccountType.id === value.id)) && (
                                      <option key={value.id} value={value.id}>
                                        {value.isDeletedName}
                                      </option>
                                    )}
                                  </>
                                ))}
                            </ModalSelect>
                          </OrganizationEditModalSelectWrap>
                        )}
                      </ModalInputBox>
                    </ModalInputArea>
                    <ModalInputArea>
                      <ModalInputBox>
                        <ModalInputLabel htmlFor="name">経費科目</ModalInputLabel>
                        <ModalInput
                          id="name"
                          type="text"
                          value={editingExpensesType.name || ''}
                          onChange={e => editingExpensesType.setName(e.target.value)}
                        />
                      </ModalInputBox>
                      <ModalInputBox>
                        <ModalInputLabel htmlFor="tax-type">税区分</ModalInputLabel>
                        <OrganizationEditModalSelectWrap>
                          <ModalSelect
                            id="tax-type"
                            value={editingExpensesType.taxTypeId?.toString() || ''}
                            onChange={e => editingExpensesType.setTaxTypeId(parseInt(e.target.value, 10) || 0)}
                          >
                            {taxTypes?.map((taxType: TaxType) => (
                              <option key={taxType.id} value={taxType.id}>
                                {taxType.name}
                              </option>
                            ))}
                          </ModalSelect>
                        </OrganizationEditModalSelectWrap>
                      </ModalInputBox>
                    </ModalInputArea>
                    <ModalInputArea>
                      <ModalInputBox />
                      <ModalInputBox>
                        <ModalInputLabel htmlFor="category">入力フォーマット</ModalInputLabel>
                        <OrganizationEditModalSelectWrap>
                          <ModalSelect
                            id="category"
                            value={editingExpensesType.category || ''}
                            onChange={e => editingExpensesType.setCategry(e.target.value)}
                          >
                            {_.map(this.state.categoryOptions, (option, i) => (
                              <option key={i} value={i}>
                                {option}
                              </option>
                            ))}
                          </ModalSelect>
                        </OrganizationEditModalSelectWrap>
                      </ModalInputBox>
                    </ModalInputArea>
                  </ModalInputWrapper>

                  <ActionWrapper>
                    <CancelButton onClick={() => this.handleCancelClick()}>キャンセル</CancelButton>
                    <BaseButton onClick={() => this.handleEditFormSubmit()}>保存</BaseButton>
                  </ActionWrapper>
                  {typeof putErrors === 'string' ? (
                    <p> {putErrors} </p>
                  ) : (
                    putErrors &&
                    putErrors.map((error, i) => (
                      <OrganizationEditModalErr key={i.toString()}>{error}</OrganizationEditModalErr>
                    ))
                  )}
                </form>
                {saving && <SimpleLoading />}
              </BiztraModalBody>
            </BiztraModal>
          )}
          {loading ? (
            <SimpleLoading />
          ) : error ? (
            <OrganizationEditModalErr>{error}</OrganizationEditModalErr>
          ) : (
            expensesTypes && this.expensesTypeCards(expensesTypes)
          )}
        </ContentBody>
        {deletingExpensesType && (
          <FeedbackModal
            open
            onClose={() => this.handleDeleteAlertClose()}
            PaperProps={{ style: { background: 'white' } }}
          >
            {this.deleteConfirm(deletingExpensesType)}
          </FeedbackModal>
        )}
      </>
    );
  }
}

const Tr = styled.tr`
  &:hover {
    background: #fff9ed;
  }
`;

const ModalInputWrapper = styled.div``;

const ModalInputBox = styled(ModalInputArea)`
  width: 50%;
  margin-right: 30px;
`;

export default ExpensesTypes;
