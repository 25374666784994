import { observable } from 'mobx';

export type OrderItemPriceDetailArgs = {
  id?: number;
  name: string;
  price: number;
  detailTraceId?: number;
  orderItemPriceId: number;
  taxTypeId: number;
  tripReportId?: number | null;
};

export default class OrderItemPriceDetail {
  @observable
  id: number | null;

  @observable
  name: string;

  @observable
  price: number;

  @observable
  detailTraceId: number | null;

  @observable
  orderItemPriceId: number | null;

  @observable
  taxTypeId: number;

  @observable
  tripReportId?: number | null;

  constructor(args: OrderItemPriceDetailArgs) {
    this.id = args.id || null;
    this.name = args.name;
    this.price = args.price;
    this.detailTraceId = args.detailTraceId || null;
    this.orderItemPriceId = args.orderItemPriceId;
    this.taxTypeId = args.taxTypeId || 2;
    this.tripReportId = args.tripReportId || null;
  }

  setName = (value: string) => {
    this.name = value;
    app.render();
  };

  setPrice = (value: number) => {
    this.price = value;
    app.render();
  };

  setTaxTypeId = (value: number) => {
    this.taxTypeId = value;
    app.render();
  };

  setPriceAndTaxType = (price: number, taxTypeId: number) => {
    this.price = price;
    this.taxTypeId = taxTypeId;
    app.render();
  };

  updateParams = () => {
    return {
      name: this.name,
      price: this.price,
      detail_trace_id: this.detailTraceId,
      order_item_price_id: this.orderItemPriceId,
      tax_type_id: this.taxTypeId,
      trip_report_id: this.tripReportId
    };
  };
}
