import { Fetcher } from '@this/src/util';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import { styled } from '@this/constants/themes';
import moment from 'moment';
import _ from 'lodash';
import { ItemCardDate, ItemCardList } from '@this/components/expenses/report_items/report_item_cards';
import { ByMedia } from '@this/shared/ui/other/by_media';
import SearchForm from './search_form';
import type { ReportJson } from '../../../domain/expenses/report';
import { Report } from '../../../domain/expenses/report';
import type { CutoffDate, CutoffDateJson } from '../../../domain/expenses/cutoff_date';
import { FiltersWrapper, FilteredBox, FilteredWrapper } from '../report_items/report_items';
import ReportCard from './report_card';
import ExpensesMain from '../main/main';

interface IndexResponse {
  reports: ReportJson[];
  payees: string[];
  cutoff_dates: CutoffDateJson[];
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  reports: Report[];
  payees: string[];
  cutoffDates?: CutoffDate[];
  error?: string;
  loading: boolean;
  submitting: boolean;
  // 検索関連
  filteredReports: Report[];
  showSearchModal: boolean;
  appliedAtFrom: string;
  appliedAtTo: string;
  selectedPayee: string;
  priceFrom: string;
  priceTo: string;
}

class Reports extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      reports: [],
      payees: [],
      loading: false,
      submitting: false,
      filteredReports: [],
      showSearchModal: false,
      appliedAtFrom: '',
      appliedAtTo: '',
      selectedPayee: '',
      priceFrom: '',
      priceTo: ''
    };
  }

  async componentDidMount() {
    await this.fetchReports();
    mixpanel.track('[Biztra][view] /reports');
  }

  handleCreateReport(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    this.props.history.push('/biztra/reports/new');
  }

  handleClickReport(report: Report) {
    if (report.status === 'draft') {
      this.props.history.push(`/biztra/reports/${report.id}/edit`);
    } else {
      this.props.history.push(`/biztra/reports/${report.id}`);
    }
  }

  setSearchParams = async (
    appliedAtFrom: string,
    appliedAtTo: string,
    selectedPayee: string,
    priceFrom: string,
    priceTo: string
  ) => {
    await this.setState({
      appliedAtFrom,
      appliedAtTo,
      selectedPayee,
      priceFrom,
      priceTo,
      showSearchModal: false
    });
    this.filterReports();
  };

  private getFormat = (): string => 'YYYY-MM-DD';

  private filterReports = () => {
    const { reports, appliedAtFrom, appliedAtTo, selectedPayee, priceFrom, priceTo } = this.state;
    const filteredReports = reports.filter(r => {
      const isSameOrAfterAppliedAt =
        r.appliedAt && appliedAtFrom ? r.appliedAt.isSameOrAfter(moment(appliedAtFrom, this.getFormat())) : true;
      const isSameOrBeforeAppliedAt =
        r.appliedAt && appliedAtTo
          ? r.appliedAt.isSameOrBefore(moment(appliedAtTo, this.getFormat()).add(1, 'days'))
          : true;

      const isSelectedPayee = selectedPayee ? r.items.flatMap(i => i.payee).includes(selectedPayee) : true;

      const isHigherPrice = priceFrom ? r.totalPrice() >= Number(priceFrom) : true;
      const isLowerPrice = priceTo ? r.totalPrice() <= Number(priceTo) : true;

      return isSameOrAfterAppliedAt && isSameOrBeforeAppliedAt && isSelectedPayee && isHigherPrice && isLowerPrice;
    });

    this.setState({
      filteredReports
    });
  };

  private async fetchReports() {
    this.setState({ loading: true });
    try {
      const response: IndexResponse = await Fetcher.get<IndexResponse>('/biztra/reports');
      const reports = response.reports.map(report => new Report(report));
      this.setState({
        loading: false,
        reports,
        payees: response.payees,
        filteredReports: reports
      });
    } catch {
      this.setState({
        loading: false,
        error: '通信エラーが発生しました。時間をおいて再度お試しください。'
      });
    }
  }

  private filteredReportsByDate() {
    const filteredReports = this.state.filteredReports;
    const goi = _.groupBy(filteredReports, reports => reports.appliedAt?.format('YYYY年MM月DD日'));
    const dates = Object.keys(goi).sort().reverse();
    return (
      <ReportCardListTableWrap>
        {dates.map((date: string) => (
          <ByMedia key={date}>
            {matches =>
              matches.sm ? (
                <ItemCardList>
                  <ItemCardDate>{date}</ItemCardDate>
                  {this.reportCardsSp(goi[date])}
                </ItemCardList>
              ) : (
                <ReportCardListTable>
                  <colgroup>
                    <ReportCardListColStatus />
                    <ReportCardListColNo />
                    <col />
                    <ReportCardListColSubTitle />
                    <ReportCardListColDescription />
                    <ReportCardListColPrice />
                  </colgroup>
                  <ReportCardListThead>
                    <tr>
                      <ReportCardDate colSpan={6}>{date}</ReportCardDate>
                    </tr>
                  </ReportCardListThead>
                  {this.reportCards(goi[date])}
                </ReportCardListTable>
              )
            }
          </ByMedia>
        ))}
      </ReportCardListTableWrap>
    );
  }

  private reportCards(reports: Report[]) {
    return reports.map((report: Report, i: number) => (
      <ReportCard
        key={i}
        report={report}
        onClickReport={r => {
          this.handleClickReport(r);
        }}
      />
    ));
  }

  private reportCardsSp(reports: Report[]) {
    return reports.map((report: Report, i: number) => (
      <ReportCard
        key={i}
        report={report}
        onClickReport={r => {
          this.handleClickReport(r);
        }}
        isMobile
      />
    ));
  }

  private newReportCard() {
    return (
      <ReportCard
        handleCreateReport={e => this.handleCreateReport(e)}
        submitting={this.state.submitting}
        newCard
      />
    );
  }

  private utils() {
    return (
      <div className="expenses-content-main__button-area">
        <PrimaryButton onClick={e => this.handleCreateReport(e)} disabled={this.state.submitting}>
          ＋ 申請を追加
        </PrimaryButton>
      </div>
    );
  }

  filteredAppliedAt() {
    return (
      <FilteredBox>
        <FilteredTitle>期間</FilteredTitle>
        <span>：</span>
        <span>{this.state.appliedAtFrom}</span>
        <FromTo>～</FromTo>
        <span>{this.state.appliedAtTo}</span>
      </FilteredBox>
    );
  }

  filteredPayee() {
    return (
      <FilteredBox>
        <FilteredTitle>取引先</FilteredTitle>
        <span>：</span>
        {this.state.selectedPayee && <span>{this.state.selectedPayee}</span>}
      </FilteredBox>
    );
  }

  filteredPrice() {
    return (
      <FilteredBox>
        <FilteredTitle>金額</FilteredTitle>
        <span>：</span>
        {this.state.priceFrom && <span>{this.state.priceFrom}円</span>}
        <FromTo>～</FromTo>
        {this.state.priceTo && <span>{this.state.priceTo}円</span>}
      </FilteredBox>
    );
  }

  render() {
    const {
      loading,
      reports,
      error,
      showSearchModal,
      payees,
      appliedAtFrom,
      appliedAtTo,
      selectedPayee,
      priceFrom,
      priceTo
    } = this.state;
    return (
      <ExpensesMain
        title="申請一覧"
        utils={this.utils()}
        noBackground={reports.length === 0}
        noBackgroundSp={reports.length === 0}
      >
        {loading ? (
          <SimpleLoading />
        ) : error ? (
          <p>{error}</p>
        ) : reports.length === 0 ? (
          this.newReportCard()
        ) : (
          <>
            <FilteredWrapper className={reports.length === 0 ? 'noItems' : ''}>
              <OpenSearchModalButton
                onClick={e => {
                  e.currentTarget.blur();
                  this.setState({ showSearchModal: true });
                }}
              >
                検索条件を指定
              </OpenSearchModalButton>
              <FiltersWrapper>
                {(!!appliedAtFrom || !!appliedAtTo) && this.filteredAppliedAt()}
                {!!selectedPayee && this.filteredPayee()}
                {(!!priceFrom || !!priceTo) && this.filteredPrice()}
              </FiltersWrapper>
            </FilteredWrapper>
            {this.filteredReportsByDate()}
            <Modal
              size="medium"
              onClose={() => {
                this.setState({ showSearchModal: false });
              }}
              open={showSearchModal}
              PaperProps={{ style: { background: 'white' } }}
            >
              <SearchForm
                onSetSearchParams={this.setSearchParams}
                onCancel={() => this.setState({ showSearchModal: false })}
                payees={payees}
                periodFrom={appliedAtFrom}
                periodTo={appliedAtTo}
                selectedPayee={selectedPayee}
                priceFrom={priceFrom}
                priceTo={priceTo}
              />
            </Modal>
          </>
        )}
      </ExpensesMain>
    );
  }
}

const cardListBaseColor = '#f1ede5';

const blackTxtColor = '#323232';

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const PrimaryButton = styled.button.attrs({ type: 'button' })`
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
  }
`;

const outlineBtnColor = '#FFFFFF';

const baseColor = '#927230';

const OutlineButton = styled.button.attrs({ type: 'button' })`
  background: ${outlineBtnColor};
  color: ${baseColor};
  border: 1px solid ${baseColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${outlineBtnColor};
    color: ${baseColor};
  }
`;

const OpenSearchModalButton = styled(OutlineButton)`
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
`;

const FilteredTitle = styled.span`
  color: ${baseColor};
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 12px;
`;

const FromTo = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`;

const ReportCardListTableWrap = styled.div`
  padding-top: 30px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const ReportCardListTable = styled.table`
  margin: 0 !important;
  border: solid 1px ${cardListBaseColor};
`;

const ReportCardListThead = styled.thead`
  background-color: ${cardListBaseColor};
`;

const ReportCardDate = styled.th`
  color: ${blackTxtColor};
  font-size: 16px;
  padding: 6px;
  letter-spacing: normal;
  font-weight: normal;
  border-bottom: none;
`;

const ReportCardListColStatus = styled.col`
  width: 6.91%;
  min-width: 106px;
`;

const ReportCardListColNo = styled.col`
  width: 11.62%;
  min-width: 110px;
`;

const ReportCardListColSubTitle = styled.col`
  width: 16.24%;
`;
const ReportCardListColDescription = styled.col`
  width: 14%;
  max-width: 180px;
`;
const ReportCardListColPrice = styled.col`
  width: 15%;
  min-width: 120px;
`;

export default Reports;
