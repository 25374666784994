import React, { useState } from 'react';
import { observer } from 'mobx-react';

import type { InternationalAirline } from '@this/components/admin/repository_display_adjustment/types';
import SearchableSelect from '@this/shared/searchable_select';
import { styled } from '@this/constants/themes';
import { ButtonBase02 } from '@this/shared/atoms/button';
import ExclusionAirlineCode from '@this/src/domain/exclusion_airline_codes';

interface Props {
  airlines: InternationalAirline[];
  exclusionAirlineCodes: ExclusionAirlineCode[];
  onUpdate: (exclusionAirlineCodes: ExclusionAirlineCode[]) => Promise<void>;
}

const ExclusionAirlinesForm: React.FC<Props> = ({ airlines, exclusionAirlineCodes, onUpdate }) => {
  const [editingCodes, setEditingCodes] = useState([...exclusionAirlineCodes]);
  const selectableAirlines = airlines.map(airline => ({
    value: airline.code,
    displayName: airline.name
  }));

  const onClickRemove = (i: number) => {
    editingCodes.splice(i, 1);
    setEditingCodes([...editingCodes]);
  };

  const onClickAdd = () => {
    editingCodes.push(new ExclusionAirlineCode({}));
    setEditingCodes([...editingCodes]);
  };

  const isChanged = () => editingCodes.join('') !== exclusionAirlineCodes.join('');

  return (
    <>
      <AirlineSelectAreaGroup className="e2e-airline-list">
        {editingCodes.map((code, i) => (
          <AirlineSelectArea key={i}>
            {code.inputType === 'autocomplete' ? (
              <SearchableSelect
                className="searchable-select"
                list={selectableAirlines}
                currentValue={code.airlineCode}
                onChange={(v: string) => code.changeAirlineCode(v)}
              />
            ) : (
              <>
                <AirlineCodeInput
                  type="text"
                  value={code.airlineCode}
                  onChange={e => code.changeAirlineCode(e.target.value)}
                  placeholder="コード"
                />
                <AirlineNameInput
                  type="text"
                  value={code.airlineName}
                  onChange={e => code.changeAirlineName(e.target.value)}
                  placeholder="航空会社名"
                />
              </>
            )}
            <RemoveButton onClick={() => onClickRemove(i)}>+</RemoveButton>
            <ToggleButton onClick={() => code.toggleInputType()}>
              {code.inputType === 'autocomplete' ? '手入力' : '選択'}
            </ToggleButton>
          </AirlineSelectArea>
        ))}
        <LinkButton onClick={onClickAdd}>+ 航空会社を追加</LinkButton>
      </AirlineSelectAreaGroup>

      <Button onClick={() => onUpdate(editingCodes)} disabled={!isChanged()}>
        更新
      </Button>
    </>
  );
};

const Button = styled.button.attrs({
  type: 'button'
})`
  ${ButtonBase02};
`;

const RemoveButton = styled.button.attrs({ type: 'button' })`
  color: #7d7d7d;
  border-radius: 50%;
  border: 0;
  padding: 0;
  background-color: transparent;
  transform: rotate(45deg);
  transition: opacity 0.3s;
  font-size: 16px;
  outline: none;

  &:hover,
  &:focus {
    opacity: 0.8;
    background-color: transparent;
    color: #7d7d7d;
  }
`;

const AirlineSelectArea = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .searchable-select {
    width: 250px;
  }
`;

const AirlineSelectAreaGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  ${AirlineSelectArea} + ${AirlineSelectArea} {
    margin-top: 5px;
  }
`;

const AirlineCodeInput = styled.input`
  &&& {
    width: 60px;
    margin-bottom: 0;
  }
`;

const AirlineNameInput = styled.input`
  &&& {
    width: 180px;
    margin-bottom: 0;
  }
`;

const ToggleButton = styled.a`
  display: inline-block;
  cursor: pointer;
`;

const LinkButton = styled.a`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
`;

export default observer(ExclusionAirlinesForm);
